var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("div", [_vm._v("线路配置")]),
    _vm._v(" "),
    _c("table", { staticClass: "infoTable mb40" }, [
      _c("tr", [
        _c("td", { staticClass: "tr bold", attrs: { width: "10%" } }, [
          _vm._v("线路名称")
        ]),
        _vm._v(" "),
        _c("td", { attrs: { width: "30%" } }, [
          _vm._v(_vm._s(_vm.lineForm.lineName))
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "tr bold", attrs: { width: "10%" } }, [
          _vm._v("营运状态")
        ]),
        _vm._v(" "),
        _c(
          "td",
          { attrs: { width: "30%" } },
          [
            _c("el-switch", {
              attrs: { "active-value": true, "inactive-value": false },
              on: { change: _vm.statusChange },
              model: {
                value: _vm.lineForm.status,
                callback: function($$v) {
                  _vm.$set(_vm.lineForm, "status", $$v)
                },
                expression: "lineForm.status"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", { staticClass: "tr bold", attrs: { width: "10%" } }, [
          _vm._v("线路类型")
        ]),
        _vm._v(" "),
        _c("td", { attrs: { width: "30%" } }, [
          _vm._v(_vm._s(_vm._f("lineTypeFilter")(_vm.lineForm.lineType)))
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "tr bold", attrs: { width: "10%" } }, [
          _vm._v("运力公司")
        ]),
        _vm._v(" "),
        _c("td", { attrs: { width: "30%" } }, [
          _vm._v(_vm._s(_vm.lineForm.companyName))
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "config-wrapper" }, [
      _c(
        "div",
        { staticClass: "left" },
        _vm._l(_vm.tabList, function(item, index) {
          return _c(
            "div",
            {
              staticClass: "item",
              style: _vm.currentTab == index ? "color:red" : "",
              on: {
                click: function($event) {
                  return _vm.chooseTab(index)
                }
              }
            },
            [
              _c("span", [_vm._v(_vm._s(item.name))]),
              _vm._v(" "),
              _vm.currentTab == index
                ? _c("div", { staticClass: "line" })
                : _vm._e()
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "right" }, [
        _vm.currentTab == 0
          ? _c(
              "div",
              { staticClass: "item" },
              [
                _c(
                  "div",
                  { staticClass: "top" },
                  [
                    _c("div", [_vm._v("线路运营范围")]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.openEditLineStationFn }
                      },
                      [_vm._v("线路站点配置")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  { attrs: { data: _vm.lineStationList, "max-height": "500" } },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "顺序",
                        align: "center",
                        prop: "sequence"
                      }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "站点名称",
                        align: "center",
                        prop: "stationName"
                      }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "站点城市",
                        align: "center",
                        prop: "stationCityName"
                      }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "站点类型",
                        align: "center",
                        prop: "stationType"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      scope.row.stationType == 1
                                        ? "站点"
                                        : "围栏"
                                    ) +
                                    "\n            "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        472027792
                      )
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "站点场景",
                        align: "center",
                        prop: "stationTag"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("stationTagFilter")(
                                        scope.row.stationTag
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3078377124
                      )
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "站点地址",
                        align: "center",
                        prop: "address",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "上车点/下车点",
                        align: "center",
                        prop: "upDown"
                      }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "到站时长",
                        align: "center",
                        prop: "estimateTime"
                      }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "等待时长",
                        align: "center",
                        prop: "waitTime"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.lineStationLoading,
                        expression: "lineStationLoading"
                      }
                    ],
                    attrs: {
                      title: "线路站点配置",
                      visible: _vm.openEditLineStation,
                      "close-on-click-modal": false,
                      center: "",
                      width: "90%"
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.openEditLineStation = $event
                      }
                    }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.addNewWay }
                      },
                      [_vm._v("添加站点")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "edit-line-station-top" }, [
                      _c("span", { staticStyle: { width: "15%" } }, [
                        _vm._v("站点名称")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { width: "5%" } }, [
                        _vm._v("站点排序")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { width: "5%" } }, [
                        _vm._v("站点城市")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { width: "5%" } }, [
                        _vm._v("站点类型")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { width: "5%" } }, [
                        _vm._v("站点场景")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { width: "15%" } }, [
                        _vm._v("是否上车点")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { width: "15%" } }, [
                        _vm._v("是否下车点")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { width: "15%" } }, [
                        _vm._v("预计到站时长")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { width: "15%" } }, [
                        _vm._v("到站预计等待时长")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { width: "5%" } }, [
                        _vm._v("操作")
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form",
                      {
                        ref: "lineStationConfigForm",
                        staticClass: "way_points_form",
                        staticStyle: { "border-bottom": "0px" },
                        attrs: {
                          model: _vm.lineStationConfigForm,
                          inline: true
                        }
                      },
                      [
                        _c(
                          "draggable",
                          {
                            model: {
                              value:
                                _vm.lineStationConfigForm.lineStationConfigList,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.lineStationConfigForm,
                                  "lineStationConfigList",
                                  $$v
                                )
                              },
                              expression:
                                "lineStationConfigForm.lineStationConfigList"
                            }
                          },
                          [
                            _c(
                              "transition-group",
                              { attrs: { name: "flip-list" } },
                              _vm._l(
                                _vm.lineStationConfigForm.lineStationConfigList,
                                function(item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.sequence,
                                      staticClass: "line-station-config-list"
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            rules: {
                                              required: true,
                                              message: "请选择途经站点",
                                              trigger: "change"
                                            },
                                            prop:
                                              "lineStationConfigList." +
                                              index +
                                              ".sid"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                size: "mini",
                                                filterable: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.changeConfigStation(
                                                    index
                                                  )
                                                }
                                              },
                                              model: {
                                                value: item.sid,
                                                callback: function($$v) {
                                                  _vm.$set(item, "sid", $$v)
                                                },
                                                expression: "item.sid"
                                              }
                                            },
                                            _vm._l(_vm.stationsList, function(
                                              ways
                                            ) {
                                              return _c("el-option", {
                                                key: ways.id,
                                                attrs: {
                                                  label: ways.stationName,
                                                  value: ways.id
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticStyle: { width: "5%" } },
                                        [_vm._v(_vm._s(index + 1))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticStyle: { width: "5%" } },
                                        [_vm._v(_vm._s(item.stationCityName))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticStyle: { width: "5%" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.stationType == 2
                                                ? "围栏"
                                                : "站点"
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticStyle: { width: "5%" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("stationTagFilter")(
                                                item.stationTag
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          key: index + "s",
                                          attrs: { label: "允许上车" }
                                        },
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              size: "mini",
                                              "active-value": 1,
                                              "inactive-value": 0
                                            },
                                            model: {
                                              value: item.up,
                                              callback: function($$v) {
                                                _vm.$set(item, "up", $$v)
                                              },
                                              expression: "item.up"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          key: index + "d",
                                          attrs: { label: "允许下车" }
                                        },
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              "active-value": 1,
                                              "inactive-value": 0,
                                              size: "mini"
                                            },
                                            model: {
                                              value: item.down,
                                              callback: function($$v) {
                                                _vm.$set(item, "down", $$v)
                                              },
                                              expression: "item.down"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          key: index + "l",
                                          ref: index + "estimateTime",
                                          refInFor: true,
                                          attrs: {
                                            label: "预计时长",
                                            rules: {
                                              required: true,
                                              message: "请填写预计时长",
                                              trigger: "blur"
                                            },
                                            prop:
                                              "lineStationConfigList." +
                                              index +
                                              ".estimateTime"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              staticStyle: { width: "80px" },
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入预计时长"
                                              },
                                              model: {
                                                value: item.estimateTime,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "estimateTime",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.estimateTime"
                                              }
                                            },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  staticStyle: {
                                                    "font-style": "normal"
                                                  },
                                                  attrs: { slot: "suffix" },
                                                  slot: "suffix"
                                                },
                                                [_vm._v("分钟")]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          key: index + "h",
                                          ref: index + "waitTime",
                                          refInFor: true,
                                          attrs: {
                                            label: "等待时长",
                                            rules: {
                                              required: true,
                                              message: "请填写等待时长",
                                              trigger: "blur"
                                            },
                                            prop:
                                              "lineStationConfigList." +
                                              index +
                                              ".waitTime"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              staticStyle: { width: "80px" },
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入等待时长"
                                              },
                                              model: {
                                                value: item.waitTime,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "waitTime",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.waitTime"
                                              }
                                            },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  staticStyle: {
                                                    "font-style": "normal"
                                                  },
                                                  attrs: { slot: "suffix" },
                                                  slot: "suffix"
                                                },
                                                [_vm._v("分钟")]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          key: index + "delItem",
                                          staticStyle: { width: "5%" }
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              key: index + "d",
                                              attrs: { size: "mini" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.delItem(index)
                                                }
                                              }
                                            },
                                            [_vm._v("删除")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("br", { key: index + "k" })
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer"
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.submitEditLineStationFn }
                          },
                          [_vm._v("保 存")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          { on: { click: _vm.closeEditLineStationFn } },
                          [_vm._v("取 消")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.currentTab == 1
          ? _c(
              "div",
              { staticClass: "item" },
              [
                _c(
                  "el-form",
                  {
                    ref: "priceForm",
                    attrs: {
                      "label-position": "left",
                      inline: true,
                      model: _vm.priceForm,
                      rules: _vm.priceRules,
                      "label-width": "90px"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "计价方式", prop: "priceType" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.priceForm.priceType,
                              callback: function($$v) {
                                _vm.$set(_vm.priceForm, "priceType", $$v)
                              },
                              expression: "priceForm.priceType"
                            }
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("一口价")
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("区间票价")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("h4", [_vm._v("基础价格")]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "线路票价", prop: "price" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: { type: "tel", placeholder: "请输入票价" },
                            model: {
                              value: _vm.priceForm.price,
                              callback: function($$v) {
                                _vm.$set(_vm.priceForm, "price", $$v)
                              },
                              expression: "priceForm.price"
                            }
                          },
                          [
                            _c(
                              "i",
                              {
                                staticStyle: { "font-style": "normal" },
                                attrs: { slot: "suffix" },
                                slot: "suffix"
                              },
                              [_vm._v("元")]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "划线票价", prop: "markingPrice" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              disabled: _vm.priceForm.isOpenMarking == "0",
                              placeholder: "请输入划线票价"
                            },
                            model: {
                              value: _vm.priceForm.markingPrice,
                              callback: function($$v) {
                                _vm.$set(_vm.priceForm, "markingPrice", $$v)
                              },
                              expression: "priceForm.markingPrice"
                            }
                          },
                          [
                            _c(
                              "i",
                              {
                                staticStyle: { "font-style": "normal" },
                                attrs: { slot: "suffix" },
                                slot: "suffix"
                              },
                              [_vm._v("元")]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "是否开启划线价",
                          prop: "isOpenMarking",
                          "label-width": "120px"
                        }
                      },
                      [
                        _c("el-switch", {
                          attrs: { "active-value": 1, "inactive-value": 0 },
                          model: {
                            value: _vm.priceForm.isOpenMarking,
                            callback: function($$v) {
                              _vm.$set(_vm.priceForm, "isOpenMarking", $$v)
                            },
                            expression: "priceForm.isOpenMarking"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.priceForm.priceType == 1
                      ? [
                          _c("h4", [_vm._v("动态费用")]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "站点加价",
                                prop: "stationRaisePriceType"
                              }
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.priceForm.stationRaisePriceType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.priceForm,
                                        "stationRaisePriceType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "priceForm.stationRaisePriceType"
                                  }
                                },
                                [
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("不收取")
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("每人加价")
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("每单加价")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _vm.priceForm.stationRaisePriceType != 0
                            ? _c(
                                "el-form-item",
                                { staticClass: "bt" },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [
                                      _vm._v("\n                上车区域："),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            !_vm.priceForm.raisePriceConfigs
                                              .up ||
                                              _vm.priceForm.raisePriceConfigs.up
                                                .length === 0
                                              ? "暂无数据"
                                              : ""
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.priceForm.raisePriceConfigs.up,
                                    function(it, idx) {
                                      return _c(
                                        "div",
                                        {
                                          key: idx + "aa",
                                          staticClass: "flexable mb10"
                                        },
                                        [
                                          _c("div", { staticClass: "mr120" }, [
                                            _vm._v(_vm._s(it.stationName))
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                it.stationType == 2
                                                  ? "围栏："
                                                  : "站点："
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "160px" },
                                                attrs: {
                                                  placeholder: "请输入价格"
                                                },
                                                model: {
                                                  value: it.price,
                                                  callback: function($$v) {
                                                    _vm.$set(it, "price", $$v)
                                                  },
                                                  expression: "it.price"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [
                                      _vm._v("\n                下车区域："),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            !_vm.priceForm.raisePriceConfigs
                                              .down ||
                                              _vm.priceForm.raisePriceConfigs
                                                .down.length === 0
                                              ? "暂无数据"
                                              : ""
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.priceForm.raisePriceConfigs.down,
                                    function(it, idx) {
                                      return _c(
                                        "div",
                                        {
                                          key: idx + "bb",
                                          staticClass: "flexable mb10"
                                        },
                                        [
                                          _c("div", { staticClass: "mr120" }, [
                                            _vm._v(_vm._s(it.stationName))
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                it.stationType == 2
                                                  ? "围栏："
                                                  : "站点："
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "160px" },
                                                attrs: {
                                                  placeholder: "请输入价格"
                                                },
                                                model: {
                                                  value: it.price,
                                                  callback: function($$v) {
                                                    _vm.$set(it, "price", $$v)
                                                  },
                                                  expression: "it.price"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    }
                                  )
                                ],
                                2
                              )
                            : _vm._e()
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.priceForm.priceType == 2
                      ? _c(
                          "el-collapse",
                          {
                            staticStyle: { margin: "15px" },
                            model: {
                              value: _vm.activeNames,
                              callback: function($$v) {
                                _vm.activeNames = $$v
                              },
                              expression: "activeNames"
                            }
                          },
                          [
                            _vm._l(_vm.priceForm.intervalPriceConfigs, function(
                              item,
                              index
                            ) {
                              return [
                                _c(
                                  "el-collapse-item",
                                  {
                                    key: index + "b",
                                    attrs: {
                                      title: "上：" + item.stationName,
                                      name: index + "p"
                                    }
                                  },
                                  _vm._l(item.downList, function(inner, idx) {
                                    return _c(
                                      "div",
                                      { key: "" + idx + index + "t" },
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              {
                                                key: index + "b",
                                                attrs: { span: 6, offset: 2 }
                                              },
                                              [
                                                _c("el-form-item", {
                                                  key: index + "c",
                                                  attrs: {
                                                    label:
                                                      "下：" +
                                                      inner.stationName,
                                                    "label-width": "200px"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              {
                                                key: index + "d",
                                                attrs: { span: 16 }
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    key: index + "l",
                                                    ref: index + "price",
                                                    refInFor: true,
                                                    attrs: {
                                                      label: "区间票价",
                                                      prop:
                                                        "intervalPriceConfigs." +
                                                        index +
                                                        ".downList." +
                                                        idx +
                                                        ".price"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "el-input",
                                                      {
                                                        staticStyle: {
                                                          width: "140px"
                                                        },
                                                        attrs: {
                                                          size: "mini",
                                                          placeholder:
                                                            "请填写区间票价"
                                                        },
                                                        model: {
                                                          value: inner.price,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              inner,
                                                              "price",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "inner.price"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            staticStyle: {
                                                              "font-style":
                                                                "normal"
                                                            },
                                                            attrs: {
                                                              slot: "suffix"
                                                            },
                                                            slot: "suffix"
                                                          },
                                                          [_vm._v("元")]
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ]
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    staticStyle: { "align-self": "center" },
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.submitEditPriceFn }
                      },
                      [_vm._v("保 存")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.currentTab == 2
          ? _c(
              "div",
              { staticClass: "item" },
              [
                _c(
                  "div",
                  { staticClass: "top" },
                  [
                    _c("div", [
                      _vm._v(
                        "班次模板用于每日快捷排班时可选择按照此模板班次时间排班"
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.openScheduleTemplateFn }
                      },
                      [_vm._v("添加班次")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    attrs: {
                      data: _vm.lineScheduleTemplateList,
                      "max-height": "500"
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "班次时间", align: "center" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      scope.row.firstTime +
                                        (scope.row.lastTime
                                          ? "-" + scope.row.lastTime
                                          : "")
                                    ) +
                                    "\n            "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3320399601
                      )
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "班次类型",
                        align: "center",
                        prop: "scheduleType"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      scope.row.scheduleType == 1
                                        ? "常规班次"
                                        : "流水班次"
                                    ) +
                                    "\n            "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        301495613
                      )
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        align: "center",
                        "class-name": "small-padding fixed-width"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermi",
                                        rawName: "v-hasPermi",
                                        value: ["bus:bus:edit"],
                                        expression: "['bus:bus:edit']"
                                      }
                                    ],
                                    attrs: {
                                      size: "mini",
                                      type: "text",
                                      icon: "el-icon-edit"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.updateScheduleTemplateFn(
                                          scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("修改")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermi",
                                        rawName: "v-hasPermi",
                                        value: ["bus:bus:remove"],
                                        expression: "['bus:bus:remove']"
                                      }
                                    ],
                                    attrs: {
                                      size: "mini",
                                      type: "text",
                                      icon: "el-icon-delete"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteScheduleTemplateFn(
                                          scope.$index
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1774947692
                      )
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    staticStyle: {
                      "align-self": "center",
                      "margin-top": "20px"
                    },
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.submitScheduleTemplateForm }
                      },
                      [_vm._v("保 存")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: _vm.scheduleTemplateTitle,
                      visible: _vm.openScheduleTemplate,
                      width: "500px",
                      center: ""
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.openScheduleTemplate = $event
                      }
                    }
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "scheduleTemplateForm",
                        attrs: {
                          model: _vm.scheduleTemplateForm,
                          rules: _vm.scheduleTemplateFormRules,
                          "label-width": "120px"
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "站点类型", prop: "scheduleType" }
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.scheduleTemplateForm.scheduleType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.scheduleTemplateForm,
                                      "scheduleType",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "scheduleTemplateForm.scheduleType"
                                }
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("常规班次")
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("流水班次")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.scheduleTemplateForm.scheduleType == 1
                          ? _c(
                              "el-form-item",
                              {
                                attrs: { label: "发车时间", prop: "firstTime" }
                              },
                              [
                                _c("el-time-picker", {
                                  attrs: {
                                    format: "HH:mm",
                                    "value-format": "HH:mm",
                                    placeholder: "选择发车时间"
                                  },
                                  model: {
                                    value: _vm.scheduleTemplateForm.firstTime,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.scheduleTemplateForm,
                                        "firstTime",
                                        $$v
                                      )
                                    },
                                    expression: "scheduleTemplateForm.firstTime"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.scheduleTemplateForm.scheduleType == 2
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "首班发车时间",
                                  prop: "firstTime"
                                }
                              },
                              [
                                _c("el-time-picker", {
                                  attrs: {
                                    format: "HH:mm",
                                    "value-format": "HH:mm",
                                    placeholder: "首班发车时间"
                                  },
                                  model: {
                                    value: _vm.scheduleTemplateForm.firstTime,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.scheduleTemplateForm,
                                        "firstTime",
                                        $$v
                                      )
                                    },
                                    expression: "scheduleTemplateForm.firstTime"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.scheduleTemplateForm.scheduleType == 2
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "末班发车时间",
                                  prop: "lastTime"
                                }
                              },
                              [
                                _c("el-time-picker", {
                                  attrs: {
                                    format: "HH:mm",
                                    "value-format": "HH:mm",
                                    placeholder: "末班发车时间"
                                  },
                                  model: {
                                    value: _vm.scheduleTemplateForm.lastTime,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.scheduleTemplateForm,
                                        "lastTime",
                                        $$v
                                      )
                                    },
                                    expression: "scheduleTemplateForm.lastTime"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer"
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.addScheduleTemplate }
                          },
                          [_vm._v("确 定")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          { on: { click: _vm.cancelScheduleTemplate } },
                          [_vm._v("取 消")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.currentTab == 3
          ? _c(
              "div",
              { staticClass: "item" },
              [
                _c(
                  "el-form",
                  {
                    ref: "lineSaleConfigForm",
                    attrs: {
                      "label-position": "left",
                      inline: true,
                      model: _vm.lineSaleConfigForm,
                      "label-width": "180px",
                      rules: _vm.lineSaleConfigFormRules
                    }
                  },
                  [
                    _c("h4", { staticStyle: { "margin-bottom": "15px" } }, [
                      _vm._v("停售规则")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "mr120",
                        attrs: {
                          label: "售票截止时间",
                          prop: "stopSellingRule"
                        }
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.lineSaleConfigForm.stopSellingRule,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.lineSaleConfigForm,
                                  "stopSellingRule",
                                  $$v
                                )
                              },
                              expression: "lineSaleConfigForm.stopSellingRule"
                            }
                          },
                          [
                            _c(
                              "el-radio",
                              { attrs: { label: 1 } },
                              [
                                _c("span", { staticStyle: { width: "46px" } }, [
                                  _vm._v("班次发车前")
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticStyle: {
                                    width: "80px",
                                    margin: "0 4px"
                                  },
                                  attrs: {
                                    type: "number",
                                    onblur: "if(!value)value=0"
                                  },
                                  model: {
                                    value:
                                      _vm.lineSaleConfigForm
                                        .stopSellingScheduleDepartBeforeMin,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.lineSaleConfigForm,
                                        "stopSellingScheduleDepartBeforeMin",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n                    lineSaleConfigForm.stopSellingScheduleDepartBeforeMin\n                  "
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("分钟之后停止售票")]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "red",
                                      "margin-left": "15px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "注！当班次为流水班次时，以班次模板发车时间计算"
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                staticStyle: { "margin-top": "10px" },
                                attrs: { label: 2 }
                              },
                              [
                                _c("span", { staticStyle: { width: "46px" } }, [
                                  _vm._v("站点发车前")
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticStyle: {
                                    width: "80px",
                                    margin: "0 4px"
                                  },
                                  attrs: {
                                    type: "number",
                                    onblur: "if(!value)value=0"
                                  },
                                  model: {
                                    value:
                                      _vm.lineSaleConfigForm
                                        .stopSellingStationDepartBeforeMin,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.lineSaleConfigForm,
                                        "stopSellingStationDepartBeforeMin",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n                    lineSaleConfigForm.stopSellingStationDepartBeforeMin\n                  "
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("分钟之后停止售票")])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("h4", { staticStyle: { "margin-bottom": "15px" } }, [
                      _vm._v("退票规则")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "是否允许退票" } },
                      [
                        _c("el-switch", {
                          model: {
                            value: _vm.lineSaleConfigForm.refundTicketSwitch,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.lineSaleConfigForm,
                                "refundTicketSwitch",
                                $$v
                              )
                            },
                            expression: "lineSaleConfigForm.refundTicketSwitch"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.lineSaleConfigForm.refundTicketSwitch
                      ? _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "30px" },
                            attrs: { label: "是否允许改签车票退票" }
                          },
                          [
                            _c("el-switch", {
                              model: {
                                value:
                                  _vm.lineSaleConfigForm
                                    .refundChangeTicketSwitch,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.lineSaleConfigForm,
                                    "refundChangeTicketSwitch",
                                    $$v
                                  )
                                },
                                expression:
                                  "lineSaleConfigForm.refundChangeTicketSwitch"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _vm.lineSaleConfigForm.refundTicketSwitch
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "退票时间范围",
                              prop: "refundTicketRule"
                            }
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value:
                                    _vm.lineSaleConfigForm.refundTicketRule,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.lineSaleConfigForm,
                                      "refundTicketRule",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "lineSaleConfigForm.refundTicketRule"
                                }
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("按班次")
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("按站点")
                                ])
                              ],
                              1
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", { staticStyle: { width: "46px" } }, [
                              _vm._v("发车前")
                            ]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "80px", margin: "0 4px" },
                              attrs: {
                                type: "number",
                                onblur: "if(!value)value=0"
                              },
                              model: {
                                value:
                                  _vm.lineSaleConfigForm.refundDepartBeforeMin,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.lineSaleConfigForm,
                                    "refundDepartBeforeMin",
                                    $$v
                                  )
                                },
                                expression:
                                  "lineSaleConfigForm.refundDepartBeforeMin"
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticStyle: { width: "300px" } }, [
                              _vm._v("分钟以内退票，收费规则如下")
                            ]),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", [_vm._v("退票手续费率：按车票金额的")]),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: {
                                  width: "100px",
                                  margin: "0 4px"
                                },
                                attrs: {
                                  type: "number",
                                  onblur: "if(!value)value=0"
                                },
                                model: {
                                  value:
                                    _vm.lineSaleConfigForm
                                      .refundDepartBeforeServiceChargeRatio,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.lineSaleConfigForm,
                                      "refundDepartBeforeServiceChargeRatio",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "\n                lineSaleConfigForm.refundDepartBeforeServiceChargeRatio\n              "
                                }
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticStyle: { "font-style": "normal" },
                                    attrs: { slot: "suffix" },
                                    slot: "suffix"
                                  },
                                  [_vm._v("%")]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v("收取，最低收取")]),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: {
                                  width: "100px",
                                  margin: "0 4px"
                                },
                                attrs: {
                                  type: "tel",
                                  onblur: "if(!value)value=0"
                                },
                                model: {
                                  value:
                                    _vm.lineSaleConfigForm
                                      .refundDepartBeforeServiceChargeMin,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.lineSaleConfigForm,
                                      "refundDepartBeforeServiceChargeMin",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "lineSaleConfigForm.refundDepartBeforeServiceChargeMin"
                                }
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticStyle: { "font-style": "normal" },
                                    attrs: { slot: "suffix" },
                                    slot: "suffix"
                                  },
                                  [_vm._v("元")]
                                )
                              ]
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("span", { staticStyle: { width: "46px" } }, [
                                  _vm._v("发车后")
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticStyle: {
                                    width: "80px",
                                    margin: "0 4px"
                                  },
                                  attrs: {
                                    type: "number",
                                    onblur: "if(!value)value=0"
                                  },
                                  model: {
                                    value:
                                      _vm.lineSaleConfigForm
                                        .refundDepartAfterMin,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.lineSaleConfigForm,
                                        "refundDepartAfterMin",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "lineSaleConfigForm.refundDepartAfterMin"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticStyle: { width: "300px" } },
                                  [_vm._v("分钟以内退票，退票收费规则如下")]
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v("退票手续费率：按车票金额的")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-input",
                                  {
                                    staticStyle: {
                                      width: "100px",
                                      margin: "0 4px"
                                    },
                                    attrs: {
                                      type: "number",
                                      onblur: "if(!value)value=0"
                                    },
                                    model: {
                                      value:
                                        _vm.lineSaleConfigForm
                                          .refundDepartAfterServiceChargeRatio,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.lineSaleConfigForm,
                                          "refundDepartAfterServiceChargeRatio",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "\n                  lineSaleConfigForm.refundDepartAfterServiceChargeRatio\n                "
                                    }
                                  },
                                  [
                                    _c(
                                      "i",
                                      {
                                        staticStyle: { "font-style": "normal" },
                                        attrs: { slot: "suffix" },
                                        slot: "suffix"
                                      },
                                      [_vm._v("%")]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v("收取，最低收取")]),
                                _vm._v(" "),
                                _c(
                                  "el-input",
                                  {
                                    staticStyle: {
                                      width: "100px",
                                      margin: "0 4px"
                                    },
                                    attrs: {
                                      type: "number",
                                      onblur: "if(!value)value=0"
                                    },
                                    model: {
                                      value:
                                        _vm.lineSaleConfigForm
                                          .refundDepartAfterServiceChargeMin,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.lineSaleConfigForm,
                                          "refundDepartAfterServiceChargeMin",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "lineSaleConfigForm.refundDepartAfterServiceChargeMin"
                                    }
                                  },
                                  [
                                    _c(
                                      "i",
                                      {
                                        staticStyle: { "font-style": "normal" },
                                        attrs: { slot: "suffix" },
                                        slot: "suffix"
                                      },
                                      [_vm._v("元")]
                                    )
                                  ]
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "14px",
                                      color: "red"
                                    }
                                  },
                                  [_vm._v("备注：退票费=车票票款金额*退票费率")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("h4", { staticStyle: { "margin-bottom": "15px" } }, [
                      _vm._v("改签规则")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "班次是否允许改签" } },
                      [
                        _c("el-switch", {
                          model: {
                            value: _vm.lineSaleConfigForm.changeTicketSwitch,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.lineSaleConfigForm,
                                "changeTicketSwitch",
                                $$v
                              )
                            },
                            expression: "lineSaleConfigForm.changeTicketSwitch"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.lineSaleConfigForm.changeTicketSwitch
                      ? _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "30px" },
                            attrs: { label: "发车后是否允许改签" }
                          },
                          [
                            _c("el-switch", {
                              model: {
                                value:
                                  _vm.lineSaleConfigForm
                                    .changeTicketAfterDepartureSwitch,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.lineSaleConfigForm,
                                    "changeTicketAfterDepartureSwitch",
                                    $$v
                                  )
                                },
                                expression:
                                  "lineSaleConfigForm.changeTicketAfterDepartureSwitch"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _vm.lineSaleConfigForm.changeTicketSwitch
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "改签时间范围",
                              prop: "changeEndTime"
                            }
                          },
                          [
                            _c("span", { staticStyle: { width: "46px" } }, [
                              _vm._v("发车前")
                            ]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "80px", margin: "0 4px" },
                              attrs: {
                                type: "number",
                                onblur: "if(!value)value=0"
                              },
                              model: {
                                value:
                                  _vm.lineSaleConfigForm.changeDepartBeforeMin,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.lineSaleConfigForm,
                                    "changeDepartBeforeMin",
                                    $$v
                                  )
                                },
                                expression:
                                  "lineSaleConfigForm.changeDepartBeforeMin"
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticStyle: { width: "300px" } }, [
                              _vm._v("分钟以内改签")
                            ]),
                            _c("br"),
                            _c("br"),
                            _vm._v(" "),
                            _vm.lineSaleConfigForm
                              .changeTicketAfterDepartureSwitch
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { width: "46px" } },
                                      [_vm._v("发车后")]
                                    ),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "80px",
                                        margin: "0 4px"
                                      },
                                      attrs: {
                                        type: "number",
                                        onblur: "if(!value)value=0"
                                      },
                                      model: {
                                        value:
                                          _vm.lineSaleConfigForm
                                            .changeDepartAfterMin,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.lineSaleConfigForm,
                                            "changeDepartAfterMin",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "lineSaleConfigForm.changeDepartAfterMin"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticStyle: { width: "300px" } },
                                      [_vm._v("分钟以内改签")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    staticStyle: {
                      "align-self": "center",
                      "margin-top": "20px"
                    },
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.submitUpdateLineSaleConfigFn }
                      },
                      [_vm._v("保 存")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.currentTab == 4
          ? _c(
              "div",
              { staticClass: "item" },
              [
                _c(
                  "el-form",
                  {
                    ref: "lineForm",
                    attrs: {
                      "label-position": "left",
                      inline: true,
                      model: _vm.lineForm,
                      "label-width": "180px"
                    }
                  },
                  [
                    _c("h4", { staticStyle: { "margin-bottom": "15px" } }, [
                      _vm._v("发票配置")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "线上开发票" } },
                      [
                        _c("el-switch", {
                          model: {
                            value: _vm.lineForm.invoiceSwitch,
                            callback: function($$v) {
                              _vm.$set(_vm.lineForm, "invoiceSwitch", $$v)
                            },
                            expression: "lineForm.invoiceSwitch"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("h4", { staticStyle: { "margin-bottom": "15px" } }, [
                      _vm._v("乘车要求配置")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "乘客实名乘车" } },
                      [
                        _c("el-switch", {
                          model: {
                            value: _vm.lineForm.realNameRideSwitch,
                            callback: function($$v) {
                              _vm.$set(_vm.lineForm, "realNameRideSwitch", $$v)
                            },
                            expression: "lineForm.realNameRideSwitch"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("h4", { staticStyle: { "margin-bottom": "15px" } }, [
                      _vm._v("线路热门推荐")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "是否推荐至热门线路" } },
                      [
                        _c("el-switch", {
                          model: {
                            value: _vm.lineForm.hotLineSwitch,
                            callback: function($$v) {
                              _vm.$set(_vm.lineForm, "hotLineSwitch", $$v)
                            },
                            expression: "lineForm.hotLineSwitch"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("h4", { staticStyle: { "margin-bottom": "15px" } }, [
                      _vm._v("线路验票配置")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "可操作线路验票" } },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            model: {
                              value: _vm.lineForm.ticketTellerType,
                              callback: function($$v) {
                                _vm.$set(_vm.lineForm, "ticketTellerType", $$v)
                              },
                              expression: "lineForm.ticketTellerType"
                            }
                          },
                          [
                            _c("el-checkbox", { attrs: { label: "1" } }, [
                              _vm._v("司机")
                            ]),
                            _vm._v(" "),
                            _c("el-checkbox", { attrs: { label: "2" } }, [
                              _vm._v("引导员")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    staticStyle: {
                      "align-self": "center",
                      "margin-top": "20px"
                    },
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.submitUpdateLineConfigFn }
                      },
                      [_vm._v("保 存")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }