"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _login = require("@/api/login");

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _jsencrypt = require("@/utils/jsencrypt");

var _PanelGroup = _interopRequireDefault(require("@/views/dashboard/PanelGroup"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Login",
  components: {
    IndexPage: _PanelGroup.default
  },
  data: function data() {
    return {
      codeUrl: "",
      cookiePassword: "",
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: ""
      },
      loginRules: {
        username: [{
          required: true,
          trigger: "blur",
          message: "用户名不能为空"
        }],
        password: [{
          required: true,
          trigger: "blur",
          message: "密码不能为空"
        }],
        code: [{
          required: true,
          trigger: "change",
          message: "验证码不能为空"
        }]
      },
      loading: false,
      redirect: undefined,
      smsCodeText: '获取验证码',
      smsCodeIndex: 60
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created: function created() {
    this.getCode();
    this.getCookie();
  },
  methods: {
    getCode: function getCode() {
      var _this = this;

      (0, _login.getCodeImg)().then(function (res) {
        _this.codeUrl = "data:image/gif;base64," + res.img;
        _this.loginForm.uuid = res.uuid;
      });
    },
    smsCodeFn: function smsCodeFn() {
      var _this2 = this;

      // 获取验证码
      if (this.smsCodeText === '获取验证码' && this.loginForm.phone) {
        (0, _login.loginVerificationCode)(this.loginForm.phone).then(function (res) {
          if (res.code === 200) {
            _this2.smsCodeNumFn();

            _this2.msgSuccess("验证码已发送，请注意查收");
          }
        });
      } else {
        this.msgError("请确认手机号是否正确");
      }
    },
    smsCodeNumFn: function smsCodeNumFn() {
      // 倒计时
      var timeout = null;
      var num = this.smsCodeIndex - 1;

      if (num < 0) {
        this.smsCodeText = '获取验证码';
        clearTimeout(timeout);
      } else {
        this.smsCodeText = num + 's';
        this.smsCodeIndex = num;
        timeout = setTimeout(this.smsCodeNumFn, 1000);
      }
    },
    getCookie: function getCookie() {
      var username = _jsCookie.default.get("username");

      var password = _jsCookie.default.get("password");

      var rememberMe = _jsCookie.default.get('rememberMe');

      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password === undefined ? this.loginForm.password : (0, _jsencrypt.decrypt)(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      };
    },
    handleLogin: function handleLogin() {
      var _this3 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this3.loading = true;

          if (_this3.loginForm.rememberMe) {
            _jsCookie.default.set("username", _this3.loginForm.username, {
              expires: 30
            });

            _jsCookie.default.set("password", (0, _jsencrypt.encrypt)(_this3.loginForm.password), {
              expires: 30
            });

            _jsCookie.default.set('rememberMe', _this3.loginForm.rememberMe, {
              expires: 30
            });
          } else {
            _jsCookie.default.remove("username");

            _jsCookie.default.remove("password");

            _jsCookie.default.remove('rememberMe');
          }

          _this3.$store.dispatch("Login", _this3.loginForm).then(function (res) {
            if (res.code === 200) {
              _this3.$router.push({
                path: _this3.redirect || "/"
              });
            } else {
              _this3.messageFn(res.msg, 'error');

              _this3.getCode();

              _this3.loading = false;
            }
          }).catch(function () {
            _this3.loading = false;

            _this3.getCode();
          });
        }
      });
    }
  }
};
exports.default = _default;