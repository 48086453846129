"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime-corejs2/helpers/interopRequireWildcard"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

_vue.default.use(_vueRouter.default);
/* Layout */


/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    roles: ['admin','editor']    // 设置该路由进入的权限，支持多个权限叠加
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */
// 公共路由
var constantRoutes = [{
  path: "/redirect",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "/redirect/:path(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/redirect"));
      });
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login"));
    });
  },
  hidden: true
}, {
  path: "/dataAnalysisBus",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/dataAnalysisBus"));
    });
  },
  hidden: true
}, {
  path: "/dataAnalysisCarpool",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/dataAnalysisCarpool"));
    });
  },
  hidden: true
}, // {
// path: '',
// component: Layout,
// redirect: 'yewuguanli/busCountry/bus'
// children: [
//   {
//     path: 'index',
//     component: () => import('@/views/business/bus/index'),
//     name: '首页',
//     meta: { title: '首页', icon: 'dashboard', noCache: true, affix: true }
//   }
// ]
// },
{
  path: "",
  component: _layout.default,
  redirect: "index",
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/index"));
      });
    },
    name: "首页",
    meta: {
      title: "首页",
      icon: "dashboard",
      noCache: true,
      affix: true
    }
  }, {
    path: "/carpoolManage/orderManage/orderDetail",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/business/carpoolNew/orderDetail"));
      });
    },
    name: "OrderDetail",
    hidden: true,
    meta: {
      title: "订单详情"
    }
  }, {
    path: "/carpoolManage/orderManage/tripDetail",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/business/carpoolNew/tripDetail"));
      });
    },
    name: "TripDetail",
    hidden: true,
    meta: {
      title: "行程详情"
    }
  }, {
    path: "/marketManage/coupon/send",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/marketManage/couponNew/send"));
      });
    },
    name: "SendCoupon",
    hidden: true,
    meta: {
      title: "发放优惠券"
    }
  }, {
    path: "/marketManage/coupon/detail",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/marketManage/coupon/detail"));
      });
    },
    name: "CouponDetail",
    hidden: true,
    meta: {
      title: "优惠券详情"
    }
  }, {
    path: "/marketManage/coupon/sendgift",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/marketManage/coupon/sendgift"));
      });
    },
    name: "SendCouponGift",
    hidden: true,
    meta: {
      title: "发放优惠券礼包"
    }
  }, {
    path: "/financeCollect/driverBill/detail",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/financeCollect/driverBill/detail"));
      });
    },
    name: "driverBillDetail",
    hidden: true,
    meta: {
      title: "加盟司机收益明细"
    }
  }, {
    path: "/system/orderManage/detail",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/system/orderManage/detail"));
      });
    },
    name: "BusOrderDetail",
    hidden: true,
    meta: {
      title: "订单详情"
    }
  }]
}, {
  path: "/index",
  component: _layout.default // redirect: 'yewuguanli/busCountry/bus'

}, {
  path: "/user",
  component: _layout.default,
  hidden: true,
  redirect: "noredirect",
  children: [{
    path: "profile",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/system/user/profile/index"));
      });
    },
    name: "Profile",
    meta: {
      title: "个人中心",
      icon: "user"
    }
  }]
}, {
  path: "/dict",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "type/data/:dictId(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/system/dict/data"));
      });
    },
    name: "Data",
    meta: {
      title: "字典数据",
      icon: ""
    }
  }]
}, // 机场
{
  path: "/airport",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "airLineConfig",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/airport/line/config"));
      });
    },
    name: "AirLineConfig",
    meta: {
      title: "线路配置",
      icon: ""
    }
  }, {
    path: "station/addUpdate",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/airport/station/addUpdate"));
      });
    },
    name: "addUpdateAirStation",
    meta: {
      title: "站点"
    }
  }, {
    path: "order/detail",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/airport/order/detail"));
      });
    },
    name: "AirOrderDetail",
    meta: {
      title: "订单详情"
    }
  }]
}, {
  path: "/carpool",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "type/data/:lineId(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/business/carpool/listConfig"));
      });
    },
    name: "CarpoolOperationalListConfig",
    meta: {
      title: "运营范围配置",
      icon: ""
    }
  }, {
    path: "carpoolNewOrder",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/business/carpoolNew/carpoolNewOrder"));
      });
    },
    name: "CarpoolNewOrder",
    meta: {
      title: "新建订单",
      icon: ""
    }
  }, {
    path: "listConfig/:lineId(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/business/carpoolNew/line/listConfig"));
      });
    },
    name: "CarpoolConfig",
    meta: {
      title: "线路配置",
      icon: ""
    }
  }]
}, {
  path: "/parcel",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "type/data/:parcelId(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/business/newParcel/configList"));
      });
    },
    name: "ParcelOperationalListConfig",
    meta: {
      title: "运营范围配置",
      icon: ""
    }
  }]
}, {
  path: "/job",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "log",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/monitor/job/log"));
      });
    },
    name: "JobLog",
    meta: {
      title: "调度日志"
    }
  }]
}, {
  path: "/gen",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "edit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/tool/gen/editTable"));
      });
    },
    name: "GenEdit",
    meta: {
      title: "修改生成配置"
    }
  }]
}, {
  path: "/passenger",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "edit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/system/passenger/edit"));
      });
    },
    name: "PassengerEdit",
    meta: {
      title: "用户详情"
    }
  }]
}, {
  path: "/driverDetail",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "detail",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/business/carpoolNew/driver/detail"));
      });
    },
    name: "driverDetail",
    meta: {
      title: "司机详情"
    }
  }]
}, {
  path: "/invoiceDetail",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "detail",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/financeCollect/orderInvoice/detail"));
      });
    },
    name: "invoiceDetail",
    meta: {
      title: "发票详情"
    }
  }]
}, {
  path: "/stationRankList",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "edit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/system/stationRankList/index"));
      });
    },
    name: "StationRankList",
    meta: {
      title: "站点排行榜"
    }
  }]
}, {
  path: "/count_detail",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "edit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/system/dept/count_details"));
      });
    },
    name: "CountDetail",
    meta: {
      title: "结算记录"
    }
  }]
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error/404"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error/401"));
    });
  },
  hidden: true
}];
exports.constantRoutes = constantRoutes;

var _default = new _vueRouter.default({
  // mode: 'history', // 去掉url中的#
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRoutes
});

exports.default = _default;