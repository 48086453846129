"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _carpoolNew = require("@/api/business/carpoolNew");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      generalForm: {
        carpoolLineConfigCJBC: {},
        carpoolLineConfigCJPC: {}
      },
      rules: {
        "maxCarpoolOrderNum": [{
          required: true,
          message: "请完善表单项",
          trigger: "blur"
        }],
        "maxCharterOrderNum": [{
          required: true,
          message: "请完善表单项",
          trigger: "blur"
        }],
        "sellTicketRule": [{
          required: true,
          message: "请完善表单项",
          trigger: "blur"
        }],
        "pickupEarlyMin": [{
          required: true,
          message: "请完善表单项",
          trigger: "blur"
        }],
        "departureEarlyMin": [{
          required: true,
          message: "请完善表单项",
          trigger: "blur"
        }],
        "travelMergeRule": [{
          required: true,
          message: "请完善表单项",
          trigger: "blur"
        }],
        "pickupMin": [{
          required: true,
          message: "请完善表单项",
          trigger: "blur"
        }]
      }
    };
  },
  props: {
    openGeneralConfigDialog: {
      type: Boolean,
      default: false
    },
    lineData: {
      type: Object,
      required: true
    }
  },
  created: function created() {
    this.getConfig();
  },
  methods: {
    /** 获取配置 */
    getConfig: function getConfig() {
      var _this = this;

      (0, _carpoolNew.lineCommonConfigInfo)({
        lineId: this.lineData.id
      }).then(function (res) {
        if (res.code === 200) {
          if (res.data) {
            _this.generalForm = res.data;
          } else {
            _this.generalForm = {
              maxCarpoolOrderNum: 1,
              maxCharterOrderNum: 1,
              sellTicketRule: '1',
              pickupEarlyMin: 0,
              departureEarlyMin: 0,
              travelMergeRule: '0',
              pickupMin: 0
            };
          }

          console.log(_this.generalForm, "----");
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    onSubmit: function onSubmit() {
      console.log("submit!");
    },
    handleChange: function handleChange(e) {
      console.log(e, "submit!");
    },
    handleDialogClose: function handleDialogClose() {
      this.$emit("closepop"); //通知父组件改变。
    },
    handleSubmit: function handleSubmit() {
      var _this2 = this;

      this.$refs.generalForm.validate(function (valid) {
        if (valid) {
          _this2.generalForm.lineId = _this2.lineData.id;
          (0, _carpoolNew.lineCommonConfigAddOrUpdate)(_this2.generalForm).then(function (res) {
            console.log(res, "----");

            if (res.code === 200) {
              _this2.$message.success(res.msg);
            } else {
              _this2.$message.error(res.msg);
            }

            _this2.$emit("closepop"); //通知父组件改变。

          });
        }
      });
    }
  }
};
exports.default = _default;