"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listDept = listDept;
exports.getDept = getDept;
exports.treeselect = treeselect;
exports.roleDeptTreeselect = roleDeptTreeselect;
exports.addDept = addDept;
exports.updateDept = updateDept;
exports.delDept = delDept;
exports.getServices = getServices;
exports.getCityList = getCityList;
exports.getTravelCityList = getTravelCityList;
exports.getDeptHotLine = getDeptHotLine;
exports.editHotLineByCompanyId = editHotLineByCompanyId;
exports.getCheckType = getCheckType;
exports.updateCheckType = updateCheckType;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询部门列表
function listDept(params) {
  return (0, _request.default)({
    url: '/system/dept/list',
    method: 'get',
    params: params
  });
} // 查询部门详细


function getDept(deptId) {
  return (0, _request.default)({
    url: '/system/dept/' + deptId,
    method: 'get'
  });
} // 查询部门下拉树结构


function treeselect() {
  return (0, _request.default)({
    url: '/system/dept/treeselect',
    method: 'get'
  });
} // 根据角色ID查询部门树结构


function roleDeptTreeselect(roleId) {
  return (0, _request.default)({
    url: '/system/dept/roleDeptTreeselect/' + roleId,
    method: 'get'
  });
} // 新增部门


function addDept(data) {
  return (0, _request.default)({
    url: '/system/dept',
    method: 'post',
    data: data
  });
} // 修改部门


function updateDept(data) {
  return (0, _request.default)({
    url: '/system/dept',
    method: 'put',
    data: data
  });
} // 删除部门


function delDept(deptId) {
  return (0, _request.default)({
    url: '/system/dept/' + deptId,
    method: 'delete'
  });
} // 查询业务


function getServices() {
  return (0, _request.default)({
    url: '/system/services/list',
    method: 'get'
  });
} // 查询城市


function getCityList(params) {
  return (0, _request.default)({
    url: '/bus/city/listCity',
    method: 'get',
    params: params
  });
}

function getTravelCityList(params) {
  return (0, _request.default)({
    url: '/travel/city/listCity',
    method: 'get',
    params: params
  });
} // 获取公司的客服电话


function getDeptHotLine(params) {
  return (0, _request.default)({
    url: '/system/dept/getDeptHotLine',
    method: 'get',
    params: params
  });
} // 设置公司的客服电话


function editHotLineByCompanyId(data) {
  return (0, _request.default)({
    url: '/system/dept/editHotLineByCompanyId',
    method: 'put',
    data: data
  });
} // 获取公司的验票方式


function getCheckType(params) {
  return (0, _request.default)({
    url: '/system/dept/getCheckType',
    method: 'get',
    params: params
  });
} // 设置公司的验票方式


function updateCheckType(data) {
  return (0, _request.default)({
    url: '/system/dept/updateCheckType',
    method: 'put',
    data: data
  });
}