import request from '@/utils/request'; // 查询费率配置列表

export function listConifg(params) {
  return request({
    url: '/system/finance/config/bus/list',
    method: 'get',
    params: params
  });
} // 修改配置列表

export function getUpdateConifg(params) {
  return request({
    url: '/system/finance/config/bus/' + params,
    method: 'get'
  });
} // 查询费率配置详细

export function getConifg(id) {
  return request({
    url: '/system/conifg/' + id,
    method: 'get'
  });
} // 新增费率配置

export function addConifg(data) {
  return request({
    url: '/system/conifg',
    method: 'post',
    data: data
  });
} // 修改费率配置

export function updateConifg(data) {
  return request({
    url: '/system/finance/config/bus/update',
    method: 'post',
    data: data
  });
} // 删除费率配置

export function delConifg(id) {
  return request({
    url: '/system/conifg/' + id,
    method: 'delete'
  });
} // 导出费率配置

export function exportConifg(params) {
  return request({
    url: '/system/conifg/export',
    method: 'get',
    params: params
  });
} // 财务模块列表

export function reportList(params) {
  return request({
    url: '/system/finance/report/list',
    method: 'get',
    params: params
  });
} // 财务模块汇总列表

export function reportSum(params) {
  return request({
    url: '/system/finance/report/sum',
    method: 'get',
    params: params
  });
} // 日收支对账汇总

export function dailyList(params) {
  return request({
    url: '/system/finance/verify/daily/list',
    method: 'get',
    params: params
  });
} // 日收支对账明细

export function detailsList(params) {
  return request({
    url: '/system/finance/verify/daily/details/list',
    method: 'get',
    params: params
  });
} // 日收支对账明细汇总

export function detailsSum(params) {
  return request({
    url: '/system/finance/verify/daily/details/sum',
    method: 'get',
    params: params
  });
} // 描述：财务模块-收支对账明细导出接口

export function detailsExport(data) {
  return request({
    url: '/system/finance/verify/details/export',
    method: 'post',
    data: data
  });
} // 描述：财务模块-日收支对账明细导出接口

export function verifyExport(data) {
  return request({
    url: '/system/finance/verify',
    method: 'post',
    data: data
  });
} // 月收支对账明细汇总

export function monthlyList(params) {
  return request({
    url: '/system/finance/verify/monthly/list',
    method: 'get',
    params: params
  });
} // 月收支对账明细汇总 - 重新对账功能接口

export function verifyUpdate(data) {
  return request({
    url: '/system/finance/verify/daily/check',
    method: 'post',
    data: data
  });
} // 描述：财务模块-月度重新对账接口

export function monthlyUpdate(data) {
  return request({
    url: '/system/finance/verify/monthly/check',
    method: 'post',
    data: data
  });
} // 财务模块-结算接口

export function monthlySettle(data) {
  return request({
    url: '/system/finance/verify/monthly/settle',
    method: 'post',
    data: data
  });
} // 描述：财务汇总-异常订单处理-退款接口

export function refundFn(data) {
  return request({
    url: '/system/finance/error/refund',
    method: 'post',
    data: data
  });
} // 描述：财务汇总-异常订单处理-退款列表查询接口

export function refundList(params) {
  return request({
    url: '/system/finance/error/refund/list',
    method: 'get',
    params: params
  });
} // 描述：财务汇总-异常订单处理-退款汇总接口

export function refundSum(params) {
  return request({
    url: '/system/finance/error/refund/sum',
    method: 'get',
    params: params
  });
} // 财务模块-结算列表接口

export function countList(params) {
  return request({
    url: '/system/finance/settle/log/list',
    method: 'get',
    params: params
  });
} // 描述：小件物流退款接口

export function deliveryRefund(data) {
  return request({
    url: '/logistics/bus/refund',
    method: 'post',
    data: data
  });
} // 描述：小件物流列表接口

export function deliveryList(params) {
  return request({
    url: '/logistics/bus/list',
    method: 'get',
    params: params
  });
} // 描述：出入库操作

export function deliveryListUpdate(data) {
  return request({
    url: '/logistics/bus/update',
    method: 'post',
    data: data
  });
}
/* 发票模块 */
// 描述：获取发票列表

export function getInvoices(params) {
  return request({
    url: '/order/orderInvoice/getInvoices',
    method: 'get',
    params: params
  });
} // 描述：导出发票列表

export function InvoicesExport(params) {
  return request({
    url: '/order/orderInvoice/export',
    method: 'get',
    params: params
  });
} // 描述：获取发票中的订单列表

export function getOrderOfInvoice(params) {
  return request({
    url: '/order/orderInvoice/getOrderOfInvoice',
    method: 'get',
    params: params
  });
} // 描述：开票操作

export function invoicing(data) {
  return request({
    url: '/order/orderInvoice/invoicing',
    method: 'post',
    data: data
  });
} // 描述：修改开票操作

export function editInvoice(data) {
  return request({
    url: '/order/orderInvoice/editInvoice',
    method: 'post',
    data: data
  });
} // 描述：查看行程单（PDF）

export function travelPDF(params) {
  return request({
    responseType: 'blob',
    url: '/order/orderInvoice/travelPDF',
    method: 'get',
    params: params
  });
} // 描述：发送邮件

export function sendEmail(params) {
  return request({
    url: '/order/orderInvoice/sendEmail',
    method: 'get',
    params: params
  });
} // 描述：获取发票的修改记录

export function getInvoiceRecords(params) {
  return request({
    url: '/order/orderInvoice/getInvoiceRecords',
    method: 'get',
    params: params
  });
}