"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime-corejs2/helpers/objectSpread2"));

var _carpoolNew = require("@/api/business/carpoolNew");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    openDialogCancelConfig: {
      type: Boolean,
      default: false
    },
    lineData: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      generalForm: {},
      activeName: "",
      serviceType: [] // 服务类型

    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      // 获取线路配置的服务类型
      (0, _carpoolNew.lineVehicleModelConfigService)({
        lineId: this.lineData.id
      }).then(function (res) {
        if (res.code == 200) {
          _this.serviceType = res.data;
          _this.activeName = _this.serviceType[0];

          _this.getlineCancelRuleConfigInfo(_this.activeName);
        }
      });
    },

    /** 获取取消配置 */
    getlineCancelRuleConfigInfo: function getlineCancelRuleConfigInfo(serviceType) {
      var _this2 = this;

      // 获取线路配置的服务类型
      (0, _carpoolNew.lineCancelRuleConfigInfo)({
        lineId: this.lineData.id,
        serviceType: serviceType
      }).then(function (res) {
        if (res.code == 200) {
          if (res.data) {
            _this2.generalForm = res.data;
          } else {
            _this2.generalForm = {};
          }
        }
      });
    },
    // 点击服务tab
    handleTabClick: function handleTabClick(tab, event) {
      this.getlineCancelRuleConfigInfo(this.activeName);
    },
    handleDialogClose: function handleDialogClose() {
      this.$emit("closepop"); //通知父组件改变。
    },
    onSubmit: function onSubmit() {
      var _this3 = this;

      var params = {
        lineId: this.lineData.id,
        serviceType: this.activeName
      };
      (0, _carpoolNew.lineCancelRuleConfigAddOrUpdate)((0, _objectSpread2.default)({}, params, {}, this.generalForm)).then(function (res) {
        console.log(res, "----");

        if (res.code === 200) {
          _this3.$message.success(res.msg);
        } else {
          _this3.$message.error(res.msg);
        }

        _this3.handleDialogClose();
      });
    }
  }
};
exports.default = _default;