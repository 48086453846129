"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _carpoolNew = require("@/api/business/carpoolNew");

var _mapLineComponents = _interopRequireDefault(require("./components/mapLineComponents"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    mapLineComponents: _mapLineComponents.default
  },
  data: function data() {
    return {
      showMap: false,
      orderNo: null,
      orderDetail: {
        orderInfo: {},
        orderTrack: [],
        peerOrders: [],
        orderLatLng: []
      },
      opneOrderRecordAudio: false,
      // 打开通话录音弹框
      audioSrc: '',
      // 行程录音链接
      orderAudioList: [],
      // 通话录音集合
      opneAudio: false // 播放录音弹框

    };
  },
  created: function created() {
    this.orderNo = this.$route.query.orderNo;
    this.getDetail();
  },
  methods: {
    /** 获取订单详情 */
    getDetail: function getDetail() {
      var _this = this;

      (0, _carpoolNew.orderSelectByOrderNo)({
        orderNo: this.orderNo
      }).then(function (res) {
        _this.orderDetail = res.data;
      }); // 订单线路

      (0, _carpoolNew.orderTrajectory)({
        orderNo: this.orderNo
      }).then(function (res) {
        _this.orderDetail.orderLatLng = res.data;

        if (_this.orderDetail.orderLatLng && _this.orderDetail.orderLatLng.length > 0) {
          _this.showMap = true;
        }
      });
    },
    // 通话记录
    opneOrderRecordAudioFn: function opneOrderRecordAudioFn() {
      var _this2 = this;

      (0, _carpoolNew.queryRecodeByOrderNo)({
        orderNo: this.orderNo
      }).then(function (res) {
        _this2.opneOrderRecordAudio = true;
        _this2.orderAudioList = res.data;
      });
    },
    // 播放录音
    opneAudioFn: function opneAudioFn(row) {
      this.audioSrc = row.recordOssUrl;
      this.opneAudio = true;
    }
  }
};
exports.default = _default;