var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        [
          _c("el-col", { staticClass: "pr20", attrs: { span: 14 } }, [
            _vm.orderDetail.orderInfo.orderStatus != 100
              ? _c(
                  "h2",
                  { staticClass: "operationBox" },
                  [
                    _vm._v("\n        订单操作：\n        "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success", size: "small" },
                        on: { click: _vm.opneOrderRecordAudioFn }
                      },
                      [_vm._v("通话记录")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "title" }, [_vm._v("订单信息")]),
            _vm._v(" "),
            _c("table", { staticClass: "infoTable mb40" }, [
              _c("tr", [
                _c("td", { staticClass: "tr bold", attrs: { width: "10%" } }, [
                  _vm._v("行程编号")
                ]),
                _vm._v(" "),
                _c("td", { attrs: { width: "30%" } }, [
                  _vm._v(_vm._s(_vm.orderDetail.orderInfo.travelId))
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "tr bold", attrs: { width: "10%" } }, [
                  _vm._v("订单号")
                ]),
                _vm._v(" "),
                _c("td", { attrs: { width: "30%" } }, [
                  _vm._v(_vm._s(_vm.orderDetail.orderInfo.orderNo))
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", { staticClass: "tr bold", attrs: { width: "10%" } }, [
                  _vm._v("订单类型")
                ]),
                _vm._v(" "),
                _c("td", { attrs: { width: "30%" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.orderDetail.orderInfo.serviceType == "carpool"
                        ? "城际拼车"
                        : "城际包车"
                    )
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "tr bold", attrs: { width: "10%" } }, [
                  _vm._v("订单备注")
                ]),
                _vm._v(" "),
                _c("td", { attrs: { width: "30%" } }, [
                  _vm._v(_vm._s(_vm.orderDetail.orderInfo.note))
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", { staticClass: "tr bold" }, [_vm._v("订单金额")]),
                _vm._v(" "),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm._v(
                    "\n            总金额：" +
                      _vm._s(_vm.orderDetail.orderInfo.orderMoney) +
                      " \n            实付金额：" +
                      _vm._s(_vm.orderDetail.orderInfo.orderActuallyPayMoney) +
                      " \n            优惠金额：" +
                      _vm._s(_vm.orderDetail.orderInfo.couponMoney) +
                      " \n            退款金额：" +
                      _vm._s(
                        _vm.orderDetail.orderInfo.refundMoney
                          ? _vm.orderDetail.orderInfo.refundMoney
                          : 0
                      ) +
                      " \n            退款手续费：" +
                      _vm._s(_vm.orderDetail.orderInfo.refundServiceCharge) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", { staticClass: "tr bold", attrs: { width: "10%" } }, [
                  _vm._v("优惠承担方")
                ]),
                _vm._v(" "),
                _c("td", { attrs: { width: "30%" } }, [
                  _vm._v(
                    "\n            平台：" +
                      _vm._s(_vm.orderDetail.orderInfo.couponPlatformMoney) +
                      "元、\n            加盟商：" +
                      _vm._s(_vm.orderDetail.orderInfo.couponCompanyMoney) +
                      "元\n          "
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "tr bold", attrs: { width: "10%" } }, [
                  _vm._v("手续费分佣")
                ]),
                _vm._v(" "),
                _c("td", { attrs: { width: "30%" } }, [
                  _vm._v(
                    "\n            平台：" +
                      _vm._s(
                        _vm.orderDetail.orderInfo.refundPlatformCommission
                      ) +
                      "元、\n            加盟商：" +
                      _vm._s(
                        _vm.orderDetail.orderInfo.refundCompanyCommission
                      ) +
                      "元、\n            司机：" +
                      _vm._s(_vm.orderDetail.orderInfo.refundDriverCommission) +
                      "元\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", { staticClass: "tr bold" }, [_vm._v("订单状态")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.filterOrderStatus(
                        _vm.orderDetail.orderInfo.orderStatus
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "tr bold" }, [_vm._v("订单退款时间")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(_vm.orderDetail.orderInfo.refundSuccessTime))
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", { staticClass: "tr bold" }, [_vm._v("订单取消方式")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.orderDetail.orderInfo.orderStatus == 102
                        ? _vm.filterOperatorType(
                            _vm.orderDetail.orderInfo.operatorType
                          )
                        : ""
                    )
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "tr bold" }, [_vm._v("订单取消时间")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.orderDetail.orderInfo.cancelTime) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", { staticClass: "tr bold" }, [_vm._v("司机")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.orderDetail.orderInfo.driverPhone) +
                      " " +
                      _vm._s(_vm.orderDetail.orderInfo.driverName) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "tr bold" }, [_vm._v("车型")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.orderDetail.orderInfo.carNo) +
                      "/" +
                      _vm._s(_vm.orderDetail.orderInfo.brand) +
                      "\n          "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "title" }, [_vm._v("订单追踪")]),
            _vm._v(" "),
            _c("div", { staticClass: "clearfix" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "cardBox" },
              _vm._l(_vm.orderDetail.orderTrack, function(item) {
                return _c("div", { key: item.id, staticClass: "cardItem" }, [
                  _c("div", { staticClass: "cardItemTop" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.orderTrackStatus(item.status)))
                    ]),
                    _vm._v(
                      _vm._s(_vm.parseTime(item.dateTime)) + "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  item.record
                    ? _c("div", { staticClass: "cardItemCont" }, [
                        item.status != 200
                          ? _c("div", { staticClass: "infoItem" }, [
                              _c("span", {
                                staticClass: "iconfont icon-auto mr10"
                              }),
                              _vm._v(" "),
                              item.status != 301
                                ? _c("span", [_vm._v(_vm._s(item.record.msg))])
                                : _vm._e(),
                              _vm._v(" "),
                              item.status == 301
                                ? _c("span", [
                                    _vm._v(
                                      "退款金额：" +
                                        _vm._s(item.record.refundMoney) +
                                        "元， 退款服务费：" +
                                        _vm._s(
                                          item.record.refundServiceCharge
                                        ) +
                                        "元 " +
                                        _vm._s(
                                          item.record.operator
                                            ? "，退款操作人：" +
                                                item.record.operator
                                            : ""
                                        )
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        item.status == 200
                          ? _c(
                              "div",
                              {
                                staticClass: "infoItem",
                                staticStyle: {
                                  "flex-direction": "column",
                                  "align-items": "start"
                                }
                              },
                              [
                                _c("div", { staticClass: "infoItem" }, [
                                  _c("span", { staticClass: "mr20" }, [
                                    _c("span", {
                                      staticClass: "iconfont icon-account-fill"
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.phoneFormat(
                                            item.record.passengerPhone
                                          )
                                        )
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "mr20" }, [
                                    _vm._v(
                                      _vm._s(item.record.passengerNum) + "人"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "mr10" }, [
                                    _c("i", [
                                      _vm._v(_vm._s(item.record.channelName))
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "infoItem" }, [
                                  _c("span", { staticClass: "circle" }),
                                  _vm._v(_vm._s(item.record.upAddress))
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "infoItem" }, [
                                  _c("span", {
                                    staticClass: "circle",
                                    staticStyle: { background: "red" }
                                  }),
                                  _vm._v(_vm._s(item.record.downAddress))
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "infoItem" }, [
                                  _vm._v(
                                    "用车时间：" + _vm._s(item.record.usageTime)
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ])
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c(
                "div",
                { staticClass: "title", staticStyle: { "margin-top": "52px" } },
                [_vm._v("订单路线")]
              ),
              _vm._v(" "),
              _vm.showMap
                ? _c("mapLineComponents", {
                    ref: "mapLineComponents",
                    staticClass: "mb40",
                    attrs: { orderDetail: _vm.orderDetail }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v("同行订单")]),
              _vm._v(" "),
              _c("table", { staticClass: "infoTable mt10" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("订单号")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("下单人")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("乘车人数")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("订单状态")])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.orderDetail.peerOrders, function(item) {
                    return _c("tr", { key: "peerOrders" + item.orderNo }, [
                      _c("td", { attrs: { align: "center" } }, [
                        _vm._v(_vm._s(item.orderNo))
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { align: "center" } }, [
                        _vm._v(
                          "尾号" +
                            _vm._s(_vm.lastSubstring(item.passengerPhone, 4))
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { align: "center" } }, [
                        _vm._v(_vm._s(item.passengerNum))
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { align: "center" } }, [
                        _vm._v(_vm._s(_vm.filterOrderStatus(item.orderStatus)))
                      ])
                    ])
                  }),
                  0
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.opneOrderRecordAudio
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "通话录音",
                visible: _vm.opneOrderRecordAudio,
                "close-on-click-modal": false,
                "append-to-body": true,
                width: "70%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.opneOrderRecordAudio = $event
                }
              }
            },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.orderAudioList }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "passengerNo", label: "乘客手机号" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "privateNo", label: "乘客虚拟号" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "driverNo", label: "司机手机号" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "callType", label: "通话类型" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "callTime", label: "通话拨打时间" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "startTime", label: "通话接听时间" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "releaseTime", label: "通话挂断时间" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "duration", label: "通话时长（秒）" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "releaseDir", label: "挂断方式" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "dateTotal", label: "操作" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.opneAudioFn(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("播放\n          ")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1057852566
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.opneAudio
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "通话录音",
                visible: _vm.opneAudio,
                "close-on-click-modal": false,
                "append-to-body": true,
                width: "70%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.opneAudio = $event
                }
              }
            },
            [
              _c("audio", {
                ref: "audio",
                attrs: { id: "", src: _vm.audioSrc, controls: "" }
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }