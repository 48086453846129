"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime-corejs2/helpers/objectSpread2"));

var _carpoolNew = require("@/api/business/carpoolNew");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Detail",
  data: function data() {
    return {
      user: {
        baseInfo: {},
        accountInfo: {},
        loginInfo: {}
      },
      tableData: [],
      tableData2: [],
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      // 总条数
      billTotal: 0,
      // 查询参数
      queryBillParams: {
        pageNum: 1,
        pageSize: 10
      },
      id: null,
      companyId: null,
      activeTab: "order"
    };
  },
  created: function created() {
    this.id = this.$route.query.id;
    this.companyId = this.$route.query.companyId;
    this.getDetail();
    this.geOrdertList(); // this.getBillList()
  },
  methods: {
    // 订单状态
    filterStatus: function filterStatus(value) {
      switch (value) {
        case 100:
          return "未指派";

        case 101:
          return "已指派";

        case 102:
          return "已取消";

        case 103:
          return "进行中";

        case 104:
          return "已完成";

        default:
          return "暂无";
      }
    },
    // 支付状态
    filterPayStatus: function filterPayStatus(value) {
      switch (value) {
        case 200:
          return "待支付";

        case 201:
          return "支付成功";

        case 203:
          return "取消支付";

        default:
          return "暂无";
      }
    },
    // 退款状态
    filterRefundStatus: function filterRefundStatus(value) {
      switch (value) {
        case 300:
          return "未退款";

        case 301:
          return "退款中";

        case 302:
          return "退款失败";

        case 303:
          return "退款成功";

        default:
          return "暂无";
      }
    },
    // 订单来源
    filterChannel: function filterChannel(value, scanCode) {
      switch (value) {
        case 1:
          return "客服代买票";

        case 2:
          if (scanCode == 1) {
            return '扫码购票';
          }

          return "小程序买票";

        case "WECHAT_MINI_APP_SO":
          return "拼车扫码下单";

        default:
          return "暂无";
      }
    },

    /** 获取司机详情 */
    getDetail: function getDetail() {
      var _this = this;

      (0, _carpoolNew.driverQueryDriverDetails)({
        driverId: this.id
      }).then(function (response) {
        _this.user = response.data;
      });
    },

    /** 获取司机订单列表 */
    geOrdertList: function geOrdertList() {
      var _this2 = this;

      (0, _carpoolNew.driverDetailsOrderList)((0, _objectSpread2.default)({}, this.queryParams, {
        driverId: this.id
      })).then(function (response) {
        _this2.tableData = response.rows;
        _this2.total = response.total;
      });
    },

    /** 获取司机账单列表 */
    getBillList: function getBillList() {
      var _this3 = this;

      getDriverBillList((0, _objectSpread2.default)({}, this.queryBillParams, {
        driverId: this.id
      })).then(function (response) {
        _this3.tableData2 = response.rows;
        _this3.billTotal = response.total;
      });
    },
    // 操作账户是否冻结
    disableAccount: function disableAccount() {
      var _this4 = this;

      var params = {
        driverId: this.id,
        accountFreeze: this.user.accountInfo.accountFreeze ? 0 : 1 //1-冻结 0-解冻 是否冻结司机,不让司机提现

      };
      (0, _carpoolNew.driverFreezeDriverAccount)(params).then(function (response) {
        if (response.code === 200) {
          _this4.getDetail();

          _this4.msgSuccess("操作成功");
        } else {
          _this4.msgError(response.msg);
        }
      });
    },
    viewDetails: function viewDetails(row) {
      this.$router.push("/carpoolManage/orderManage/orderDetail?orderNo=" + row.orderNo);
    }
  }
};
exports.default = _default;