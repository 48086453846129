var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "details_content" },
      [
        _c("span", { staticClass: "title" }, [_vm._v("订单信息")]),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.getOrderDetail }
          },
          [_vm._v("点击获取订单信息")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "div_wrapper" }, [
          _c("div", [
            _vm._v("\n      订单号码："),
            _c("span", [_vm._v(_vm._s(_vm.orderNo || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      下单时间： "),
            _c("span", [_vm._v(_vm._s(_vm.form.created || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      支付流水号码： "),
            _c("span", [_vm._v(_vm._s(_vm.form.tradeNo || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      公司名称： "),
            _c("span", [_vm._v(_vm._s(_vm.form.deptName || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      订单状态： "),
            _c("span", [_vm._v(_vm._s(_vm.form.showStatusMsg || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      下单手机： "),
            _c("span", [_vm._v(_vm._s(_vm.form.passengerPhone || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      业务类型： "),
            _c("span", [_vm._v(_vm._s(_vm.form.businessType || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      支付时间： "),
            _c("span", [_vm._v(_vm._s(_vm.form.gmtPayment || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      支付方式： "),
            _c("span", [_vm._v(_vm._s(_vm.form.payChannel || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      订单来源： "),
            _c("span", [_vm._v(_vm._s(_vm.form.orderChannel || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      乘车人： "),
            _c("span", [_vm._v(_vm._s(_vm.form.rider || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      未验票人数： "),
            _c("span", [_vm._v(_vm._s(_vm.form.totalNoChecking || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      验票人数： "),
            _c("span", [_vm._v(_vm._s(_vm.form.totalChecking || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      退款人数： "),
            _c("span", [_vm._v(_vm._s(_vm.form.totalRefundsNumber || "--"))])
          ])
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "title" }, [_vm._v("班次信息")]),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.orderSchedule }
          },
          [_vm._v("点击获取班次信息")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "div_wrapper" }, [
          _c("div", [
            _vm._v("\n      线路名称： "),
            _c("span", [_vm._v(_vm._s(_vm.scheduleDetail.shortName || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      发车时间： "),
            _c("span", [
              _vm._v(_vm._s(_vm.scheduleDetail.departureTime || "--"))
            ])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      行程状态： "),
            _c("span", [
              _vm._v(_vm._s(_vm.scheduleDetail.ticketStatus || "--"))
            ])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      起点： "),
            _c("span", [
              _vm._v(_vm._s(_vm.scheduleDetail.startStationName || "--"))
            ])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      车牌号码： "),
            _c("span", [_vm._v(_vm._s(_vm.scheduleDetail.carNo || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      终点： "),
            _c("span", [
              _vm._v(_vm._s(_vm.scheduleDetail.endStationName || "--"))
            ])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      司机姓名： "),
            _c("span", [
              _vm._v(_vm._s(_vm.scheduleDetail.driverNickName || "--"))
            ])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      票价： "),
            _c("span", [_vm._v(_vm._s(_vm.scheduleDetail.money || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      司机手机： "),
            _c("span", [_vm._v(_vm._s(_vm.scheduleDetail.driverPhone || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      短信条数： "),
            _c("span", [_vm._v(_vm._s(_vm.scheduleDetail.countSms || "--"))])
          ])
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "title" }, [_vm._v("乘客信息")]),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.getPersonDetail }
          },
          [_vm._v("点击获取乘客信息")]
        ),
        _vm._v(" "),
        _c(
          "el-table",
          { staticStyle: { width: "100%" }, attrs: { data: _vm.formtable } },
          [
            _c("el-table-column", {
              attrs: { label: "姓名", align: "center", prop: "passengerName" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "手机号码",
                align: "center",
                prop: "passengerPhone"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "乘客类型",
                align: "center",
                prop: "passengerType"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "证件类型", align: "center", prop: "idcardType" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "证件号码",
                align: "center",
                prop: "passengerIdcard"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "验票码", align: "center", prop: "rideCode" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "验票状态",
                align: "center",
                prop: "checkedStatus"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "发起退票时间",
                align: "center",
                prop: "refundTicketTime"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "退款时间", align: "center", prop: "refundTime" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "退款金额", align: "center", prop: "amt" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "票价", align: "center", prop: "money" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "优惠金额",
                align: "center",
                prop: "discountedPrice"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "平台承担优惠金额",
                align: "center",
                prop: "platformMoney"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "加盟商承担优惠金额",
                align: "center",
                prop: "franchiseeMoney"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "手续费",
                align: "center",
                prop: "deductionMoney"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "退款路径", align: "center", prop: "channel" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "流水号码",
                align: "center",
                prop: "serialNumber"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "退款状态",
                align: "center",
                prop: "showStatusMsg"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("span", { staticClass: "title" }, [_vm._v("金额明细")]),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.orderMoney }
          },
          [_vm._v("点击获取金额明细")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "div_wrapper" }, [
          _c("div", [
            _vm._v("\n      订单应收： "),
            _c("span", [_vm._v(_vm._s(_vm.moneyDetail.orderMoney || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      订单金额： "),
            _c("span", [_vm._v(_vm._s(_vm.moneyDetail.orderMoney || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      退款金额： "),
            _c("span", [
              _vm._v(_vm._s(_vm.moneyDetail.totalRefundsMoney || "--"))
            ])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      短信费： "),
            _c("span", [_vm._v(_vm._s(_vm.moneyDetail.smsMoney || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      优惠金额： "),
            _c("span", [
              _vm._v(_vm._s(_vm.moneyDetail.discountedPrice || "--"))
            ])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      平台承担优惠金额： "),
            _c("span", [_vm._v(_vm._s(_vm.moneyDetail.platformMoney || "--"))])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      加盟商承担优惠金额： "),
            _c("span", [
              _vm._v(_vm._s(_vm.moneyDetail.franchiseeMoney || "--"))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "title" }, [_vm._v("订单日志")]),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.getLogs }
          },
          [_vm._v("点击获取订单日志")]
        ),
        _vm._v(" "),
        _c(
          "el-table",
          { attrs: { data: _vm.logs } },
          [
            _c("el-table-column", {
              attrs: {
                label: "订单状态",
                align: "center",
                prop: "showStatusMsg"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "操作时间", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm._f("formatTime")(scope.row.created)) +
                          "\n      "
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("span", { staticClass: "title" }, [_vm._v("备注")]),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.getOrderNote }
          },
          [_vm._v("点击获取备注")]
        ),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.noteDetail || "暂无"))])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }