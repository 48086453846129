"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _airport = require("@/api/business/airport");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      orderNo: null,
      orderDetail: {
        airportOrderAmount: {},
        orderDetails: {},
        passengerInfos: [],
        priceDetails: [],
        scheduleDetails: {},
        statusRecords: []
      },
      invoiceRecord: []
    };
  },
  mounted: function mounted() {
    this.orderNo = this.$route.query.orderNo;
    this.getDetail();
  },
  methods: {
    /** 获取订单详情 */
    getDetail: function getDetail() {
      var _this = this;

      (0, _airport.airOrderDetail)({
        orderNo: this.orderNo
      }).then(function (res) {
        if (res.code == 200) {
          _this.orderDetail = res.data;

          _this.getInvoiceRecord();
        }
      });
    },

    /** 获取发票日志 */
    getInvoiceRecord: function getInvoiceRecord() {
      var _this2 = this;

      (0, _airport.airInvoiceRecord)(this.orderDetail.orderDetails.invoiceOrderNo).then(function (res) {
        if (res.code == 200) {
          _this2.invoiceRecord = res.data;
        }
      });
    }
  }
};
exports.default = _default;