"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.regexp.split");

var _scheduleItem = _interopRequireDefault(require("./components/scheduleItem.vue"));

var _utils = require("@/utils");

var _carpool = require("@/api/business/carpool");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    openScheduleOrderDialog: {
      type: Boolean,
      default: false
    },
    operationData: {
      type: Object,
      required: true
    }
  },
  components: {
    scheduleItem: _scheduleItem.default
  },
  created: function created() {
    this.weekFn(0);
    var forDate = new Date();
    var year = forDate.getFullYear();
    var month = forDate.getMonth() + 1;
    var day = forDate.getDate();
    var times = Date.parse(new Date(year, month - 1, day, 0, 0, 0));
    this.activityTag = times;
    this.searchListDay = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
    this.getLists();
  },
  mounted: function mounted() {
    var _this = this;

    setTimeout(function () {
      _this.tagIndex = document.querySelector(".carpool-scheduling-tag-ac span").innerText.split(" ")[0].substr(0, 2);
    });
  },
  methods: {
    // 查询线路下车型模板配置
    getCarpoolScheduleCarList: function getCarpoolScheduleCarList() {
      var _this2 = this;

      (0, _carpool.selectCarpoolScheduleCarByLineId)({
        lineId: this.operationData.id
      }).then(function (res) {
        _this2.carList = res.rows;
      });
    },
    okScheduleTemplateFn: function okScheduleTemplateFn() {
      var _this3 = this;

      // 本周排班确定按钮
      var params = {
        days: "",
        lineId: this.operationData.id * 1,
        type: this.scheduleTemplateRadio,
        lastDays: null
      }; // 当天排班

      if (this.scheduleTemplateTitle.indexOf("周") === -1) {
        if (params.type === 1) {
          console.log("12");
          var lastWeekDayTimestamp = this.activityTag - 7 * 24 * 60 * 60 * 1000;
          var newDate = new Date(lastWeekDayTimestamp);
          var month = this.calcDateFn(newDate.getMonth() + 1);
          var day = this.calcDateFn(newDate.getDate());
          params.lastDays = "".concat(newDate.getFullYear(), "-").concat(month, "-").concat(day);
        }

        params.days = this.searchListDay;
      } else {
        var days = null;
        var lastDays = null;

        if (params.type === 1) {
          console.log("12345");
          lastDays = this.generateWeekFn(this.weeknum - 1).days;
          days = this.generateWeekFn(this.weeknum).days;
          params.lastDays = lastDays.join(",");
        } else {
          days = this.generateWeekFn(this.weeknum).days;
        }

        params.days = days.join(",");
      }

      console.log(params); // return;

      (0, _carpool.carpoolScheduleAddList)(params).then(function (res) {
        if (res.code === 200) {
          _this3.messageFn(res.msg);

          _this3.getLists();

          _this3.scheduleTemplateState = false;
        }

        setTimeout(function () {
          _this3.scheduleTemplateRadio = 0;
        }, 1000);
      });
    },
    getLists: function getLists() {
      var _this4 = this;

      (0, _carpool.carpoolScheduleLists)({
        lineId: this.operationData.id,
        scheduleDay: this.searchListDay
      }).then(function (res) {
        console.log(res, "=====");
        res.rows.map(function (item) {
          // if (isString(item)) {
          item.serviceType = item.serviceType.split(","); // 不限

          if (item.scheduleType == 0) {
            item.totalSeat = 0;
            item.totalSeatCjpc = 0;
            item.totalSeatCjbc = 0;
          } else if (item.scheduleType == 1) {
            //区分类型
            item.totalSeat = 0;
          } else if (item.scheduleType == 2) {
            // 不区分类型
            item.totalSeatCjpc = 0;
            item.totalSeatCjbc = 0;
          } // }

        });
        _this4.formDialog.dataLists = res.rows;
        _this4.rawScheduleListsForChangeDay = (0, _utils.deepClone)(res.rows);
      });
    },
    handleFn: function handleFn(val, index) {
      console.log("handleFn");
    },
    followFn: function followFn(val, index) {
      console.log("followFn");
    },
    weekScheduleTemplateFn: function weekScheduleTemplateFn() {
      this.scheduleTemplateState = true;
      this.scheduleTemplateTitle = "本周排班设置";
    },
    todayScheduleTemplateFn: function todayScheduleTemplateFn() {
      this.scheduleTemplateState = true;
      this.scheduleTemplateTitle = "今日排班设置";
    },
    activityTagFn: function activityTagFn(val, index) {
      var _this5 = this;

      // 切换tag
      this.deleteCarpoolScheduleList = [];
      console.log(this.formDialog.dataLists.length, this.rawScheduleListsForChangeDay.length);

      if (this.formDialog.dataLists.length !== this.rawScheduleListsForChangeDay.length) {
        this.$confirm("当前设置未保存，切换标签将不会保存当前页面设置", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _this5.changeTagAction(val);
        }).catch(function () {});
      } else {
        this.changeTagAction(val);
      }
    },
    changeTagAction: function changeTagAction(val) {
      var _this6 = this;

      this.searchListDay = val.year + "-" + val.month + "-" + val.day;
      console.log(this.searchListDay, "searchListDay");
      this.activityTag = val.timestamp;
      setTimeout(function () {
        _this6.tagIndex = document.querySelector(".carpool-scheduling-tag-ac span").innerText.split(" ")[0].substr(0, 2);
      });
      this.getLists();
    },
    lastWeekFn: function lastWeekFn() {
      var _this7 = this;

      // 点击上一周箭头
      this.weeknum--;
      this.weekFn(this.weeknum);

      if (this.weeknum === 0) {
        var forDate = new Date();
        var year = forDate.getFullYear();
        var month = forDate.getMonth() + 1;
        var day = forDate.getDate();
        var times = Date.parse(new Date(year, month - 1, day, 0, 0, 0));
        this.activityTag = times;
        this.searchListDay = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
      } else {
        this.activityTag = this.week[0].timestamp;
        this.searchListDay = this.week[0].year + "-" + this.week[0].month + "-" + this.week[0].day;
      }

      this.getLists();
      setTimeout(function () {
        _this7.tagIndex = document.querySelector(".carpool-scheduling-tag-ac span").innerText.split(" ")[0].substr(0, 2);
      });
    },
    nextWeekFn: function nextWeekFn() {
      var _this8 = this;

      // 点击下一周箭头
      this.weeknum++;
      this.weekFn(this.weeknum);

      if (this.weeknum === 0) {
        var forDate = new Date();
        var year = forDate.getFullYear();
        var month = forDate.getMonth() + 1;
        var day = forDate.getDate();
        var times = Date.parse(new Date(year, month - 1, day, 0, 0, 0));
        this.activityTag = times;
        this.searchListDay = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
      } else {
        this.activityTag = this.week[0].timestamp;
        this.searchListDay = this.week[0].year + "-" + this.week[0].month + "-" + this.week[0].day;
      }

      this.getLists();
      setTimeout(function () {
        _this8.tagIndex = document.querySelector(".carpool-scheduling-tag-ac span").innerText.split(" ")[0].substr(0, 2);
      });
    },
    currentWeekFn: function currentWeekFn() {
      // 当前周
      this.weeknum = 0;
      this.weekFn(this.weeknum);

      if (this.weeknum === 0) {
        var forDate = new Date();
        var year = forDate.getFullYear();
        var month = forDate.getMonth() + 1;
        var day = forDate.getDate();
        var times = Date.parse(new Date(year, month - 1, day, 0, 0, 0));
        this.activityTag = times;
        this.searchListDay = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
      } else {
        this.activityTag = this.week[0].timestamp;
        this.searchListDay = this.week[0].year + "-" + this.week[0].month + "-" + this.week[0].day;
      }

      this.getLists();
    },
    generateWeekFn: function generateWeekFn(num) {
      var days = [];
      var dateArr = [];
      var weekArr = [];
      var timestamp = Date.parse(new Date()) + num * 7 * 24 * 60 * 60 * 1000;
      var newDate = new Date(timestamp);
      var weekDay = newDate.getDay();
      var arrMonth = [];
      var arrYear = [];

      for (var i = 0; i < 7; i++) {
        var forDate = new Date(timestamp + 24 * 60 * 60 * 1000 * (i - (weekDay + 6) % 7));
        var year = forDate.getFullYear();
        var month = forDate.getMonth() + 1;
        var day = forDate.getDate();
        var times = Date.parse(new Date(year, month - 1, day, 0, 0, 0));
        var obj = {
          text: "周" + "日一二三四五六".charAt(forDate.getDay()),
          year: year,
          day: day > 9 ? day : "0" + day,
          month: month > 9 ? month : "0" + month,
          timestamp: times // 当天0时0分0秒的时间戳

        };
        weekArr.push(obj);
        var date = year + "-" + this.supplementZero(month) + "-" + this.supplementZero(day);
        var opt = {
          date: date,
          timestamp: times // 当天0时0分0秒的时间戳

        };
        dateArr.push(opt);
        var monthDate = new Date(timestamp + 24 * 60 * 60 * 1000 * (i - (weekDay + 6) % 7)).getMonth() + 1 + "月";

        if (arrMonth.indexOf(monthDate) === -1) {
          arrMonth.push(monthDate);
        }

        var yearDate = new Date(timestamp + 24 * 60 * 60 * 1000 * (i - (weekDay + 6) % 7)).getFullYear() + "年";

        if (arrYear.indexOf(yearDate) === -1) {
          arrYear.push(yearDate);
        }
      }

      dateArr.forEach(function (item) {
        return days.push(item.date);
      });
      return {
        weekArr: weekArr,
        dateArr: dateArr,
        arrYear: arrYear,
        arrMonth: arrMonth,
        days: days
      };
    },
    weekFn: function weekFn(num) {
      // 当前显示的周数据
      var _this$generateWeekFn = this.generateWeekFn(num),
          weekArr = _this$generateWeekFn.weekArr,
          dateArr = _this$generateWeekFn.dateArr,
          arrYear = _this$generateWeekFn.arrYear,
          arrMonth = _this$generateWeekFn.arrMonth;

      this.week = weekArr;

      if (arrYear.length === 1 && arrMonth.length === 1) {
        this.yearMonth = arrMonth[0] + this.week[0].day + "日-" + arrMonth[0] + this.week[6].day + "日";
      } else if (arrYear.length === 1) {
        this.yearMonth = arrMonth[0] + this.week[0].day + "日-" + arrMonth[1] + this.week[6].day + "日";
      } else if (arrYear.length === 2) {
        this.yearMonth = arrMonth[0] + this.week[0].day + "日-" + arrMonth[arrMonth.length - 1] + this.week[6].day + "日";
      }
    },
    // 批量起售停售
    batchFreezeFn: function batchFreezeFn(type) {
      console.log(this.scheduleList); // var isEmpty = false;
      // this.dataListSelections.map(item => {
      //   if(item.realName||item.carNo){
      //     isEmpty = true;
      //   }
      // })
      // var ids = this.dataListSelections.map(item => {
      //   return item.id
      // })
      // let params = {
      //   ids:ids.join(','),
      //   type,
      // }
      // this.$confirm(`确定对选中的数据进行[批量${type}]操作?`, '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   batchFreeze(params).then(data=>{
      //     if (data && data) {
      //       this.messageFn(`批量${type}成功!`)
      //     }
      //   })
      // }).catch(() => {
      // });
    },
    // 批量编辑
    batchUpdate: function batchUpdate() {
      var state = this.scheduleList.some(function (item) {
        return item.isEditAble;
      });

      if (!state) {
        return this.messageFn("暂无可编辑的班次", "error");
      }
    },
    handleDialogClose: function handleDialogClose() {
      var _this9 = this;

      this.$confirm("点击取消不会保存当前设置，请谨慎操作!!!", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this9.$emit("closepop"); //通知父组件改变。

      }).catch(function () {});
    },
    removeItem: function removeItem(item, index) {
      if (item.id) {
        this.deleteCarpoolScheduleList.push(item);
      }

      this.formDialog.dataLists.splice(index, 1);
    },
    addDomain: function addDomain() {
      this.formDialog.dataLists.push({
        startTime: "",
        endTime: "",
        lineId: this.operationData.id,
        lineName: this.operationData.lineName,
        scheduleType: 0,
        scheduleDay: this.searchListDay,
        serviceType: [],
        status: 1,
        carConfigList: [] // 班次的车型配置

      });
    },
    handleSubmit: function handleSubmit() {
      var _this10 = this;

      console.log(this.formDialog);
      this.$refs["formDialog"].validate(function (valid) {
        if (valid) {
          _this10.loading = true;

          _this10.formDialog.dataLists.map(function (item) {
            if (item.serviceType.includes("城际拼车") && item.serviceType.includes("城际包车")) {
              item.serviceType = "城际拼车,城际包车";
              item.serviceTypeCode = 2;
            } else if (item.serviceType.includes("城际拼车")) {
              item.serviceType = "城际拼车";
              item.serviceTypeCode = 0;
            } else if (item.serviceType.includes("城际包车")) {
              item.serviceType = "城际包车";
              item.serviceTypeCode = 1;
            } // 保存车型需要的参数


            item.scheduleCarIds = item.carConfigList.map(function (item, index) {
              return item.scheduleCarId;
            }).join(",");
          });

          var params = {
            carpoolScheduleList: _this10.formDialog.dataLists
          };

          if (_this10.deleteCarpoolScheduleList.length > 0) {
            _this10.deleteCarpoolScheduleList.map(function (item) {
              if (item.serviceType.includes("城际拼车") && item.serviceType.includes("城际包车")) {
                item.serviceType = "城际拼车,城际包车";
                item.serviceTypeCode = 2;
              } else if (item.serviceType.includes("城际拼车")) {
                item.serviceType = "城际拼车";
                item.serviceTypeCode = 0;
              } else if (item.serviceType.includes("城际包车")) {
                item.serviceType = "城际包车";
                item.serviceTypeCode = 1;
              }
            });

            params["deleteCarpoolScheduleList"] = _this10.deleteCarpoolScheduleList;
          }

          (0, _carpool.addCarpoolScheduleS)(params).then(function (res) {
            if (res.code === 200) {
              _this10.$message.success(res.msg);
            } else {
              _this10.$message.error(res.msg);
            }

            _this10.loading = false;

            _this10.$emit("closepop");
          }).catch(function () {
            _this10.formDialog.dataLists.map(function (item) {
              item.serviceType = item.serviceType.split(",");
            });

            if (_this10.deleteCarpoolScheduleList.length > 0) {
              _this10.deleteCarpoolScheduleList.map(function (item) {
                item.serviceType = item.serviceType.split(",");
              });
            }
          });
        }
      });
    },
    reload: function reload(obj) {
      console.log(obj, "obj");
      this.formDialog = obj;
    },
    // 打开车型配置弹框
    openScheduleCarDialog: function openScheduleCarDialog(index) {
      this.scheduleIndex = index;
      this.scheduleID = this.formDialog.dataLists[index].id;
      this.scheduleCarConfigShow = true;
    },
    // 车型选择
    handleSelectionChange: function handleSelectionChange(selection) {
      console.log(selection);
      this.templateIds = selection.map(function (item, index) {
        return item.id;
      }).join(",");
      console.log(this.templateIds);
    },
    // 通过车辆价格模板新增班次车辆价格配置数据
    confirmScheduleCar: function confirmScheduleCar() {
      var _this11 = this;

      (0, _carpool.insertScheduleCarByTemplateIds)({
        templateIds: this.templateIds
      }).then(function (res) {
        if (res.code == 200) {
          var data = res.data;

          _this11.closeScheduleCarDialog();
        }
      });
    },
    // 关闭车型配置弹框
    closeScheduleCarDialog: function closeScheduleCarDialog() {
      this.scheduleCarConfigShow = false;
    },
    // 删除车型配置
    deleteCarType: function deleteCarType(index, tag) {
      var _this12 = this;

      this.formDialog.dataLists[index].carConfigList.splice(this.formDialog.dataLists[index].carConfigList.indexOf(tag), 1); // 保存班次和打开配置时需要的参数

      this.scheduleCarIdList = [];
      this.formDialog.dataLists[index].carConfigList.forEach(function (item) {
        _this12.scheduleCarIdList.push(item.scheduleCarId);
      }); // 请求接口用的参数

      this.formDialog.dataLists[index].scheduleCarIds = this.scheduleCarIdList.toString();
    }
  },
  data: function data() {
    return {
      scheduleID: -1,
      // 当前点击的班次ID
      scheduleCarIdList: [],
      // 已经配置过得班次车型id
      scheduleIndex: -1,
      // 当前点击的班次下标
      scheduleCarConfigShow: false,
      // 班次车型配置弹框
      scheduleTemplateState: false,
      // 本周排班弹窗
      scheduleTemplateRadio: 0,
      scheduleTemplateTitle: "",
      carpoolScheduleList: [],
      rawScheduleListsForChangeDay: [],
      deleteCarpoolScheduleList: [],
      tagIndex: "",
      todayYear: "",
      // 使用当天的年份
      todayMonth: "",
      // 使用当天的月份
      todayDay: "",
      // 使用当天的日期
      todaytimestamp: "",
      // 使用当天的0时0分0秒的时间戳
      yearMonth: "",
      // 当前年份月份
      weeknum: 0,
      // 当前第几周 0 为今天的当前周,负值为lastWeek，正值为nextWeek
      activityTag: "",
      // 当前选中的日期
      searchListDay: "",
      // 获取排班列表所需的参数 day
      week: [],
      // 当周日历天数数组
      days: [],
      // 本周排班需要的days字段
      date: [],
      // 当周日历天数对应的完整日期
      time: [],
      // 日历偏好时间点
      scheduleList: [],
      // 获得的班次数据列表
      dataListSelections: [],
      formDialog: {
        dataLists: [{
          startTime: "",
          endTime: "",
          lineId: this.operationData.id,
          lineName: this.operationData.lineName,
          scheduleType: 0,
          scheduleDay: this.searchListDay,
          serviceType: [],
          status: 1,
          carConfigList: [] // 班次的车型配置

        }]
      },
      carList: [],
      templateIds: ''
    };
  }
};
exports.default = _default;