import request from '@/utils/request'; // 查询乘客复购分析

export function moreCount(data) {
  return request({
    url: '/system/main/moreCount',
    method: 'get',
    params: data
  });
} // 查询线路站点排行榜

export function stationLevel(data) {
  return request({
    url: '/system/main/stationLevel',
    method: 'get',
    params: data
  });
} // 查询分时段购票数量

export function timeLevelCount(data) {
  return request({
    url: '/system/main/timeLevelCount',
    method: 'get',
    params: data
  });
} // 查询各班次售票分布分析

export function scheduleByDayTimeCount(data) {
  return request({
    url: '/system/main/scheduleByDayTimeCount',
    method: 'get',
    params: data
  });
} // 查询订单趋势

export function busTicketOrderCount(data) {
  return request({
    url: '/system/main/busTicketOrderCount',
    method: 'get',
    params: data
  });
} // 发单热力大盘

export function busTicketLongLatitude(data) {
  return request({
    url: '/system/main/busTicketLongLatitude',
    method: 'get',
    params: data
  });
} // 用户行为漏斗

export function passengerBehaviorCount(data) {
  return request({
    url: '/system/main/passengerBehaviorCount',
    method: 'get',
    params: data
  });
} // 乘客复购分析详情

export function busTicketListCount(data) {
  return request({
    url: '/system/main/busTicketListCount',
    method: 'get',
    params: data
  });
} // 查询各班次售票分布分析导出报表

export function scheduleByDayTimeCountExcel(data) {
  return request({
    url: '/system/main/scheduleByDayTimeCountExcel',
    method: 'get',
    params: data
  });
} // 查询分时段购票数量导出报表

export function timeLevelCountExcel(data) {
  return request({
    url: '/system/main/timeLevelCountExcel',
    method: 'get',
    params: data
  });
} // 经营汇总-交易概况-交易总览Tab

export function tradeTab(data) {
  return request({
    url: '/operations/summary/tradeTab',
    method: 'post',
    data: data
  });
} // 经营汇总-交易概况-交易总览-交易额趋势

export function tradingTrends(data) {
  return request({
    url: '/operations/summary/tradingTrends',
    method: 'post',
    data: data
  });
} // 经营汇总-交易概况-上座率

export function ticketingRatio(data) {
  return request({
    url: '/operations/summary/ticketingRatio',
    method: 'post',
    data: data
  });
} // 经营汇总-复购情况-用户复购情况饼状图

export function ticketRepurchase(data) {
  return request({
    url: '/operations/summary/ticketRepurchase',
    method: 'post',
    data: data
  });
} // 经营汇总-复购情况-用户复购情况数据下载 导出excel

export function ticketRepurchaseExcel(data) {
  return request({
    url: '/operations/summary/ticketRepurchaseExcel',
    method: 'post',
    data: data
  });
} // 经营汇总-复购情况-交易时段分布

export function tradingDuration(data) {
  return request({
    url: '/operations/summary/tradingDuration',
    method: 'post',
    data: data
  });
} // 经营汇总-用户出行总览-出行班次分布

export function scheduleDuration(data) {
  return request({
    url: '/operations/summary/scheduleDuration',
    method: 'post',
    data: data
  });
} // 经营汇总-用户总览-新老占比

export function countAccount(data) {
  return request({
    url: '/operations/summary/countAccount',
    method: 'post',
    data: data
  });
} // 经营汇总-用户总览-下单渠道

export function ticketChannels(data) {
  return request({
    url: '/operations/summary/ticketChannels',
    method: 'post',
    data: data
  });
} // 经营汇总-用户总览-预购情况

export function preTicket(data) {
  return request({
    url: '/operations/summary/preTicket',
    method: 'post',
    data: data
  });
} // 经营汇总-用户总览-行为漏斗

export function userBehavior(data) {
  return request({
    url: '/operations/summary/userBehavior',
    method: 'post',
    data: data
  });
}