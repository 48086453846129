"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.vehicleModelAddOrUpdate = vehicleModelAddOrUpdate;
exports.vehicleModelList = vehicleModelList;
exports.settlementRuleList = settlementRuleList;
exports.settlementRuleAdd = settlementRuleAdd;
exports.settlementRuleDelete = settlementRuleDelete;
exports.settlementRuleDetailsAdd = settlementRuleDetailsAdd;
exports.settlementRuleDetails = settlementRuleDetails;
exports.settlementRuleView = settlementRuleView;
exports.settlementRuleDropList = settlementRuleDropList;
exports.transportationCompanyManageList = transportationCompanyManageList;
exports.transportationCompanyManageAddOrUpdate = transportationCompanyManageAddOrUpdate;
exports.lineQueryLinesByCompanyId = lineQueryLinesByCompanyId;
exports.lineList = lineList;
exports.lineAddOrUpdate = lineAddOrUpdate;
exports.lineDelete = lineDelete;
exports.lineDetails = lineDetails;
exports.cityconfigUpList = cityconfigUpList;
exports.cityconfigDownList = cityconfigDownList;
exports.lineConfigStatusList = lineConfigStatusList;
exports.transportationCompanyManageServiceList = transportationCompanyManageServiceList;
exports.lineVehicleModelConfigInfo = lineVehicleModelConfigInfo;
exports.lineVehicleModelConfigSave = lineVehicleModelConfigSave;
exports.lineVehicleModelConfigService = lineVehicleModelConfigService;
exports.lineOperateScopeConfigStatusList = lineOperateScopeConfigStatusList;
exports.lineOperateScopeConfigList = lineOperateScopeConfigList;
exports.lineOperateScopeConfigAddOrUpdate = lineOperateScopeConfigAddOrUpdate;
exports.lineOperateScopeConfigDelete = lineOperateScopeConfigDelete;
exports.linePriceConfigStatusList = linePriceConfigStatusList;
exports.linePriceConfigDetails = linePriceConfigDetails;
exports.linePriceConfigSave = linePriceConfigSave;
exports.lineScheduleTemplateConfigList = lineScheduleTemplateConfigList;
exports.lineScheduleTemplateConfigSave = lineScheduleTemplateConfigSave;
exports.lineCancelRuleConfigInfo = lineCancelRuleConfigInfo;
exports.lineCancelRuleConfigAddOrUpdate = lineCancelRuleConfigAddOrUpdate;
exports.lineCommonConfigInfo = lineCommonConfigInfo;
exports.lineCommonConfigAddOrUpdate = lineCommonConfigAddOrUpdate;
exports.lineConfigStatusCheck = lineConfigStatusCheck;
exports.lineScheduleList = lineScheduleList;
exports.lineScheduleAddOrUpdate = lineScheduleAddOrUpdate;
exports.lineScheduleShortcut = lineScheduleShortcut;
exports.driverList = driverList;
exports.driverQueryAllDriver = driverQueryAllDriver;
exports.driverAdd = driverAdd;
exports.driverUpdate = driverUpdate;
exports.driverDelete = driverDelete;
exports.lineDriverServiceLines = lineDriverServiceLines;
exports.carQueryCarUnbindDriver = carQueryCarUnbindDriver;
exports.driverQueryById = driverQueryById;
exports.carBindDriver = carBindDriver;
exports.carUnBindDriver = carUnBindDriver;
exports.driverDetailsOrderList = driverDetailsOrderList;
exports.driverQueryDriverDetails = driverQueryDriverDetails;
exports.driverFreezeDriverAccount = driverFreezeDriverAccount;
exports.carList = carList;
exports.carQueryAllCar = carQueryAllCar;
exports.carAdd = carAdd;
exports.carUpdate = carUpdate;
exports.carQueryDriverUnbindCar = carQueryDriverUnbindCar;
exports.carQueryById = carQueryById;
exports.carDelete = carDelete;
exports.cityConfigList = cityConfigList;
exports.cityConfigAdd = cityConfigAdd;
exports.cityConfigDelete = cityConfigDelete;
exports.cityConfigUpdateHotCity = cityConfigUpdateHotCity;
exports.orderList = orderList;
exports.orderExport = orderExport;
exports.orderSelectByOrderNo = orderSelectByOrderNo;
exports.orderTrajectory = orderTrajectory;
exports.queryRecodeByOrderNo = queryRecodeByOrderNo;
exports.orderCancel = orderCancel;
exports.orderRefundAmount = orderRefundAmount;
exports.orderRefund = orderRefund;
exports.travelQuerySelective = travelQuerySelective;
exports.travelSelectOrderByTravelId = travelSelectOrderByTravelId;
exports.travelQueryById = travelQueryById;
exports.travelTrajectory = travelTrajectory;
exports.refundRecordList = refundRecordList;
exports.refundRecordExport = refundRecordExport;
exports.orderOrderNumOfLine = orderOrderNumOfLine;
exports.orderOrderScheduling = orderOrderScheduling;
exports.orderCountOrderScheduling = orderCountOrderScheduling;
exports.orderQueryOrderSchedulingById = orderQueryOrderSchedulingById;
exports.orderAssignableDriver = orderAssignableDriver;
exports.orderCrossEnableTravels = orderCrossEnableTravels;
exports.orderDispatch = orderDispatch;
exports.orderCountScheduleOrderList = orderCountScheduleOrderList;
exports.orderMapDispatchList = orderMapDispatchList;
exports.driverBillFundList = driverBillFundList;
exports.driverBillFundSum = driverBillFundSum;
exports.driverBillFundListExport = driverBillFundListExport;
exports.driverBillList = driverBillList;
exports.driverBillSum = driverBillSum;
exports.driverBillListExport = driverBillListExport;
exports.driverBillDetailList = driverBillDetailList;
exports.driverBillDetailListExport = driverBillDetailListExport;
exports.lineVehicleModelConfig = lineVehicleModelConfig;
exports.lineSchenduleEffectiveList = lineSchenduleEffectiveList;
exports.lineScheduleAmount = lineScheduleAmount;
exports.orderCreate = orderCreate;

var _request = _interopRequireDefault(require("@/utils/request"));

// 新版拼车接口
// 新增拼车车型
function vehicleModelAddOrUpdate(data) {
  return (0, _request.default)({
    url: "/carpool/new/vehicle/model/addOrUpdate",
    method: "post",
    data: data
  });
} // 拼车车型列表


function vehicleModelList(params) {
  return (0, _request.default)({
    url: "/carpool/new/vehicle/model/list",
    method: "get",
    params: params
  });
} // 拼车结算规则列表


function settlementRuleList(params) {
  return (0, _request.default)({
    url: "/carpool/new/settlement/rule/list",
    method: "get",
    params: params
  });
} // 新增拼车结算规则


function settlementRuleAdd(params) {
  return (0, _request.default)({
    url: "/carpool/new/settlement/rule/add",
    method: "get",
    params: params
  });
} // 删除拼车结算规则


function settlementRuleDelete(params) {
  return (0, _request.default)({
    url: "/carpool/new/settlement/rule/delete",
    method: "get",
    params: params
  });
} // 配置拼车结算规则


function settlementRuleDetailsAdd(data) {
  return (0, _request.default)({
    url: "/carpool/new/settlement/rule/details/add",
    method: "post",
    data: data
  });
} // 查询拼车结算规则详情


function settlementRuleDetails(params) {
  return (0, _request.default)({
    url: "/carpool/new/settlement/rule/details",
    method: "get",
    params: params
  });
} // 查询拼车结算规则关联公司


function settlementRuleView(params) {
  return (0, _request.default)({
    url: "/carpool/new/settlement/rule/view",
    method: "get",
    params: params
  });
} // 查询拼车所有规则


function settlementRuleDropList(params) {
  return (0, _request.default)({
    url: "/carpool/new/settlement/rule/drop/list",
    method: "get",
    params: params
  });
} // 查询拼车运力公司列表


function transportationCompanyManageList(params) {
  return (0, _request.default)({
    url: "/carpool/new/transportation/company/manage/list",
    method: "get",
    params: params
  });
} // 新增拼车运力公司


function transportationCompanyManageAddOrUpdate(data) {
  return (0, _request.default)({
    url: "/carpool/new/transportation/company/manage/addOrUpdate",
    method: "post",
    data: data
  });
} // 通过公司id查询对应的服务线路，如果没有传公司id就查当前账号下所属公司下所有的服务线路


function lineQueryLinesByCompanyId(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/queryLinesByCompanyId",
    method: "get",
    params: params
  });
} // 查询拼车线路列表


function lineList(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/list",
    method: "get",
    params: params
  });
} // 新增/修改拼车线路


function lineAddOrUpdate(data) {
  return (0, _request.default)({
    url: "/carpool/new/line/addOrUpdate",
    method: "post",
    data: data
  });
} // 删除拼车线路


function lineDelete(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/delete",
    method: "get",
    params: params
  });
} // 查询拼车线路详情


function lineDetails(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/details",
    method: "get",
    params: params
  });
} // 查询拼车上车城市


function cityconfigUpList(params) {
  return (0, _request.default)({
    url: "/carpool/new/passenger/city/config/up/list",
    method: "get",
    params: params
  });
} // 查询拼车下车城市


function cityconfigDownList(params) {
  return (0, _request.default)({
    url: "/carpool/new/passenger/city/config/down/list",
    method: "get",
    params: params
  });
} // 查询拼车线路配置项


function lineConfigStatusList(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/config/status/list",
    method: "get",
    params: params
  });
} // 公司授权服务列表


function transportationCompanyManageServiceList(params) {
  return (0, _request.default)({
    url: "/carpool/new/transportation/company/manage/service/list",
    method: "get",
    params: params
  });
} // 获取线路服务配置信息


function lineVehicleModelConfigInfo(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/vehicle/model/config/info",
    method: "get",
    params: params
  });
} // 线路服务配置保存


function lineVehicleModelConfigSave(data) {
  return (0, _request.default)({
    url: "/carpool/new/line/vehicle/model/config/save",
    method: "post",
    data: data
  });
} // 线路服务配置列表


function lineVehicleModelConfigService(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/vehicle/model/config/service",
    method: "get",
    params: params
  });
} // 运营配置状态列表


function lineOperateScopeConfigStatusList(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/operate/scope/config/status/list",
    method: "get",
    params: params
  });
} // 运营配置站点列表


function lineOperateScopeConfigList(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/operate/scope/config/list",
    method: "get",
    params: params
  });
} // 运营配置站点保存


function lineOperateScopeConfigAddOrUpdate(data) {
  return (0, _request.default)({
    url: "/carpool/new/line/operate/scope/config/addOrUpdate",
    method: "post",
    data: data
  });
} // 运营配置站点删除


function lineOperateScopeConfigDelete(data) {
  return (0, _request.default)({
    url: "/carpool/new/line/operate/scope/config/delete",
    method: "post",
    data: data
  });
} // 价格配置状态列表


function linePriceConfigStatusList(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/price/config/status/list",
    method: "get",
    params: params
  });
} // 价格配置详情


function linePriceConfigDetails(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/price/config/details",
    method: "get",
    params: params
  });
} // 价格配置保存


function linePriceConfigSave(data) {
  return (0, _request.default)({
    url: "/carpool/new/line/price/config/save",
    method: "post",
    data: data
  });
} // 班次模板列表


function lineScheduleTemplateConfigList(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/schedule/template/config/list",
    method: "get",
    params: params
  });
} // 班次模板保存


function lineScheduleTemplateConfigSave(data) {
  return (0, _request.default)({
    url: "/carpool/new/line/schedule/template/config/save",
    method: "post",
    data: data
  });
} // 取消配置信息


function lineCancelRuleConfigInfo(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/cancel/rule/config/info",
    method: "get",
    params: params
  });
} // 取消配置保存


function lineCancelRuleConfigAddOrUpdate(data) {
  return (0, _request.default)({
    url: "/carpool/new/line/cancel/rule/config/addOrUpdate",
    method: "post",
    data: data
  });
} // 通用配置信息


function lineCommonConfigInfo(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/common/config/info",
    method: "get",
    params: params
  });
} // 通用配置信息保存


function lineCommonConfigAddOrUpdate(data) {
  return (0, _request.default)({
    url: "/carpool/new/line/common/config/addOrUpdate",
    method: "post",
    data: data
  });
} // 校验线路是否可排班


function lineConfigStatusCheck(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/config/status/check",
    method: "get",
    params: params
  });
} // 班次列表


function lineScheduleList(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/schedule/list",
    method: "get",
    params: params
  });
} // 保存班次


function lineScheduleAddOrUpdate(data) {
  return (0, _request.default)({
    url: "/carpool/new/line/schedule/addOrUpdate",
    method: "post",
    data: data
  });
} // 快捷排班


function lineScheduleShortcut(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/schedule/shortcut",
    method: "get",
    params: params
  });
} // 获取司机列表


function driverList(params) {
  return (0, _request.default)({
    url: "/carpool/new/driver/list",
    method: "get",
    params: params
  });
} // 获取不分页司机列表


function driverQueryAllDriver(params) {
  return (0, _request.default)({
    url: "/carpool/new/driver/queryAllDriver",
    method: "get",
    params: params
  });
} // 新增司机


function driverAdd(data) {
  return (0, _request.default)({
    url: "/carpool/new/driver/add",
    method: "post",
    data: data
  });
} // 修改司机


function driverUpdate(data) {
  return (0, _request.default)({
    url: "/carpool/new/driver/update",
    method: "post",
    data: data
  });
} // 删除司机


function driverDelete(params) {
  return (0, _request.default)({
    url: "/carpool/new/driver/delete",
    method: "delete",
    params: params
  });
} // 获取公司司机可服务线路


function lineDriverServiceLines(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/driverServiceLines",
    method: "get",
    params: params
  });
} // 获取未绑定司机的车辆


function carQueryCarUnbindDriver(params) {
  return (0, _request.default)({
    url: "/carpool/new/car/queryCarUnbindDriver",
    method: "get",
    params: params
  });
} // 通过司机id获取司机基本信息


function driverQueryById(params) {
  return (0, _request.default)({
    url: "/carpool/new/driver/queryById",
    method: "get",
    params: params
  });
} // 司机车辆绑定


function carBindDriver(params) {
  return (0, _request.default)({
    url: "/carpool/new/car/carBindDriver",
    method: "get",
    params: params
  });
} // 司机车辆解绑


function carUnBindDriver(params) {
  return (0, _request.default)({
    url: "/carpool/new/car/carUnbindDriver",
    method: "get",
    params: params
  });
} // 司机详情获取订单列表


function driverDetailsOrderList(params) {
  return (0, _request.default)({
    url: "/carpool/new/order/driver/details/order/list",
    method: "get",
    params: params
  });
} // 获取司机账户信息、基础信息、登录信息的接口


function driverQueryDriverDetails(params) {
  return (0, _request.default)({
    url: "/carpool/new/driver/queryDriverDetails",
    method: "get",
    params: params
  });
} // 冻结、解冻司机账户


function driverFreezeDriverAccount(params) {
  return (0, _request.default)({
    url: "/carpool/new/driver/freezeDriverAccount",
    method: "get",
    params: params
  });
} // 获取车辆列表


function carList(params) {
  return (0, _request.default)({
    url: "/carpool/new/car/list",
    method: "get",
    params: params
  });
} // 获取不分页车辆列表


function carQueryAllCar(params) {
  return (0, _request.default)({
    url: "/carpool/new/car/queryAllCar",
    method: "get",
    params: params
  });
} // 新增车辆


function carAdd(data) {
  return (0, _request.default)({
    url: "/carpool/new/car/add",
    method: "post",
    data: data
  });
} // 修改车辆


function carUpdate(data) {
  return (0, _request.default)({
    url: "/carpool/new/car/update",
    method: "post",
    data: data
  });
} // 获取没有绑定车辆的司机


function carQueryDriverUnbindCar(params) {
  return (0, _request.default)({
    url: "/carpool/new/driver/queryDriverUnbindCar",
    method: "get",
    params: params
  });
} // 通过车辆id查询车辆详情信息


function carQueryById(params) {
  return (0, _request.default)({
    url: "/carpool/new/car/queryById",
    method: "get",
    params: params
  });
} // 通过车辆id查询车辆详情信息


function carDelete(params) {
  return (0, _request.default)({
    url: "/carpool/new/car/delete",
    method: "delete",
    params: params
  });
} // 乘客城市配置-城市配置列表


function cityConfigList(params) {
  return (0, _request.default)({
    url: "/carpool/new/passenger/city/config/list",
    method: "get",
    params: params
  });
} // 乘客城市配置-添加城市配置


function cityConfigAdd(data) {
  return (0, _request.default)({
    url: "/carpool/new/passenger/city/config/add",
    method: "post",
    data: data
  });
} // 乘客城市配置-删除城市配置


function cityConfigDelete(params) {
  return (0, _request.default)({
    url: "/carpool/new/passenger/city/config/delete",
    method: "delete",
    params: params
  });
} // 乘客城市配置-修改热门城市


function cityConfigUpdateHotCity(params) {
  return (0, _request.default)({
    url: "/carpool/new/passenger/city/config/updateHotCity",
    method: "get",
    params: params
  });
} // 订单列表 - 查询、获取订单列表接口


function orderList(data) {
  return (0, _request.default)({
    url: "/carpool/new/order/list",
    method: "post",
    data: data
  });
} // 订单列表-导出


function orderExport(data) {
  return (0, _request.default)({
    url: "/carpool/new/order/export",
    method: "post",
    data: data
  });
} // 订单详情


function orderSelectByOrderNo(params) {
  return (0, _request.default)({
    url: "/carpool/new/order/selectByOrderNo",
    method: "get",
    params: params
  });
} // 订单详情-订单线路


function orderTrajectory(params) {
  return (0, _request.default)({
    url: "/carpool/new/order/orderTrajectory",
    method: "get",
    params: params
  });
} // 订单详情-获取订单通话录音


function queryRecodeByOrderNo(params) {
  return (0, _request.default)({
    url: "/carpool/new/order/queryRecodeByOrderNo",
    method: "get",
    params: params
  });
} // 取消订单


function orderCancel(params) {
  return (0, _request.default)({
    url: "/carpool/new/order/cancel",
    method: "get",
    params: params
  });
} // 订单退款计算


function orderRefundAmount(params) {
  return (0, _request.default)({
    url: "/carpool/new/order/refund/amount",
    method: "get",
    params: params
  });
} // 订单退款


function orderRefund(data) {
  return (0, _request.default)({
    url: "/carpool/new/order/refund",
    method: "post",
    data: data
  });
} // 行程列表-获取行程列表、筛选


function travelQuerySelective(data) {
  return (0, _request.default)({
    url: "/carpool/new/travel/querySelective",
    method: "post",
    data: data
  });
} // 行程列表-获取行程中所有的订单


function travelSelectOrderByTravelId(params) {
  return (0, _request.default)({
    url: "/carpool/new/travel/selectOrderByTravelId",
    method: "get",
    params: params
  });
} // 行程详情


function travelQueryById(params) {
  return (0, _request.default)({
    url: "/carpool/new/travel/queryById",
    method: "get",
    params: params
  });
} // 行程详情-行程线路


function travelTrajectory(params) {
  return (0, _request.default)({
    url: "/carpool/new/travel/travelTrajectory",
    method: "get",
    params: params
  });
} // 订单退款记录-列表、筛选


function refundRecordList(data) {
  return (0, _request.default)({
    url: "/carpool/new/refund/record/list",
    method: "post",
    data: data
  });
} // 订单退款记录-导出


function refundRecordExport(data) {
  return (0, _request.default)({
    url: "/carpool/new/refund/record/export",
    method: "post",
    data: data
  });
} // 订单调度-获取各线路的待派单数


function orderOrderNumOfLine(data) {
  return (0, _request.default)({
    url: "/carpool/new/order/orderNumOfLine",
    method: "post",
    data: data
  });
} // 订单调度-调度列表


function orderOrderScheduling(params) {
  return (0, _request.default)({
    url: "/carpool/new/order/orderSchedulingList",
    method: "get",
    params: params
  });
} // 订单调度-全部订单数量、未指派订单数量、已指派订单数量


function orderCountOrderScheduling(params) {
  return (0, _request.default)({
    url: "/carpool/new/order/countOrderScheduling",
    method: "get",
    params: params
  });
} // 订单调度-订单详情


function orderQueryOrderSchedulingById(params) {
  return (0, _request.default)({
    url: "/carpool/new/order/queryOrderSchedulingById",
    method: "get",
    params: params
  });
} // 订单调度-可指派司机列表


function orderAssignableDriver(params) {
  return (0, _request.default)({
    url: "/carpool/new/order/assignableDriver",
    method: "get",
    params: params
  });
} // 订单调度-查询是否可跨行程指派 并获取行程列表


function orderCrossEnableTravels(params) {
  return (0, _request.default)({
    url: "/carpool/new/order/crossEnableTravels",
    method: "get",
    params: params
  });
} // 订单调度-指派、改派、跨行程合并指派


function orderDispatch(params) {
  return (0, _request.default)({
    url: "/carpool/new/order/dispatch",
    method: "get",
    params: params
  });
} // 订单调度-实时售票查看 列表


function orderCountScheduleOrderList(params) {
  return (0, _request.default)({
    url: "/carpool/new/order/countScheduleOrderList",
    method: "get",
    params: params
  });
} // 订单调度-地图派单 地图订单列表


function orderMapDispatchList(params) {
  return (0, _request.default)({
    url: "/carpool/new/order/mapDispatchList",
    method: "get",
    params: params
  });
} // 司机资金列表


function driverBillFundList(params) {
  return (0, _request.default)({
    url: "/carpool/new/driver/bill/fund/list",
    method: "get",
    params: params
  });
} // 司机资金汇总


function driverBillFundSum(params) {
  return (0, _request.default)({
    url: "/carpool/new/driver/bill/fund/sum",
    method: "get",
    params: params
  });
} // 司机资金导出


function driverBillFundListExport(params) {
  return (0, _request.default)({
    url: "/carpool/new/driver/bill/fund/list/export",
    method: "get",
    params: params
  });
} // 司机收益列表


function driverBillList(data) {
  return (0, _request.default)({
    url: "/carpool/new/driver/bill/list",
    method: "post",
    data: data
  });
} // 司机收益汇总


function driverBillSum(data) {
  return (0, _request.default)({
    url: "/carpool/new/driver/bill/sum",
    method: "post",
    data: data
  });
} // 司机收益列表导出


function driverBillListExport(data) {
  return (0, _request.default)({
    url: "/carpool/new/driver/bill/list/export",
    method: "post",
    data: data
  });
} // 司机资金详情列表


function driverBillDetailList(params) {
  return (0, _request.default)({
    url: "/carpool/new/driver/bill/detail/list",
    method: "get",
    params: params
  });
} // 司机资金详情导出


function driverBillDetailListExport(params) {
  return (0, _request.default)({
    url: "/carpool/new/driver/bill/detail/list/export",
    method: "get",
    params: params
  });
} // 客服代下单-获取包车车型配置


function lineVehicleModelConfig(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/vehicle/model/config",
    method: "get",
    params: params
  });
} // 客服代下单-获取可售班次列表


function lineSchenduleEffectiveList(params) {
  return (0, _request.default)({
    url: "/carpool/new/line/schedule/effective/list",
    method: "get",
    params: params
  });
} // 客服代下单-订单金额


function lineScheduleAmount(data) {
  return (0, _request.default)({
    url: "/carpool/new/line/schedule/amount",
    method: "post",
    data: data
  });
} // 客服代下单-提交订单


function orderCreate(data) {
  return (0, _request.default)({
    url: "/carpool/new/order/create",
    method: "post",
    data: data
  });
}