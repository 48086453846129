"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _mapComponents = _interopRequireDefault(require("./components/mapComponents.vue"));

var _carpoolNew = require("@/api/business/carpoolNew");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    mapComponents: _mapComponents.default
  },
  props: {
    openOperationScopeDialog: {
      type: Boolean,
      default: false
    },
    lineData: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      timeRefresh0: "",
      timeRefresh2: "",
      timeRefresh1: "",
      timeRefresh3: "",
      activeName: "operation",
      rules: {
        electricFenceName: [{
          required: true,
          message: "请输入围栏名称",
          trigger: "blur"
        }, {
          min: 3,
          max: 15,
          message: "长度在 3 到 15 个字符",
          trigger: "blur"
        }],
        stations: [{
          required: true,
          message: "请输入活动区域",
          trigger: "blur"
        }]
      },
      configStatusList: [],
      // 配置状态列表 
      serviceType: ['operation'] // 服务列表

    };
  },
  created: function created() {
    // this.init()
    this.getLineOperateScopeConfigStatusList();
  },
  methods: {
    init: function init() {
      var _this = this;

      // 获取线路配置的服务类型
      (0, _carpoolNew.lineVehicleModelConfigService)({
        lineId: this.lineData.id
      }).then(function (res) {
        if (res.code == 200) {
          _this.serviceType = res.data;

          if (_this.serviceType.length) {
            _this.activeName = _this.serviceType[0];

            _this.getLineOperateScopeConfigStatusList(_this.serviceType[0]);
          }
        }
      });
    },

    /** 获取线路运行配置状态 */
    getLineOperateScopeConfigStatusList: function getLineOperateScopeConfigStatusList(serviceType) {
      var _this2 = this;

      (0, _carpoolNew.lineOperateScopeConfigStatusList)({
        lineId: this.lineData.id,
        serviceType: serviceType
      }).then(function (res) {
        if (res.code == 200) {
          _this2.configStatusList = res.data;
          console.log(_this2.configStatusList);
        }
      });
    },
    // 点击服务tab
    handleTabClick: function handleTabClick(tab, event) {
      console.log(this.activeName);
      this.getLineOperateScopeConfigStatusList(this.activeName);
    },
    // 点击上下车tab
    handlePaneClick: function handlePaneClick(tab, event) {
      console.log(tab, event, "--------");

      if (tab.paneName === "0") {
        this.timeRefresh0 = new Date().getTime();
        this.timeRefresh1 = new Date().getTime();
      }

      if (tab.paneName === "1") {
        this.timeRefresh2 = new Date().getTime();
        this.timeRefresh3 = new Date().getTime();
      } // this.$refs.child2.setFitViewFn()

    },
    reLoad: function reLoad(index) {
      console.log(index, "triggerReload");

      if (index === 0 || index) {
        switch (index) {
          case 1:
            this.timeRefresh1 = new Date().getTime();
            break;

          case 3:
            this.timeRefresh3 = new Date().getTime();
            break;

          case 0:
            this.timeRefresh0 = new Date().getTime();
            break;

          case 2:
            this.timeRefresh2 = new Date().getTime();
            break;
        } // this[`timeRefresh${index}`] = new Date().getTime();


        this.getLineOperateScopeConfigStatusList(this.activeName);
      }
    },
    handleDialogClose: function handleDialogClose(formName) {
      _jsCookie.default.remove("fenceCityCode");

      _jsCookie.default.remove("fenceCountryCode");

      this.$emit("closepop"); //通知父组件改变。

      this.$emit("reloadList");
    }
  }
};
exports.default = _default;