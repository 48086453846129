"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/web.dom.iterable");

var _typeof2 = _interopRequireDefault(require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime-corejs2/helpers/typeof"));

require("core-js/modules/es6.regexp.split");

var _vuedraggable = _interopRequireDefault(require("vuedraggable"));

var _utils = require("@/utils");

var _airport = require("@/api/business/airport");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    draggable: _vuedraggable.default
  },
  data: function data() {
    return {
      lineForm: {},
      tabList: [{
        name: "营运范围配置"
      }, {
        name: "价格配置"
      }, {
        name: "班次模板配置"
      }, {
        name: "售票配置"
      }, {
        name: "通用配置"
      }],
      currentTab: 0,
      // 左侧选中
      lineStationList: [],
      // 线路站点列表
      openEditLineStation: false,
      // 站点配置弹框
      lineStationLoading: false,
      // 站点配置弹框加载
      stationsList: [],
      // 公司下的全部站点
      lineStationConfigForm: {
        lineStationConfigList: [] // 线路站点配置列表

      },
      // 线路站点配置
      priceForm: {
        lid: null,
        // 线路ID
        price: null,
        // 票价
        priceType: 0,
        // 加价方式：1-一口价；2-区间票价
        markingPrice: null,
        // 划线价
        isOpenMarking: null,
        // 是否开启划线价：1-开启；0-关闭
        stationRaisePriceType: null,
        // 站点加价：0-不加价；1-每人加价；2-每单加价
        intervalPriceConfigs: [],
        // 区间价格信息
        raisePriceConfigs: {} // 动态加价信息

      },
      // 价格配置
      priceRules: {
        priceType: [{
          required: true,
          message: "请选择计价方式",
          trigger: "blur"
        }],
        price: [{
          required: true,
          message: "请输入线路票价",
          trigger: "blur"
        }],
        stationRaisePriceType: [{
          required: true,
          message: "请选择是否加价",
          trigger: "blur"
        }]
      },
      // 价格配置规则
      activeNames: [],
      // 班次区间票价展开modal
      openScheduleTemplate: false,
      // 是否打开添加班次模板弹框
      scheduleTemplateTitle: "",
      // 班次模板弹框标题
      scheduleTemplateForm: {
        scheduleType: 1,
        firstTime: "",
        lastTime: ""
      },
      // 班次模板弹框表单
      lineScheduleTemplateList: [],
      // 班次模板列表
      scheduleTemplateFormRules: {
        firstTime: [{
          required: true,
          message: "请选择出发时间",
          trigger: "blur"
        }],
        lastTime: [{
          required: true,
          message: "请选择末班时间",
          trigger: "blur"
        }],
        scheduleType: [{
          required: true,
          message: "请选择类型",
          trigger: "blur"
        }]
      },
      // 班次模板规则
      scheduleTemplateIndex: -1,
      // 班次模板下标，修改时记录
      lineSaleConfigForm: {
        stopSellingScheduleDepartBeforeMin: 0,
        stopSellingStationDepartBeforeMin: 0,
        refundDepartBeforeMin: 0,
        refundDepartBeforeServiceChargeMin: 0,
        refundDepartBeforeServiceChargeRatio: 0,
        refundDepartAfterMin: 0,
        refundDepartAfterServiceChargeRatio: 0,
        refundDepartAfterServiceChargeMin: 0,
        changeDepartBeforeMin: 0,
        changeDepartAfterMin: 0
      },
      // 线路下的售票配置
      lineSaleConfigFormRules: {
        stopSellingRule: [{
          required: true,
          message: "请选择售票截止时间",
          trigger: "blur"
        }],
        refundTicketRule: [{
          required: true,
          message: "请选择退票时间范围",
          trigger: "blur"
        }]
      } // 售票配置规则

    };
  },
  filters: {
    stationTagFilter: function stationTagFilter(tag) {
      var str = "";

      switch (tag) {
        case "2":
          str = "火车站";
          break;

        case "3":
          str = "机场";
          break;

        case "4":
          str = "客运站";
          break;

        default:
          str = "普通站点";
          break;
      }

      return str;
    },
    lineTypeFilter: function lineTypeFilter(type) {
      var serviceTypeName = "";

      switch (type) {
        case "1":
          serviceTypeName = "机场巴士";
          break;

        case "2":
          serviceTypeName = "定制客运";
          break;

        case "3":
          serviceTypeName = "城际拼车";
          break;

        default:
          serviceTypeName = "机场巴士";
          break;
      }

      return serviceTypeName;
    }
  },
  created: function created() {
    var _this = this;

    this.lineForm.id = this.$route.query.id; // 线路详情

    this.getDetail(); // 根据选项卡，请求接口

    this.chooseTab(this.currentTab); // 查询所有站点

    (0, _airport.airStationSearch)().then(function (res) {
      if (res.code == 200) {
        _this.stationsList = res.data.rows;
      }
    });
  },
  methods: {
    /** 查询机场线路详情 */
    getDetail: function getDetail() {
      var _this2 = this;

      this.loading = true;
      (0, _airport.airLineInfo)(this.lineForm).then(function (response) {
        _this2.lineForm = response.data;

        if (_this2.lineForm.ticketTellerType) {
          _this2.lineForm.ticketTellerType = _this2.lineForm.ticketTellerType.split(",");
        } else {
          _this2.lineForm.ticketTellerType = [];
        }

        _this2.loading = false;
      });
    },

    /**
     * 获取机场线路中配置的站点
     * isConfig 是否是配置站点请求
     */
    getAirLineStationList: function getAirLineStationList(isConfig) {
      var _this3 = this;

      (0, _airport.airLineStationList)({
        lid: this.lineForm.id
      }).then(function (res) {
        if (res.code == 200) {
          if (isConfig) {
            _this3.lineStationConfigForm.lineStationConfigList = res.data;
          } else {
            _this3.lineStationList = res.data;
          }
        }
      });
    },

    /**
     * 查询机场线路的价格配置
     */
    getAirLinePriceInfo: function getAirLinePriceInfo() {
      var _this4 = this;

      (0, _airport.airLinePriceInfo)({
        lid: this.lineForm.id
      }).then(function (res) {
        if (res.code == 200) {
          _this4.priceForm = res.data;

          for (var index = 0; index < _this4.priceForm.intervalPriceConfigs.length; index++) {
            _this4.activeNames.push("".concat(index, "p"));
          }

          console.log(_this4.priceForm);
        }
      });
    },

    /**
     * 查询机场线路的班次模板配置
     */
    getAirLineScheduleTemplateList: function getAirLineScheduleTemplateList() {
      var _this5 = this;

      (0, _airport.airLineScheduleTemplateList)({
        lid: this.lineForm.id
      }).then(function (res) {
        if (res.code == 200) {
          _this5.lineScheduleTemplateList = res.data;
        }
      });
    },

    /**
     * 查询机场线路的售票配置
     */
    getAirLineSalesTicketInfo: function getAirLineSalesTicketInfo() {
      var _this6 = this;

      (0, _airport.airLineSalesTicketInfo)({
        lid: this.lineForm.id
      }).then(function (res) {
        if (res.code == 200) {
          _this6.lineSaleConfigForm = res.data;
        }
      });
    },
    // 改变线路启用状态
    statusChange: function statusChange() {
      var _this7 = this;

      var str;

      if (this.lineForm.status) {
        str = "是否开启线路营运状态？";
      } else {
        str = "是否关闭线路营运状态？";
      }

      this.$confirm(str, "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return (0, _airport.airLineUpdate)({
          id: _this7.lineForm.id,
          status: _this7.lineForm.status
        });
      }).catch(function () {
        _this7.lineForm.status = !_this7.lineForm.status;
      });
    },
    // 选择左侧tab栏
    chooseTab: function chooseTab(index) {
      this.currentTab = index;

      if (index == 0) {
        this.getAirLineStationList();
      } else if (index == 1) {
        this.getAirLinePriceInfo();
      } else if (index == 2) {
        this.getAirLineScheduleTemplateList();
      } else if (index == 3) {
        this.getAirLineSalesTicketInfo();
      }
    },
    // 打开线路站点配置弹框
    openEditLineStationFn: function openEditLineStationFn() {
      this.openEditLineStation = true;
      this.getAirLineStationList(true);
    },
    // 保存线路站点配置
    submitEditLineStationFn: function submitEditLineStationFn() {
      var _this8 = this;

      if (this.filterLineStation()) {
        this.msgError("围栏与围栏、站点与围栏不允许重合！");
        return;
      }

      this.$refs["lineStationConfigForm"].validate(function (valid) {
        if (valid) {
          (0, _airport.airLineStationSave)(_this8.lineStationConfigForm.lineStationConfigList).then(function (res) {
            if (res.code == 200) {
              _this8.closeEditLineStationFn();

              _this8.getAirLineStationList();

              _this8.msgSuccess("保存成功");
            }
          });
        }
      });
    },

    /**
     * 过滤围栏、站点
     * 围栏与围栏、站点与围栏不允许重合
     */
    filterLineStation: function filterLineStation() {
      // 找出所有围栏
      var polygonList = this.lineStationConfigForm.lineStationConfigList.filter(function (item) {
        return item.stationType == 2;
      }); // 循环已选择的所有站点

      for (var i = 0; i < this.lineStationConfigForm.lineStationConfigList.length; i++) {
        var item = this.lineStationConfigForm.lineStationConfigList[i];

        var _loop = function _loop(j) {
          var element = polygonList[j]; // 本身不作判断

          if (element.id == item.id) {
            return "continue";
          } // 围栏的坐标点


          var path1 = [];
          element.stations.split(";").forEach(function (e) {
            var ite = e.split(",");
            path1.push([ite[0] * 1, ite[1] * 1]);
          }); // 围栏

          if (item.stationType == 2) {
            var path = [];
            item.stations.split(";").forEach(function (e) {
              var ite = e.split(",");
              path.push([ite[0] * 1, ite[1] * 1]);
            });
            var isRingInRing = AMap.GeometryUtil.isRingInRing(path, path1);
            var doesRingRingIntersect = AMap.GeometryUtil.doesRingRingIntersect(path, path1);

            if (isRingInRing || doesRingRingIntersect) {
              return {
                v: true
              };
            }
          } else if (item.stationType == 1) {
            // 站点
            var lnglat = [item.longitude, item.latitude];
            console.log(item);
            var isPointInRing = AMap.GeometryUtil.isPointInRing(lnglat, path1);

            if (isPointInRing) {
              return {
                v: true
              };
            }
          }
        };

        for (var j = 0; j < polygonList.length; j++) {
          var _ret = _loop(j);

          switch (_ret) {
            case "continue":
              continue;

            default:
              if ((0, _typeof2.default)(_ret) === "object") return _ret.v;
          }
        }
      }

      return false;
    },
    // 关闭线路站点配置弹框
    closeEditLineStationFn: function closeEditLineStationFn() {
      this.openEditLineStation = false;
    },
    // 线路站点配置添加站点
    addNewWay: function addNewWay() {
      var index = this.lineStationConfigForm.lineStationConfigList.length + 1; // 新增途经点

      this.lineStationConfigForm.lineStationConfigList.push({
        lid: this.lineForm.id,
        sequence: index,
        waitTime: "0",
        estimateTime: "0",
        stationName: "",
        up: "0",
        down: "0"
      });
    },
    // 切换站点时触发
    changeConfigStation: function changeConfigStation(index) {
      var item = this.lineStationConfigForm.lineStationConfigList[index];
      var station = this.stationsList.find(function (i) {
        return i.id === item.sid;
      });
      item.stationType = station.stationType;
      item.stationTag = station.stationTag;
      item.stationCityName = station.stationCityName;
      item.stationCityId = station.stationCityId;
      item.stationCityAdcode = station.stationCityAdcode;
      item.stationName = station.stationName;
      item.longitude = station.longitude;
      item.latitude = station.latitude;
      item.stations = station.stations;
    },
    // 线路站点配置删除站点
    delItem: function delItem(index) {
      this.lineStationConfigForm.lineStationConfigList.splice(index, 1);
    },
    // 保存线路价格配置
    submitEditPriceFn: function submitEditPriceFn() {
      var _this9 = this;

      this.$refs["priceForm"].validate(function (valid) {
        if (valid) {
          _this9.priceForm.lid = _this9.lineForm.id;
          (0, _airport.airLinePriceSave)(_this9.priceForm).then(function (res) {
            if (res.code == 200) {
              _this9.msgSuccess("保存成功");
            }
          });
        }
      });
    },
    // 打开添加班次模板弹框
    openScheduleTemplateFn: function openScheduleTemplateFn() {
      this.openScheduleTemplate = true;
      this.scheduleTemplateTitle = "添加班次";
    },
    // 修改添加班次模板弹框
    updateScheduleTemplateFn: function updateScheduleTemplateFn(index, row) {
      this.openScheduleTemplate = true;
      this.scheduleTemplateTitle = "修改班次";
      this.scheduleTemplateForm = (0, _utils.deepClone)(row);
      this.scheduleTemplateIndex = index;
      console.log(this.scheduleTemplateForm);
    },
    // 添加班次模板
    addScheduleTemplate: function addScheduleTemplate() {
      var _this10 = this;

      this.$refs["scheduleTemplateForm"].validate(function (valid) {
        if (valid) {
          // 修改班次
          if (_this10.scheduleTemplateTitle == "修改班次") {
            console.log(_this10.scheduleTemplateForm);
            var item = _this10.lineScheduleTemplateList[_this10.scheduleTemplateIndex];

            _this10.$set(item, "scheduleType", _this10.scheduleTemplateForm.scheduleType);

            _this10.$set(item, "firstTime", _this10.scheduleTemplateForm.firstTime);

            _this10.$set(item, "lastTime", _this10.scheduleTemplateForm.lastTime);
          } else {
            _this10.lineScheduleTemplateList.push({
              scheduleType: _this10.scheduleTemplateForm.scheduleType,
              firstTime: _this10.scheduleTemplateForm.firstTime,
              lastTime: _this10.scheduleTemplateForm.lastTime
            });
          }

          _this10.cancelScheduleTemplate();
        }
      });
    },
    // 保存添加班次模板
    submitScheduleTemplateForm: function submitScheduleTemplateForm() {
      var _this11 = this;

      var params = {
        id: this.lineForm.id,
        list: this.lineScheduleTemplateList
      };
      (0, _airport.airLineScheduleTemplateSave)(params).then(function (res) {
        if (res.code == 200) {
          _this11.msgSuccess("保存成功");

          _this11.lineScheduleTemplateList = res.data;
        }
      });
    },
    // 关闭添加班次模板弹框
    cancelScheduleTemplate: function cancelScheduleTemplate() {
      this.openScheduleTemplate = false;
      this.scheduleTemplateForm = {
        scheduleType: 1,
        firstTime: "",
        lastTime: ""
      };
      this.resetForm("scheduleTemplateForm");
    },
    // 删除班次模板
    deleteScheduleTemplateFn: function deleteScheduleTemplateFn(index) {
      this.lineScheduleTemplateList.splice(index, 1);
    },
    // 修改线路售票配置
    submitUpdateLineSaleConfigFn: function submitUpdateLineSaleConfigFn() {
      var _this12 = this;

      this.$refs["lineSaleConfigForm"].validate(function (valid) {
        if (valid) {
          _this12.lineSaleConfigForm.lid = _this12.lineForm.id;
          (0, _airport.airLineSalesTicketSave)(_this12.lineSaleConfigForm).then(function (res) {
            if (res.code == 200) {
              _this12.msgSuccess("保存成功");
            }
          });
        }
      });
    },
    // 修改线路通用配置
    submitUpdateLineConfigFn: function submitUpdateLineConfigFn() {
      var _this13 = this;

      var params = (0, _utils.deepClone)(this.lineForm);
      params.ticketTellerType = params.ticketTellerType.join(",");
      (0, _airport.airLineUpdate)(params).then(function (res) {
        if (res.code == 200) {
          _this13.msgSuccess("保存成功");
        }
      });
    }
  }
};
exports.default = _default;