"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listActivity = listActivity;
exports.getActivity = getActivity;
exports.addActivity = addActivity;
exports.updateActivity = updateActivity;
exports.editActivity = editActivity;
exports.activityStatistics = activityStatistics;
exports.activityStatisticsList = activityStatisticsList;
exports.activityStatisticsListExport = activityStatisticsListExport;
exports.listCoupon = listCoupon;
exports.addCoupon = addCoupon;
exports.getCoupon = getCoupon;
exports.listCouponNoPage = listCouponNoPage;
exports.couponStatisticsList = couponStatisticsList;
exports.couponStatistics = couponStatistics;
exports.couponGrantStatisticsList = couponGrantStatisticsList;
exports.couponGrantStatistics = couponGrantStatistics;
exports.couponStatisticsListExport = couponStatisticsListExport;
exports.couponGrantStatisticsListExport = couponGrantStatisticsListExport;
exports.couponBillStatisticsList = couponBillStatisticsList;
exports.couponBillStatisticsListExport = couponBillStatisticsListExport;
exports.listGift = listGift;
exports.addGift = addGift;
exports.giftNewSelectList = giftNewSelectList;
exports.sendCouponToUser = sendCouponToUser;
exports.sendCouponToUserList = sendCouponToUserList;
exports.couponLineList = couponLineList;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询活动列表
function listActivity(query) {
  return (0, _request.default)({
    url: '/activitynew/activityNew/list',
    method: 'get',
    params: query
  });
} // 查询活动详细


function getActivity(id) {
  return (0, _request.default)({
    url: '/activitynew/activityNew/' + id,
    method: 'get'
  });
} // 新增活动


function addActivity(data) {
  return (0, _request.default)({
    url: '/activitynew/activityNew',
    method: 'post',
    data: data
  });
} // 修改活动


function updateActivity(data) {
  return (0, _request.default)({
    url: '/activitynew/activityNew',
    method: 'put',
    data: data
  });
} // 开启关闭、删除活动


function editActivity(data) {
  return (0, _request.default)({
    url: '/activitynew/activityNew/editActivity',
    method: 'put',
    data: data
  });
} // 查询活动统计数据


function activityStatistics(query) {
  return (0, _request.default)({
    url: '/activitynew/activityNew/statistics',
    method: 'get',
    params: query
  });
} // 查询活动统计数据列表


function activityStatisticsList(query) {
  return (0, _request.default)({
    url: '/activitynew/activityNew/statisticsList',
    method: 'get',
    params: query
  });
} // 查询活动统计数据列表导出


function activityStatisticsListExport(query) {
  return (0, _request.default)({
    url: '/activitynew/activityNew/activityStatisticsListExport',
    method: 'get',
    params: query
  });
} // 查询优惠券列表


function listCoupon(query) {
  return (0, _request.default)({
    url: '/activitynew/couponNew/list',
    method: 'get',
    params: query
  });
} // 新增优惠券


function addCoupon(data) {
  return (0, _request.default)({
    url: '/activitynew/couponNew/v2/add',
    method: 'post',
    data: data
  });
} // 查询优惠券详细


function getCoupon(id) {
  return (0, _request.default)({
    url: '/activitynew/couponNew/' + id,
    method: 'get'
  });
} // 查询优惠券列表(不分页)


function listCouponNoPage(query) {
  return (0, _request.default)({
    url: '/activitynew/couponNew/selectCouponNew',
    method: 'get',
    params: query
  });
} // 优惠券-统计列表


function couponStatisticsList(query) {
  return (0, _request.default)({
    url: '/activitynew/couponNew/couponStatisticsList',
    method: 'get',
    params: query
  });
} // 优惠券-统计


function couponStatistics(query) {
  return (0, _request.default)({
    url: '/activitynew/couponNew/couponStatistics',
    method: 'get',
    params: query
  });
} // 优惠券-发放统计列表


function couponGrantStatisticsList(query) {
  return (0, _request.default)({
    url: '/activitynew/couponNew/grantStatisticsList',
    method: 'get',
    params: query
  });
} // 优惠券-发放统计


function couponGrantStatistics(query) {
  return (0, _request.default)({
    url: '/activitynew/couponNew/grantStatistics',
    method: 'get',
    params: query
  });
} // 查询优惠券统计数据列表导出


function couponStatisticsListExport(query) {
  return (0, _request.default)({
    url: '/activitynew/couponNew/couponStatisticsListExport',
    method: 'get',
    params: query
  });
} // 查询优惠券发放统计数据列表导出


function couponGrantStatisticsListExport(query) {
  return (0, _request.default)({
    url: '/activitynew/couponNew/grantStatisticsListExport',
    method: 'get',
    params: query
  });
} // 优惠账单统计


function couponBillStatisticsList(query) {
  return (0, _request.default)({
    url: '/activitynew/couponNew/couponBillStatisticsList',
    method: 'get',
    params: query
  });
} // 优惠账单统计-导出


function couponBillStatisticsListExport(query) {
  return (0, _request.default)({
    url: '/activitynew/couponNew/couponBillStatisticsListExport',
    method: 'get',
    params: query
  });
} // 查询优惠券包列表


function listGift(query) {
  return (0, _request.default)({
    url: '/activitynew/giftNew/list',
    method: 'get',
    params: query
  });
} // 新增优惠券包


function addGift(data) {
  return (0, _request.default)({
    url: '/activitynew/giftNew',
    method: 'post',
    data: data
  });
} // 查询优惠券包列表（包含优惠券列表）,券包详情


function giftNewSelectList(query) {
  return (0, _request.default)({
    url: '/activitynew/giftNew/selectList',
    method: 'get',
    params: query
  });
} // 发放优惠券


function sendCouponToUser(data) {
  return (0, _request.default)({
    url: '/activitynew/usercoupon/v2/sendCouponToUser',
    method: 'post',
    data: data
  });
} // 发放优惠券-批量导入


function sendCouponToUserList(data) {
  return (0, _request.default)({
    url: '/activitynew/usercoupon/v2/sendCouponToUserList',
    method: 'post',
    data: data
  });
} // 根据公司查询线路-拼车，班车


function couponLineList(query) {
  return (0, _request.default)({
    url: '/activitynew/couponNew/line/list',
    method: 'get',
    params: query
  });
}