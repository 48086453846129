"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _PanelGroup = _interopRequireDefault(require("./dashboard/PanelGroup"));

var _LineChart = _interopRequireDefault(require("./dashboard/LineChart"));

var _RaddarChart = _interopRequireDefault(require("./dashboard/RaddarChart"));

var _PieChart = _interopRequireDefault(require("./dashboard/PieChart"));

var _BarChart = _interopRequireDefault(require("./dashboard/BarChart"));

var _server = require("@/api/monitor/server");

var _auditing = require("@/api/system/auditing");

var _vueCountTo = _interopRequireDefault(require("vue-count-to"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var lineChartData = {
  newVisitis: {
    expectedData: [100, 120, 161, 134, 105, 160, 165],
    actualData: [120, 82, 91, 154, 162, 140, 145]
  },
  messages: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130]
  },
  purchases: {
    expectedData: [80, 100, 121, 104, 105, 90, 100],
    actualData: [120, 90, 100, 138, 142, 130, 130]
  },
  shoppings: {
    expectedData: [130, 140, 141, 142, 145, 150, 160],
    actualData: [120, 82, 91, 154, 162, 140, 130]
  }
};
var _default = {
  name: 'Index',
  components: {
    PanelGroup: _PanelGroup.default,
    LineChart: _LineChart.default,
    RaddarChart: _RaddarChart.default,
    PieChart: _PieChart.default,
    BarChart: _BarChart.default,
    CountTo: _vueCountTo.default
  },
  data: function data() {
    return {
      chartData: {
        title: {
          text: "客流量趋势图",
          textStyle: {
            color: "#333",
            fontWeight: "bolder"
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"]
        },
        yAxis: {
          type: "value"
        },
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["扫码", "APP", "公众号", "小件速运"]
        },
        series: []
      },
      chartData2: {
        title: {
          text: "收益趋势图",
          textStyle: {
            color: "#333",
            fontWeight: "bolder"
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"]
        },
        yAxis: {
          type: "value"
        },
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["扫码", "APP", "公众号", "小件速运"]
        },
        series: []
      },
      jinRiKeLiang: {},
      jinRiKeShouYi: {},
      options: [{
        value: 0,
        label: '本周'
      }, {
        value: 1,
        label: '本月'
      }, {
        value: 2,
        label: '三个月'
      }],
      types: ['本周', '本月', '三个月'],
      value1: 0,
      value2: 0,
      lineChartData: lineChartData.newVisitis,
      noticeNumberDetail: {},
      totalMoney: 0,
      totalPerson: 0,
      showTotalMoney: false,
      showTotalPerson: false,
      showKeLiang: false,
      showShowYi: false,
      showChartData1: false,
      showChartData2: false
    };
  },
  created: function created() {
    var _this = this;

    (0, _auditing.getNoticeNumber)({
      companyId: this.dept.deptId
    }).then(function (res) {
      if (res.code == 200) {
        _this.noticeNumberDetail = res.data;
      }
    });
  },
  methods: {
    // 总收益
    getTotalMoney: function getTotalMoney() {
      var _this2 = this;

      (0, _server.totalMoney)().then(function (res) {
        _this2.totalMoney = res.data.totalMoney;
        _this2.showTotalMoney = true;
      });
    },
    // 总客量
    getTotalPerson: function getTotalPerson() {
      var _this3 = this;

      (0, _server.totalPerson)().then(function (res) {
        _this3.totalPerson = res.data.totalPerson;
        _this3.showTotalPerson = true;
      });
    },
    // 今日收益
    getShouYi: function getShouYi() {
      var _this4 = this;

      (0, _server.shouYiKeLiang)().then(function (res) {
        _this4.jinRiKeShouYi = res.data;
        _this4.showShowYi = true;
      });
    },
    // 今日客量
    getKeLiang: function getKeLiang() {
      var _this5 = this;

      (0, _server.jinRiKeLiang)().then(function (res) {
        _this5.jinRiKeLiang = res.data;
        _this5.showKeLiang = true;
      });
    },
    // 客流量趋势图
    handleSetLineChartData1: function handleSetLineChartData1(type) {
      var _this6 = this;

      (0, _server.rongLiangQuShiTu)({
        type: this.types[this.value1]
      }).then(function (res) {
        res.data.series.forEach(function (item) {
          delete item.stack;
        });
        _this6.chartData.legend.data = res.data.legend;
        _this6.chartData.series = res.data.series;
        _this6.chartData.xAxis.data = res.data.xAxis;
        _this6.showChartData1 = true;
      });
    },
    // 收益趋势图
    handleSetLineChartData2: function handleSetLineChartData2(type) {
      var _this7 = this;

      (0, _server.shouYiQuShiTu)({
        type: this.types[this.value2]
      }).then(function (res) {
        res.data.series.forEach(function (item) {
          delete item.stack;
        });
        _this7.chartData2.legend.data = res.data.legend;
        _this7.chartData2.series = res.data.series;
        _this7.chartData2.xAxis.data = res.data.xAxis;
        _this7.showChartData2 = true;
      });
    },
    gotoData: function gotoData() {
      this.$router.push({
        path: "/dataAnalysisBus"
      });
    },
    gotoCarpoolData: function gotoCarpoolData() {
      this.$router.push({
        path: "/dataAnalysisCarpool"
      });
    },
    // 待办事件跳转到相应列表
    gotoList: function gotoList(type) {
      if (type == 1) {
        // 发票列表
        this.$router.push({
          path: "/finance/orderInvoice"
        });
      } else if (type == 2) {
        // 司机提现列表
        this.$router.push({
          path: "/finance/1/withdrawList"
        });
      } else if (type == 3) {
        // 用户提现列表
        this.$router.push({
          path: "/finance/1/apply"
        });
      } else if (type == 4) {
        // 待我审批列表
        this.$router.push({
          path: "/yunying/2/auditing"
        });
      } else if (type == 5) {
        // 我的申请列表
        this.$router.push({
          path: "/yunying/2/my"
        });
      } else if (type == 6) {
        // 订单投诉列表
        this.$router.push({
          path: "/yunying/3/complaint"
        });
      }
    }
  }
};
exports.default = _default;