"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _carpoolNew = require("@/api/business/carpoolNew");

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    openScheduleModuleDialog: {
      type: Boolean,
      default: false
    },
    lineData: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      loading: false,
      formDialog: {
        saveList: []
      },
      serviceType: [] // 服务类型

    };
  },
  created: function created() {
    this.getLists();
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      // 获取线路配置的服务类型
      (0, _carpoolNew.lineVehicleModelConfigService)({
        lineId: this.lineData.id
      }).then(function (res) {
        if (res.code == 200) {
          _this.serviceType = res.data;
        }
      });
    },
    // 获取班次模板列表
    getLists: function getLists() {
      var _this2 = this;

      (0, _carpoolNew.lineScheduleTemplateConfigList)({
        lineId: this.lineData.id
      }).then(function (res) {
        if (res.code == 200) {
          res.data.map(function (item) {
            if (item.serviceType) {
              item.serviceType = item.serviceType.split(",");
            }
          });
          _this2.formDialog.saveList = res.data;
        }
      });
    },
    removeItem: function removeItem(item) {
      this.formDialog.saveList.splice(item, 1);
    },
    addDomain: function addDomain() {
      this.formDialog.saveList.push({
        pickupTime: "",
        departureTime: "",
        lineId: this.lineData.id,
        seatSaleRule: '1',
        serviceType: [],
        carpoolNum: 0,
        charterNum: 0,
        allNum: 0
      });
    },
    handleSubmit: function handleSubmit() {
      var _this3 = this;

      console.log(this.formDialog);
      this.$refs["formDialog"].validate(function (valid) {
        if (valid) {
          _this3.loading = true;

          _this3.formDialog.saveList.map(function (item) {
            if ((0, _utils.isArray)(item.serviceType)) {
              item.serviceType = item.serviceType.join(",");
            }
          });

          (0, _carpoolNew.lineScheduleTemplateConfigSave)({
            lineId: _this3.lineData.id,
            saveList: _this3.formDialog.saveList
          }).then(function (res) {
            if (res.code === 200) {
              _this3.$message.success(res.msg);
            } else {
              _this3.$message.error(res.msg);
            }

            _this3.loading = false;

            _this3.$emit("closepop");
          });
        }
      });
    },
    handleDialogClose: function handleDialogClose() {
      this.$emit("closepop");
    },
    reload: function reload(obj) {
      console.log(obj, "obj");
      this.formDialog = obj;
    }
  }
};
exports.default = _default;