"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
var _default2 = {
  data: function data() {
    return {
      map: null,
      recordLists: [],
      markers: [],
      infos: []
    };
  },
  props: {
    orderDetail: {
      type: Object,
      default: function _default() {
        return null;
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    if (!this.orderDetail || !this.orderDetail.orderLatLng) {
      return;
    }

    this.$nextTick(function () {
      if (_this.orderDetail.orderLatLng) {
        for (var index = 0; index < _this.orderDetail.orderLatLng.length; index++) {
          var element = _this.orderDetail.orderLatLng[index];

          _this.recordLists.push([element.longitude, element.latitude]);
        }

        _this.initMap();
      }
    });
  },
  methods: {
    initMap: function initMap() {
      var tempMarkers = []; //基本地图加载

      this.map = new AMap.Map("mapcontainer", {
        resizeEnable: true,
        zoom: 14 //初始化地图层级

      });
      var polyline = new AMap.Polyline({
        path: this.recordLists,
        // 设置线覆盖物路径
        strokeWeight: 6,
        // 线宽
        lineJoin: "round",
        lineCap: "round",
        showDir: true
      });
      console.log(polyline);
      this.map.add([polyline]);
      this.map.setFitView(); // 起点

      new AMap.Marker({
        icon: new AMap.Icon({
          image: "//a.amap.com/jsapi/static/image/plugin/marker/start.png",
          imageSize: new AMap.Size(22, 28) //图标大小

        }),
        map: this.map,
        position: this.recordLists[0]
      }); // 终点

      new AMap.Marker({
        icon: new AMap.Icon({
          image: "//a.amap.com/jsapi/static/image/plugin/marker/end.png",
          imageSize: new AMap.Size(22, 28) //图标大小

        }),
        map: this.map,
        position: this.recordLists[this.recordLists.length - 1]
      }); // this.addEvent();
    },
    //添加marker标记
    addMarker: function addMarker(item, index, idx) {
      // debugger;
      var content = "<div style=\"line-height: 26px;\"><div style=\"font-size: 14px;\">".concat(item.statusMessage, "\uFF08\u5C3E\u53F7").concat(item.userPhone ? item.userPhone.slice(-4) : '', "\uFF09</div><div style=\"font-size: 13px;\">").concat(item.currentLongitude, ",").concat(item.currentLatitude, "</div><div style=\"font-size: 12px;color: grey;\">").concat(this.formatTime(item.updated), "</div</div>");
      this.infos.push(new AMap.InfoWindow({
        anchor: "bottom-center",
        content: content,
        offset: new AMap.Pixel(0, -36)
      }));
      this.markers.push( // new AMap.Marker({
      //   map: this.map,
      //   icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
      //   position: [item.currentLongitude, item.currentLatitude]
      // })
      new AMap.Marker({
        icon: new AMap.Icon({
          image: "//permanentPicture.hellouxing.com/carpoolback/mark_".concat(index, "_").concat(idx, ".png"),
          imageSize: new AMap.Size(22, 28) //图标大小

        }),
        map: this.map,
        position: [item.currentLongitude, item.currentLatitude]
      }));
    },
    addEvent: function addEvent() {
      var _this2 = this;

      if (!this.orderDetail || !this.orderDetail.carpoolTravelRecordMapDianList.length) {
        return;
      }

      var result = (0, _utils.formateArrData)(this.orderDetail.carpoolTravelRecordMapDianList, "orderNo", []);

      for (var index = 0; index < result.length; index++) {
        var item = result[index];

        for (var idx = 0; idx < item.length; idx++) {
          this.addMarker(item[idx], index, idx);
        }
      }

      var self = this;

      var _loop = function _loop(j) {
        var ite = _this2.markers[j];
        ite.on("click", function () {
          self.infos[j].open(self.map, ite.getPosition());
        }); // ite.on("mouseout", function() {
        //   self.infos[j].close();
        // });
      };

      for (var j = 0; j < this.markers.length; j++) {
        _loop(j);
      }
    }
  }
};
exports.default = _default2;