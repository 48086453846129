var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "top" }, [
        _c("div", { staticClass: "back", on: { click: _vm.backPage } }),
        _vm._v(" "),
        _vm._m(0)
      ]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "queryForm",
          staticClass: "search",
          attrs: { model: _vm.queryParams, inline: true }
        },
        [
          _c(
            "div",
            { staticClass: "search-item" },
            [
              _c("img", {
                staticClass: "icon",
                attrs: {
                  src: require("../assets/image/data_analysis_calendar@2x.png")
                }
              }),
              _vm._v(" "),
              _c("el-date-picker", {
                staticStyle: { background: "transparent" },
                attrs: {
                  clearable: "",
                  format: "yyyy-MM-dd HH:mm:ss",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.timeValue,
                  callback: function($$v) {
                    _vm.timeValue = $$v
                  },
                  expression: "timeValue"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "line" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "search-item" },
            [
              _c("img", {
                staticClass: "icon",
                attrs: {
                  src: require("../assets/image/data_analysis_line@2x.png")
                }
              }),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticStyle: { background: "transparent" },
                  attrs: {
                    "collapse-tags": "",
                    multiple: "",
                    filterable: "",
                    placeholder: "请选择线路"
                  },
                  model: {
                    value: _vm.lineId,
                    callback: function($$v) {
                      _vm.lineId = $$v
                    },
                    expression: "lineId"
                  }
                },
                _vm._l(_vm.queryParamsLine, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.lineName, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "query-button", on: { click: _vm.handleQuery } },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "query-button", on: { click: _vm.resetQuery } },
            [_vm._v("重置")]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { display: "flex", "justify-content": "space-around" } },
        [
          _c("el-col", { attrs: { span: 7 } }, [
            _c("div", { staticClass: "title-wrapper" }, [
              _c("div", { staticClass: "title" }, [_vm._v("用户行为漏斗")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [
                _c("funnel-chart", {
                  attrs: { "chart-data": _vm.passengerBehaviorCountData }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "funnel-detail-wrapper" },
                  _vm._l(_vm.passengerBehaviorCountData, function(item) {
                    return _c("div", { key: item.title, staticClass: "item" }, [
                      _c("div", [_vm._v(_vm._s(item.title))]),
                      _vm._v(" "),
                      _c("div", { staticClass: "num" }, [
                        _vm._v(_vm._s(item.num)),
                        _c("span", { staticClass: "unit" }, [_vm._v("人")])
                      ])
                    ])
                  }),
                  0
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 9 } }, [
            _c("div", { staticClass: "title-wrapper" }, [
              _c("div", { staticClass: "title" }, [_vm._v("乘客复购分析")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [
                _c("pie-chart", { attrs: { "chart-data": _vm.moreCountData } }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "button",
                    on: {
                      click: function($event) {
                        return _vm.openMoreCount()
                      }
                    }
                  },
                  [_vm._v("查看详情")]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 7 } }, [
            _c("div", { staticClass: "title-wrapper" }, [
              _c("div", { staticClass: "title" }, [_vm._v("线路站点排行榜")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "chart-wrapper" }, [
              _c("div", { staticClass: "line-header" }, [
                _c(
                  "div",
                  { staticClass: "header-title", staticStyle: { flex: "1" } },
                  [_vm._v("排序")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "header-title", staticStyle: { flex: "2" } },
                  [_vm._v("站点名称")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "header-title", staticStyle: { flex: "1" } },
                  [_vm._v("人数")]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "line-rank-wrapper" },
                _vm._l(_vm.stationLevelData, function(item, index) {
                  return _c("div", { staticClass: "line-rank" }, [
                    _c(
                      "div",
                      { staticClass: "item num", staticStyle: { flex: "1" } },
                      [_vm._v(_vm._s(index + 1))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "item station",
                        staticStyle: { flex: "2" }
                      },
                      [_vm._v(_vm._s(item.stationName))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item total", staticStyle: { flex: "1" } },
                      [_vm._v(_vm._s(item.total) + "人")]
                    )
                  ])
                }),
                0
              )
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "chart-wrapper",
          staticStyle: {
            height: "500px",
            "margin-left": "15px",
            "margin-right": "15px"
          }
        },
        [
          _c("across-bar-chart", {
            staticStyle: { "margin-top": "60px" },
            attrs: { "chart-data": _vm.scheduleByDayTimeCountData }
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.downScheduleByDayTimeCountExcel()
                }
              }
            },
            [_vm._v("下载报表")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-around",
            "margin-left": "15px",
            "margin-right": "15px",
            "margin-top": "20px"
          }
        },
        [
          _c(
            "el-col",
            { staticStyle: { "margin-right": "20px" }, attrs: { span: 10 } },
            [
              _c("div", { staticClass: "title-wrapper" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v("乘客预购票行为分析")
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "chart-wrapper" },
                [
                  _c("pie-chart", {
                    attrs: { "chart-data": _vm.timeLevelCountData }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "button",
                      on: {
                        click: function($event) {
                          return _vm.downTimeLevelCountExcel()
                        }
                      }
                    },
                    [_vm._v("下载报表")]
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 14 } }, [
            _c("div", { staticClass: "title-wrapper" }, [
              _c("div", { staticClass: "title" }, [_vm._v("订单趋势")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [
                _c("line-bar-chart", {
                  attrs: { "chart-data": _vm.busTicketOrderCountData }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _vm.openMoreCountDetail
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "乘客复购详情",
                visible: _vm.openMoreCountDetail,
                "close-on-click-modal": false,
                "append-to-body": true,
                width: "90%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.openMoreCountDetail = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "15px" } },
                _vm._l(_vm.buyCount, function(item, index) {
                  return _c(
                    "el-button",
                    {
                      key: item.count,
                      staticClass: "count-tag",
                      class: _vm.count === item.count ? "count-tag-ac" : "",
                      attrs: { size: "mini" },
                      on: {
                        click: function($event) {
                          return _vm.getBusTicketListCount(item.count, $event)
                        }
                      },
                      model: {
                        value: item.count,
                        callback: function($$v) {
                          _vm.$set(item, "count", $$v)
                        },
                        expression: "item.count"
                      }
                    },
                    [_vm._v("\n        " + _vm._s(item.title) + "\n      ")]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  attrs: { data: _vm.busTicketListCountData }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "序号", align: "center", type: "index" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "手机号", align: "center", prop: "phone" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "购买次数",
                      align: "center",
                      prop: "buyCount"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "注册时间",
                      align: "center",
                      prop: "created"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0"
                  }
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.queryParams.pageNum,
                  limit: _vm.queryParams.pageSize
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.queryParams, "pageNum", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.queryParams, "pageSize", $event)
                  },
                  pagination: function($event) {
                    return _vm.getBusTicketListCount()
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "onduty-user" }, [
      _vm._v("\n      平台值班人员:\n      "),
      _c("div", { staticClass: "title" }, [_vm._v("石伟伟：18710105752")]),
      _vm._v("\n      平台客服：\n      "),
      _c("div", { staticClass: "title" }, [_vm._v("400-032-9996")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "title-wrapper",
        staticStyle: {
          "margin-left": "15px",
          "margin-right": "15px",
          "margin-top": "20px"
        }
      },
      [_c("div", { staticClass: "title" }, [_vm._v("各班次售票分布分析")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "map-wrapper" }, [
      _c("div", { staticClass: "title-wrapper" }, [
        _c("div", { staticClass: "title" }, [_vm._v("发单热力大盘")])
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "new_map" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }