var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box-card" },
    [
      _c("div", { staticClass: "tips" }, [
        _vm._v("\n    线路名称："),
        _c("span", [_vm._v(_vm._s(_vm.lineData.lineName))])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tips" },
        [
          _vm._v("\n    线路营运状态：\n    "),
          _c("el-switch", {
            attrs: { size: "mini" },
            on: { change: _vm.updateLineStatus },
            model: {
              value: _vm.lineData.status,
              callback: function($$v) {
                _vm.$set(_vm.lineData, "status", $$v)
              },
              expression: "lineData.status"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.lists, function(item, index) {
        return _c(
          "div",
          { key: index, staticClass: "dashboard-text" },
          [
            _c("span", [_vm._v(_vm._s(item.configItem))]),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticStyle: { color: "#67C23A" },
                attrs: { type: "text" },
                on: {
                  click: function($event) {
                    return _vm.opneDialog(item.configItem)
                  }
                }
              },
              [_vm._v("查看")]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "line" }, [
              _vm._v(
                " - - - - - - - - - - - - - - - - - - - - - - - -\n      - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -\n      - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -\n      - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -\n      - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -\n      - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -\n      - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -\n      -"
              )
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                style: {
                  color: item.status != 0 ? "#67C23A" : "#F56C6C",
                  fontSize: "14px"
                }
              },
              [_vm._v(_vm._s(item.status != 0 ? "已设置" : "未设置"))]
            )
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.openServiceDialog
        ? _c("dialogServiceConfig", {
            attrs: {
              openServiceDialog: _vm.openServiceDialog,
              lineData: _vm.lineData
            },
            on: {
              submit: _vm.submitServiceDialog,
              closepop: _vm.closeServiceDialog
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openOperationScopeDialog
        ? _c("dialogOperationScope", {
            key: _vm.timeRefresh,
            attrs: {
              lineData: _vm.lineData,
              openOperationScopeDialog: _vm.openOperationScopeDialog
            },
            on: { reload: _vm.reLoad, closepop: _vm.closeOperationScopeDialog }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openLinePriceDialog
        ? _c("dialogLinePrice", {
            attrs: {
              openLinePriceDialog: _vm.openLinePriceDialog,
              lineData: _vm.lineData
            },
            on: {
              submit: _vm.submitLinePriceDialog,
              closepop: _vm.closeLinePriceDialog
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openScheduleModuleDialog
        ? _c("dialogScheduleModuleDialog", {
            attrs: {
              lineData: _vm.lineData,
              openScheduleModuleDialog: _vm.openScheduleModuleDialog
            },
            on: { closepop: _vm.closeScheduleModuleDialog }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openDialogCancelConfig
        ? _c("dialogCancelConfig", {
            attrs: {
              lineData: _vm.lineData,
              openDialogCancelConfig: _vm.openDialogCancelConfig
            },
            on: { closepop: _vm.closeDialogCancelConfig }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openGeneralConfigDialog
        ? _c("dialogGeneralConfig", {
            attrs: {
              openGeneralConfigDialog: _vm.openGeneralConfigDialog,
              lineData: _vm.lineData
            },
            on: {
              submit: _vm.submitGeneralConfigDialog,
              closepop: _vm.closeGeneralConfigDialog
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }