//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { deepClone } from "@/utils";
import { orderDetail, selectBusTicketAndRefund, listNoPageDetails, orderSchedule as _orderSchedule, orderMoney as _orderMoney, getOrderNote as _getOrderNote } from "@/api/order";
export default {
  name: "OrderManager",
  data: function data() {
    return {
      orderNo: null,
      form: {},
      logs: [],
      formtable: [],
      scheduleDetail: {},
      moneyDetail: {},
      noteDetail: ""
    };
  },
  created: function created() {
    this.orderNo = this.$route.query.orderNo;
  },
  methods: {
    // 订单详情
    getOrderDetail: function getOrderDetail() {
      var _this = this;

      orderDetail({
        orderNo: this.orderNo
      }).then(function (response) {
        _this.form = response.data;
      });
    },
    // 班次详情
    orderSchedule: function orderSchedule() {
      var _this2 = this;

      _orderSchedule({
        orderNo: this.orderNo
      }).then(function (response) {
        _this2.scheduleDetail = response.data;
      });
    },
    // 金额详情
    orderMoney: function orderMoney() {
      var _this3 = this;

      _orderMoney({
        orderNo: this.orderNo
      }).then(function (response) {
        _this3.moneyDetail = response.data;
      });
    },
    // 乘客信息
    getPersonDetail: function getPersonDetail() {
      var _this4 = this;

      selectBusTicketAndRefund({
        orderNo: this.orderNo
      }).then(function (response) {
        _this4.formtable = response.data;
      });
    },
    // 日志信息
    getLogs: function getLogs() {
      var _this5 = this;

      listNoPageDetails({
        orderNo: this.orderNo
      }).then(function (response) {
        _this5.logs = response.data;
      });
    },
    // 备注详情
    getOrderNote: function getOrderNote() {
      var _this6 = this;

      _getOrderNote({
        orderNo: this.orderNo
      }).then(function (response) {
        _this6.noteDetail = response.data;
      });
    }
  }
};