"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.airStationSearch = airStationSearch;
exports.airStationAdd = airStationAdd;
exports.airStationUpdate = airStationUpdate;
exports.airStationLookUp = airStationLookUp;
exports.airStationDelete = airStationDelete;
exports.airLineSearch = airLineSearch;
exports.airLineAdd = airLineAdd;
exports.airLineUpdate = airLineUpdate;
exports.airLineInfo = airLineInfo;
exports.airLineDelete = airLineDelete;
exports.airLineStationList = airLineStationList;
exports.airLineStationSave = airLineStationSave;
exports.airLinePriceInfo = airLinePriceInfo;
exports.airLinePriceSave = airLinePriceSave;
exports.airLineScheduleTemplateList = airLineScheduleTemplateList;
exports.airLineScheduleTemplateSave = airLineScheduleTemplateSave;
exports.airLineSalesTicketInfo = airLineSalesTicketInfo;
exports.airLineSalesTicketSave = airLineSalesTicketSave;
exports.airScheduleList = airScheduleList;
exports.airScheduleAdd = airScheduleAdd;
exports.allCars = allCars;
exports.allDriver = allDriver;
exports.airScheduleDelete = airScheduleDelete;
exports.airScheduleStartStop = airScheduleStartStop;
exports.airScheduleArrange = airScheduleArrange;
exports.airScheduleFlowArrange = airScheduleFlowArrange;
exports.airScheduleFlowDelete = airScheduleFlowDelete;
exports.airScheduleShortcut = airScheduleShortcut;
exports.airSchedulePriceInfo = airSchedulePriceInfo;
exports.airSchedulePriceSave = airSchedulePriceSave;
exports.airScheduleStationList = airScheduleStationList;
exports.airScheduleStationSave = airScheduleStationSave;
exports.airMachineManagerSearch = airMachineManagerSearch;
exports.airMachineManagerAdd = airMachineManagerAdd;
exports.airMachineManagerUpdate = airMachineManagerUpdate;
exports.airMachineManagerDelete = airMachineManagerDelete;
exports.airMachineManagerGetHeartbeatList = airMachineManagerGetHeartbeatList;
exports.airMachineManagerQueryAllMachineNo = airMachineManagerQueryAllMachineNo;
exports.airInspectorSearch = airInspectorSearch;
exports.airInspectorAdd = airInspectorAdd;
exports.airInspectorUpdate = airInspectorUpdate;
exports.airInspectorDelete = airInspectorDelete;
exports.airUpDownCityQueryRidingCity = airUpDownCityQueryRidingCity;
exports.airPassengerTypeQueryAll = airPassengerTypeQueryAll;
exports.airPassengerConfigQuery = airPassengerConfigQuery;
exports.airPassengerConfigAdd = airPassengerConfigAdd;
exports.airPassengerConfigUpdate = airPassengerConfigUpdate;
exports.airPassengerConfigDelete = airPassengerConfigDelete;
exports.airGlobalConfigQuery = airGlobalConfigQuery;
exports.airGlobalConfigAddOrEdit = airGlobalConfigAddOrEdit;
exports.airLineForCompany = airLineForCompany;
exports.airOrderList = airOrderList;
exports.airOrderListExport = airOrderListExport;
exports.airOrderRefund = airOrderRefund;
exports.airOrderDetail = airOrderDetail;
exports.airInvoiceList = airInvoiceList;
exports.airInvoiceListExport = airInvoiceListExport;
exports.airInvoiceDetails = airInvoiceDetails;
exports.airInvoiceOrderList = airInvoiceOrderList;
exports.airInvoiceItineraryListPDF = airInvoiceItineraryListPDF;
exports.airInvoiceRecord = airInvoiceRecord;
exports.airInvoiceReverser = airInvoiceReverser;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询机场站点列表
function airStationSearch(query) {
  return (0, _request.default)({
    url: '/airport/station/search',
    method: 'get',
    params: query
  });
} // 新增机场站点


function airStationAdd(data) {
  return (0, _request.default)({
    url: '/airport/station/add',
    method: 'post',
    data: data
  });
} // 修改机场站点


function airStationUpdate(data) {
  return (0, _request.default)({
    url: '/airport/station/update',
    method: 'post',
    data: data
  });
} // 查询机场站点详情


function airStationLookUp(query) {
  return (0, _request.default)({
    url: '/airport/station/lookUp',
    method: 'get',
    params: query
  });
} // 删除机场站点


function airStationDelete(data) {
  return (0, _request.default)({
    url: '/airport/station/delete',
    method: 'post',
    data: data
  });
} // 查询机场线路列表


function airLineSearch(query) {
  return (0, _request.default)({
    url: '/airport/line/search',
    method: 'get',
    params: query
  });
} // 新增机场线路


function airLineAdd(data) {
  return (0, _request.default)({
    url: '/airport/line/add',
    method: 'post',
    data: data
  });
} // 修改机场线路


function airLineUpdate(data) {
  return (0, _request.default)({
    url: '/airport/line/update',
    method: 'post',
    data: data
  });
} // 查询机场线路详情


function airLineInfo(query) {
  return (0, _request.default)({
    url: '/airport/line/info',
    method: 'get',
    params: query
  });
} // 删除机场线路


function airLineDelete(data) {
  return (0, _request.default)({
    url: '/airport/line/delete',
    method: 'post',
    data: data
  });
} // 查询机场线路配置的站点列表


function airLineStationList(query) {
  return (0, _request.default)({
    url: '/airport/line/station/list',
    method: 'get',
    params: query
  });
} // 保存机场线路配置的站点列表


function airLineStationSave(data) {
  return (0, _request.default)({
    url: '/airport/line/station/save',
    method: 'post',
    data: data
  });
} // 查询机场线路的价格配置


function airLinePriceInfo(query) {
  return (0, _request.default)({
    url: '/airport/line/price/info',
    method: 'get',
    params: query
  });
} // 保存机场线路的价格配置


function airLinePriceSave(data) {
  return (0, _request.default)({
    url: '/airport/line/price/save',
    method: 'post',
    data: data
  });
} // 查询机场线路班次模板配置


function airLineScheduleTemplateList(query) {
  return (0, _request.default)({
    url: '/airport/line/schedule/template/list',
    method: 'get',
    params: query
  });
} // 保存机场线路班次模板配置


function airLineScheduleTemplateSave(data) {
  return (0, _request.default)({
    url: '/airport/line/schedule/template/save/' + data.id,
    method: 'post',
    data: data.list
  });
} // 查询机场线路售票配置


function airLineSalesTicketInfo(query) {
  return (0, _request.default)({
    url: '/airport/line/sales/ticket/info',
    method: 'get',
    params: query
  });
} // 保存机场线路售票配置


function airLineSalesTicketSave(data) {
  return (0, _request.default)({
    url: '/airport/line/sales/ticket/save',
    method: 'post',
    data: data
  });
} // 查询机场线路班次列表


function airScheduleList(query) {
  return (0, _request.default)({
    url: '/airport/schedule/list',
    method: 'get',
    params: query
  });
} // 添加机场线路班次


function airScheduleAdd(data) {
  return (0, _request.default)({
    url: '/airport/schedule/add',
    method: 'post',
    data: data
  });
} // 排班时查询公司所有车型/车辆


function allCars(params) {
  return (0, _request.default)({
    url: '/system/car/carList',
    method: 'get',
    params: params
  });
} // 排班时查询公司所有司机


function allDriver(params) {
  return (0, _request.default)({
    url: '/system/driver/drivers',
    method: 'get',
    params: params
  });
} // 删除机场线路班次


function airScheduleDelete(data) {
  return (0, _request.default)({
    url: '/airport/schedule/delete',
    method: 'post',
    data: data
  });
} // 机场线路班次批量起售/停售


function airScheduleStartStop(data) {
  return (0, _request.default)({
    url: '/airport/schedule/startStop',
    method: 'post',
    data: data
  });
} // 修改机场线路班次信息


function airScheduleArrange(data) {
  return (0, _request.default)({
    url: '/airport/schedule/arrange',
    method: 'post',
    data: data
  });
} // 机场线路流水班次安排车辆、司机信息


function airScheduleFlowArrange(data) {
  return (0, _request.default)({
    url: '/airport/schedule/flow/arrange',
    method: 'post',
    data: data
  });
} // 机场线路流水班次删除安排车辆


function airScheduleFlowDelete(data) {
  return (0, _request.default)({
    url: '/airport/schedule/flow/delete',
    method: 'post',
    data: data
  });
} // 机场线路班次快捷排班


function airScheduleShortcut(data) {
  return (0, _request.default)({
    url: '/airport/schedule/shortcut',
    method: 'post',
    data: data
  });
} // 查询机场线路下班次的价格配置


function airSchedulePriceInfo(query) {
  return (0, _request.default)({
    url: '/airport/schedule/price/info',
    method: 'get',
    params: query
  });
} // 保存机场线路下班次的价格配置


function airSchedulePriceSave(data) {
  return (0, _request.default)({
    url: '/airport/schedule/price/save',
    method: 'post',
    data: data
  });
} // 查询机场线路下班次的站点列表


function airScheduleStationList(query) {
  return (0, _request.default)({
    url: '/airport/schedule/station/list',
    method: 'get',
    params: query
  });
} // 保存机场线路下班次的站点列表


function airScheduleStationSave(data) {
  return (0, _request.default)({
    url: '/airport/schedule/station/save',
    method: 'post',
    data: data
  });
} // 查询售票机列表


function airMachineManagerSearch(query) {
  return (0, _request.default)({
    url: '/airport/machine/manager/search',
    method: 'get',
    params: query
  });
} // 新增售票机


function airMachineManagerAdd(data) {
  return (0, _request.default)({
    url: '/airport/machine/manager/add',
    method: 'post',
    data: data
  });
} // 修改售票机


function airMachineManagerUpdate(data) {
  return (0, _request.default)({
    url: '/airport/machine/manager/update',
    method: 'post',
    data: data
  });
} // 删除售票机


function airMachineManagerDelete(query) {
  return (0, _request.default)({
    url: '/airport/machine/manager/delete',
    method: 'delete',
    params: query
  });
} // 获取所有售票机的心跳数据


function airMachineManagerGetHeartbeatList(query) {
  return (0, _request.default)({
    url: '/airport/machine/manager/getHeartbeatList',
    method: 'get',
    params: query
  });
} // 获取获取所有售票机编码


function airMachineManagerQueryAllMachineNo(query) {
  return (0, _request.default)({
    url: '/airport/machine/manager/queryAllMachineNo',
    method: 'get',
    params: query
  });
} // 查询验票员列表


function airInspectorSearch(query) {
  return (0, _request.default)({
    url: '/airport/line/ticket/inspector/manage/search',
    method: 'get',
    params: query
  });
} // 新增验票员


function airInspectorAdd(data) {
  return (0, _request.default)({
    url: '/airport/line/ticket/inspector/manage/add',
    method: 'post',
    data: data
  });
} // 修改验票员


function airInspectorUpdate(data) {
  return (0, _request.default)({
    url: '/airport/line/ticket/inspector/manage/update',
    method: 'post',
    data: data
  });
} // 删除验票员


function airInspectorDelete(query) {
  return (0, _request.default)({
    url: '/airport/line/ticket/inspector/manage/delete',
    method: 'delete',
    params: query
  });
} // 查询乘车城市管理列表数据


function airUpDownCityQueryRidingCity(query) {
  return (0, _request.default)({
    url: '/airport/upDownCity/queryRidingCity',
    method: 'get',
    params: query
  });
} // 查询所有乘客类型


function airPassengerTypeQueryAll(query) {
  return (0, _request.default)({
    url: '/airport/passenger/type/queryAll',
    method: 'get',
    params: query
  });
} // 查询乘客类型配置列表数据


function airPassengerConfigQuery(query) {
  return (0, _request.default)({
    url: '/airport/passenger/config/query',
    method: 'get',
    params: query
  });
} // 新增乘客类型


function airPassengerConfigAdd(data) {
  return (0, _request.default)({
    url: '/airport/passenger/config/add',
    method: 'post',
    data: data
  });
} // 修改乘客类型


function airPassengerConfigUpdate(data) {
  return (0, _request.default)({
    url: '/airport/passenger/config/update',
    method: 'put',
    data: data
  });
} // 删除乘客类型


function airPassengerConfigDelete(query) {
  return (0, _request.default)({
    url: '/airport/passenger/config/delete',
    method: 'delete',
    params: query
  });
} // 查询乘客订单取消配置


function airGlobalConfigQuery(query) {
  return (0, _request.default)({
    url: '/airport/global/config/queryByCompanyId',
    method: 'get',
    params: query
  });
} // 新增或修改乘客订单取消配置


function airGlobalConfigAddOrEdit(data) {
  return (0, _request.default)({
    url: '/airport/global/config/addOrEdit',
    method: 'post',
    data: data
  });
} // 查询公司下所有线路


function airLineForCompany(query) {
  return (0, _request.default)({
    url: '/airport/line/ticket/inspector/manage/queryLineByCompanyId',
    method: 'get',
    params: query
  });
} // 查询机场订单列表


function airOrderList(data) {
  return (0, _request.default)({
    url: '/airport/order/list',
    method: 'post',
    data: data
  });
} // 导出机场订单列表


function airOrderListExport(data) {
  return (0, _request.default)({
    url: '/airport/order/list/export',
    method: 'post',
    data: data
  });
} // 机场订单退款


function airOrderRefund(data) {
  return (0, _request.default)({
    url: '/airport/order/refund',
    method: 'post',
    data: data
  });
} // 查询机场订单详情


function airOrderDetail(query) {
  return (0, _request.default)({
    url: '/airport/order/details',
    method: 'get',
    params: query
  });
} // 查询发票列表


function airInvoiceList(data) {
  return (0, _request.default)({
    url: '/airport/invoice/list',
    method: 'post',
    data: data
  });
} // 发票列表导出


function airInvoiceListExport(data) {
  return (0, _request.default)({
    url: '/airport/invoice/list/export',
    method: 'post',
    data: data
  });
} // 发票详情


function airInvoiceDetails(query) {
  return (0, _request.default)({
    url: '/airport/invoice/details',
    method: 'get',
    params: query
  });
} // 发票包含订单


function airInvoiceOrderList(data) {
  return (0, _request.default)({
    url: '/airport/invoice/order/list',
    method: 'post',
    data: data
  });
} // 发票行程单


function airInvoiceItineraryListPDF(query) {
  return (0, _request.default)({
    responseType: 'blob',
    url: '/airport/invoice/itinerary/list/pdf',
    method: 'get',
    params: query
  });
} // 发票日志


function airInvoiceRecord(id) {
  return (0, _request.default)({
    url: '/airport/invoice/record/' + id,
    method: 'get'
  });
} // 发票退票


function airInvoiceReverser(id) {
  return (0, _request.default)({
    url: '/airport/invoice/reverser/' + id,
    method: 'get'
  });
}