"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listLine = listLine;
exports.allListLine = allListLine;
exports.getLine = getLine;
exports.getStartCity = getStartCity;
exports.getEndCity = getEndCity;
exports.addLine = addLine;
exports.updateLine = updateLine;
exports.delLine = delLine;
exports.exportLine = exportLine;
exports.listNotPage = listNotPage;
exports.lineOperation = lineOperation;
exports.scheduleOperation = scheduleOperation;
exports.lineStation = lineStation;
exports.lineRefundConfig = lineRefundConfig;
exports.lineRefundConfigUpdate = lineRefundConfigUpdate;
exports.lineRefundConfigDeputy = lineRefundConfigDeputy;
exports.lineRefundConfigUpdateDeputy = lineRefundConfigUpdateDeputy;
exports.busLineList = busLineList;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询线路列表
function listLine(query) {
  return (0, _request.default)({
    url: '/bus/line/list',
    method: 'get',
    params: query
  });
} // 查询线路列表


function allListLine() {
  return (0, _request.default)({
    url: '/bus/line/getLineListNoPage',
    method: 'get'
  });
} // 查询线路详细


function getLine(id) {
  return (0, _request.default)({
    url: '/bus/line/' + id,
    method: 'get'
  });
}

function getStartCity() {
  return (0, _request.default)({
    url: '/bus/city/selectStartCityList',
    method: 'get'
  });
}

function getEndCity(id) {
  return (0, _request.default)({
    url: '/bus/city/selectEndCityList',
    method: 'get'
  });
} // 新增线路


function addLine(data) {
  return (0, _request.default)({
    url: '/bus/line',
    method: 'post',
    data: data
  });
} // 修改线路


function updateLine(data) {
  return (0, _request.default)({
    url: '/bus/line',
    method: 'put',
    data: data
  });
} // 删除线路


function delLine(id) {
  return (0, _request.default)({
    url: '/bus/line/' + id,
    method: 'delete'
  });
} // 导出线路


function exportLine(query) {
  return (0, _request.default)({
    url: '/bus/line/export',
    method: 'get',
    params: query
  });
} // 线路管理页面需要用到的站点lists


function listNotPage(query) {
  return (0, _request.default)({
    url: '/bus/bus/listNotPage',
    method: 'get',
    params: query
  });
}

function lineOperation(query) {
  return (0, _request.default)({
    url: '/bus/line/listOperation',
    method: 'get',
    params: query
  });
}

function scheduleOperation(query) {
  return (0, _request.default)({
    url: '/bus/schedule/listOperation',
    method: 'get',
    params: query
  });
}

function lineStation(params) {
  return (0, _request.default)({
    url: '/bus/lineStation',
    method: 'get',
    params: params
  });
} // 售票配置查询


function lineRefundConfig(query) {
  return (0, _request.default)({
    url: '/bus/line/lineRefundConfig',
    method: 'get',
    params: query
  });
} // 配置更新 


function lineRefundConfigUpdate(data) {
  return (0, _request.default)({
    url: '/bus/line/lineRefundConfig',
    method: 'post',
    data: data
  });
} // 售票配置查询


function lineRefundConfigDeputy(query) {
  return (0, _request.default)({
    url: '/bus/line/lineRefundConfigDeputy',
    method: 'get',
    params: query
  });
} // 配置更新 


function lineRefundConfigUpdateDeputy(data) {
  return (0, _request.default)({
    url: '/bus/line/lineRefundConfigDeputy',
    method: 'post',
    data: data
  });
} // 获取公司下班车线路列表


function busLineList(data) {
  return (0, _request.default)({
    url: '/operations/summary/busLineList',
    method: 'post',
    data: data
  });
}