"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _carpoolNew = require("@/api/business/carpoolNew");

var _mapLineTripComponents = _interopRequireDefault(require("./components/mapLineTripComponents"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    mapLineComponents: _mapLineTripComponents.default
  },
  data: function data() {
    return {
      showMap: false,
      tripItem: null,
      recordLists: [],
      tripDetail: {
        travelInfo: {},
        travelOrders: [],
        travelTracking: [],
        travelLatLng: []
      },
      opneOrderRecordAudio: false,
      // 打开行程录音弹框
      audioSrc: '' // 行程录音链接

    };
  },
  created: function created() {
    console.log(this.$route.query);
    this.tripItem = this.$route.query;
    this.getDetail();
  },
  methods: {
    /** 获取行程详情 */
    getDetail: function getDetail() {
      var _this = this;

      (0, _carpoolNew.travelQueryById)({
        travelId: this.tripItem.id
      }).then(function (res) {
        if (res.code == 200) {
          _this.tripDetail = res.data; // 行程录音

          _this.audioSrc = 'https://helloux.oss-cn-beijing.aliyuncs.com/carpool/carpool_driver_travel_record/d' + _this.tripDetail.travelInfo.driverId + '_t' + _this.tripDetail.travelInfo.travelId + '.mp3';
        }
      }); // 行程线路

      (0, _carpoolNew.travelTrajectory)({
        travelId: this.tripItem.id
      }).then(function (res) {
        if (res.code == 200) {
          _this.tripDetail.travelLatLng = res.data;

          if (_this.tripDetail.travelLatLng && _this.tripDetail.travelLatLng.length > 0) {
            _this.showMap = true;
          }
        }
      });
    }
  }
};
exports.default = _default;