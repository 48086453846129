"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _dialogOperationScope = _interopRequireDefault(require("./dialogOperationScope.vue"));

var _dialogLinePrice = _interopRequireDefault(require("./dialogLinePrice.vue"));

var _dialogCarConfig = _interopRequireDefault(require("./dialogCarConfig.vue"));

var _dialogServiceConfig = _interopRequireDefault(require("./dialogServiceConfig.vue"));

var _dialogGeneralConfig = _interopRequireDefault(require("./dialogGeneralConfig.vue"));

var _dialogScheduleModuleDialog = _interopRequireDefault(require("./dialogScheduleModuleDialog.vue"));

var _dialogScheduleOrderDialog = _interopRequireDefault(require("./dialogScheduleOrderDialog.vue"));

var _dialogCancelConfig = _interopRequireDefault(require("./dialogCancelConfig.vue"));

var _carpool = require("@/api/business/carpool");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    dialogOperationScope: _dialogOperationScope.default,
    dialogLinePrice: _dialogLinePrice.default,
    dialogServiceConfig: _dialogServiceConfig.default,
    dialogScheduleModuleDialog: _dialogScheduleModuleDialog.default,
    dialogScheduleOrderDialog: _dialogScheduleOrderDialog.default,
    dialogCancelConfig: _dialogCancelConfig.default,
    dialogGeneralConfig: _dialogGeneralConfig.default,
    dialogCarConfig: _dialogCarConfig.default
  },
  data: function data() {
    return {
      openOperationScopeDialog: false,
      openScheduleModuleDialog: false,
      openScheduleOrderDialog: false,
      openLinePriceDialog: false,
      openCarConfigDialog: false,
      openServiceDialog: false,
      openGeneralConfigDialog: false,
      openDialogCancelConfig: false,
      timeRefresh: "",
      lnglatData: [],
      operationData: {},
      lists: [{
        config: "服务配置",
        type: "openServiceDialog",
        isSet: true
      }, {
        config: "运营范围配置",
        type: "openOperationScopeDialog",
        isSet: false
      }, {
        config: "价格配置",
        type: "openLinePriceDialog",
        isSet: true
      }, // { config: "车型模板配置", type: "openCarConfigDialog", isSet: true },
      {
        config: "班次模板配置",
        type: "openScheduleModuleDialog",
        isSet: true
      }, {
        config: "班次排班配置",
        type: "openScheduleOrderDialog",
        isSet: true
      }, {
        config: "取消规则配置",
        type: "openDialogCancelConfig",
        isSet: true
      }, {
        config: "通用配置",
        type: "openGeneralConfigDialog",
        isSet: true
      }],
      userName: ""
    };
  },
  mounted: function mounted() {
    console.log(this.$route, "this.$route");
    this.operationData = this.$route.query;
  },
  methods: {
    reLoad: function reLoad() {
      this.timeRefresh = new Date().getTime();
    },
    initLineData: function initLineData() {
      var _this = this;

      (0, _carpool.getCarpoolLine)(this.$route.query.id).then(function (response) {
        _this.operationData = response.data;
        console.log(_this.operationData, "this.$route");
      });
    },
    closeOperationScopeDialog: function closeOperationScopeDialog() {
      this.openOperationScopeDialog = false;
      this.initLineData();
    },
    closeScheduleModuleDialog: function closeScheduleModuleDialog() {
      this.openScheduleModuleDialog = false;
      this.initLineData();
    },
    closeScheduleOrderDialog: function closeScheduleOrderDialog() {
      this.openScheduleOrderDialog = false;
      this.initLineData();
    },
    closeLinePriceDialog: function closeLinePriceDialog() {
      this.openLinePriceDialog = false;
      this.initLineData();
    },
    closeServiceDialog: function closeServiceDialog() {
      this.openServiceDialog = false;
      this.initLineData();
    },
    closeGeneralConfigDialog: function closeGeneralConfigDialog() {
      this.openGeneralConfigDialog = false;
      this.initLineData();
    },
    closeDialogCancelConfig: function closeDialogCancelConfig() {
      this.openDialogCancelConfig = false;
      this.initLineData();
    },
    closeDialogCarConfig: function closeDialogCarConfig() {
      this.openCarConfigDialog = false;
      this.initLineData();
    },
    submitOperationScopeDialog: function submitOperationScopeDialog(row) {
      console.log(row);
    },
    submitLinePriceDialog: function submitLinePriceDialog(row) {
      console.log(row);
    },
    submitGeneralConfigDialog: function submitGeneralConfigDialog(row) {
      console.log(row);
    },
    submitServiceDialog: function submitServiceDialog(row) {// row.serviceType = row.serviceType.join(",");
      // addLineConfig({ ...row }).then(res => {
      //   console.log(res);
      //   this.closeServiceDialog();
      //   if (res.code === 200) {
      //     this.$message({ showClose: true, message: res.msg, type: "success" });
      //   } else {
      //     this.$message({ showClose: true, message: res.msg, type: "error" });
      //   }
      // });
    },
    goDetails: function goDetails(type) {
      this[type] = true;
    }
  }
};
exports.default = _default;