var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.openGeneralConfigDialog
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: "通用配置",
            center: "",
            "before-close": _vm.handleDialogClose,
            visible: _vm.openGeneralConfigDialog,
            "close-on-click-modal": false,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.openGeneralConfigDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "generalForm",
              staticClass: "general-form",
              attrs: {
                "label-position": "left",
                model: _vm.generalForm,
                rules: _vm.rules,
                "label-width": "180px"
              }
            },
            [
              _c("div", { staticClass: "title" }, [_vm._v("乘客下单限制")]),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "maxCarpoolOrderNum",
                    label: "乘客同一时间最大允许"
                  }
                },
                [
                  _c("el-input-number", {
                    staticStyle: { margin: "0 10px", width: "120px" },
                    attrs: { min: 1, max: 10 },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.generalForm.maxCarpoolOrderNum,
                      callback: function($$v) {
                        _vm.$set(_vm.generalForm, "maxCarpoolOrderNum", $$v)
                      },
                      expression: "generalForm.maxCarpoolOrderNum"
                    }
                  }),
                  _vm._v("个有效拼车单（1-10之间）\n    ")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "乘客同一时间最大允许",
                    prop: "maxCharterOrderNum"
                  }
                },
                [
                  _c("el-input-number", {
                    staticStyle: { margin: "0 10px", width: "120px" },
                    attrs: { min: 1, max: 10 },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.generalForm.maxCharterOrderNum,
                      callback: function($$v) {
                        _vm.$set(_vm.generalForm, "maxCharterOrderNum", $$v)
                      },
                      expression: "generalForm.maxCharterOrderNum"
                    }
                  }),
                  _vm._v("个有效包车单（1-10之间）\n    ")
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [
                _vm._v("乘客提前下单时间限制")
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "允许乘客提前下单时间",
                    prop: "sellTicketRule"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.generalForm.sellTicketRule,
                        callback: function($$v) {
                          _vm.$set(_vm.generalForm, "sellTicketRule", $$v)
                        },
                        expression: "generalForm.sellTicketRule"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          staticStyle: {
                            height: "30px",
                            "line-height": "30px"
                          },
                          attrs: { label: "1" }
                        },
                        [
                          _vm._v(
                            "\n          对比出发时间：可提前\n          "
                          ),
                          _c("el-input", {
                            staticStyle: { width: "60px", margin: "0 4px" },
                            attrs: { maxlength: "3", size: "mini" },
                            model: {
                              value: _vm.generalForm.departureEarlyMin,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.generalForm,
                                  "departureEarlyMin",
                                  $$v
                                )
                              },
                              expression: "generalForm.departureEarlyMin"
                            }
                          }),
                          _vm._v("分钟下单\n        ")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          staticStyle: {
                            height: "30px",
                            "line-height": "30px"
                          },
                          attrs: { label: "2" }
                        },
                        [
                          _vm._v(
                            "\n          对比接驾时间：可提前\n          "
                          ),
                          _c("el-input", {
                            staticStyle: { width: "60px", margin: "0 4px" },
                            attrs: { maxlength: "3", size: "mini" },
                            model: {
                              value: _vm.generalForm.pickupEarlyMin,
                              callback: function($$v) {
                                _vm.$set(_vm.generalForm, "pickupEarlyMin", $$v)
                              },
                              expression: "generalForm.pickupEarlyMin"
                            }
                          }),
                          _vm._v("分钟下单\n        ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v("派单限制")]),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "travelMergeRule",
                    "label-width": "160px",
                    label: "是否允许不同出发时间订单加入同一行程"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.generalForm.travelMergeRule,
                        callback: function($$v) {
                          _vm.$set(_vm.generalForm, "travelMergeRule", $$v)
                        },
                        expression: "generalForm.travelMergeRule"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          staticStyle: {
                            height: "30px",
                            "line-height": "30px"
                          },
                          attrs: { label: "1" }
                        },
                        [_vm._v("允许")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          staticStyle: {
                            height: "30px",
                            "line-height": "30px"
                          },
                          attrs: { label: "0" }
                        },
                        [_vm._v("不允许")]
                      )
                    ],
                    1
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _vm.generalForm.travelMergeRule === "1"
                    ? _c(
                        "div",
                        [
                          _vm._v(
                            "\n        订单加入已有行程时间限制：允许加入接驾时间前后\n        "
                          ),
                          _c("el-input", {
                            staticStyle: { width: "60px", margin: "0 4px" },
                            attrs: { maxlength: "3", size: "mini" },
                            model: {
                              value: _vm.generalForm.pickupMin,
                              callback: function($$v) {
                                _vm.$set(_vm.generalForm, "pickupMin", $$v)
                              },
                              expression:
                                "\n            generalForm.pickupMin\n          "
                            }
                          }),
                          _vm._v("分钟的行程\n      ")
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleDialogClose } }, [
                _vm._v("取 消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }