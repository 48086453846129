"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime-corejs2/helpers/objectSpread2"));

var _airport = require("@/api/business/airport");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Detail",
  data: function data() {
    return {
      invoiceDetail: {},
      tableData: [],
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      // 总条数
      billTotal: 0,
      // 查询参数
      queryBillParams: {
        pageNum: 1,
        pageSize: 10
      },
      id: null,
      companyId: null,
      activeTab: "order",
      invoiceRecord: []
    };
  },
  created: function created() {
    this.id = this.$route.query.id;
    this.getDetail();
    this.getInvoiceRecord();
  },
  methods: {
    formatter: function formatter(row, column) {
      if (!row[column.property] && row[column.property] !== 0) {
        return "--";
      }

      return row[column.property];
    },

    /** 获取发票详情 */
    getDetail: function getDetail() {
      var _this = this;

      (0, _airport.airInvoiceDetails)({
        invoiceOrderNo: this.id
      }).then(function (response) {
        _this.invoiceDetail = response.data;

        _this.geOrdertList();
      });
    },

    /** 获取发票日志 */
    getInvoiceRecord: function getInvoiceRecord() {
      var _this2 = this;

      (0, _airport.airInvoiceRecord)(this.id).then(function (res) {
        if (res.code == 200) {
          _this2.invoiceRecord = res.data;
        }
      });
    },

    /** 包含订单列表 */
    geOrdertList: function geOrdertList() {
      var _this3 = this;

      (0, _airport.airInvoiceOrderList)((0, _objectSpread2.default)({}, this.queryParams, {
        invoiceOrderNo: this.id,
        invoiceType: this.invoiceDetail.invoiceType
      })).then(function (response) {
        _this3.tableData = response.data.rows;
        _this3.total = response.data.total;
      });
    },
    // 生成pdf
    handlePDF: function handlePDF() {
      (0, _airport.airInvoiceItineraryListPDF)({
        invoiceOrderNo: this.id,
        invoiceType: this.invoiceDetail.invoiceType
      }).then(function (res) {
        var blob = new Blob([res], {
          type: 'application/pdf'
        });
        var url = window.URL.createObjectURL(blob);
        window.open(url);
        console.log(url);
      });
    },

    /** 下载发票pdf */
    downloadPDF: function downloadPDF(url) {
      window.open(url); // window.location.href = url
    }
  }
};
exports.default = _default;