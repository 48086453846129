var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "shuttlebus_station_form" } },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.saveLoading,
              expression: "saveLoading"
            }
          ],
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "140px",
            disabled: _vm.lookDetail
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "站点名称", prop: "stationName" } },
            [
              _c("el-input", {
                attrs: { maxlength: "15", placeholder: "请输入站点名称" },
                model: {
                  value: _vm.ruleForm.stationName,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "stationName", $$v)
                  },
                  expression: "ruleForm.stationName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "站点类型", prop: "stationType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.stationType,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "stationType", $$v)
                    },
                    expression: "ruleForm.stationType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [_vm._v("站点")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "2" } }, [_vm._v("围栏")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "站点城市", prop: "cityValue" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    disabled: this.ruleForm.id > 0,
                    placeholder: "城市"
                  },
                  on: {
                    change: function($event) {
                      return _vm.getCountryList("city", _vm.ruleForm.cityValue)
                    }
                  },
                  model: {
                    value: _vm.ruleForm.cityValue,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "cityValue", $$v)
                    },
                    expression: "ruleForm.cityValue"
                  }
                },
                _vm._l(_vm.cities, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.cityName,
                      value: item.id + "|" + item.cityName
                    }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    disabled: this.ruleForm.id > 0,
                    placeholder: "县区"
                  },
                  model: {
                    value: _vm.ruleForm.countryValue,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "countryValue", $$v)
                    },
                    expression: "ruleForm.countryValue"
                  }
                },
                _vm._l(_vm.countries, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.cityName,
                      value: item.id + "|" + item.cityName
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "站点描述", prop: "stationDesc" } },
            [
              _c("el-input", {
                attrs: { maxlength: "30", placeholder: "请输入站点描述" },
                model: {
                  value: _vm.ruleForm.stationDesc,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "stationDesc", $$v)
                  },
                  expression: "ruleForm.stationDesc"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticStyle: { position: "relative" },
              attrs: { label: "站点地址", prop: "address" }
            },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.ruleForm.address,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "address", $$v)
                  },
                  expression: "ruleForm.address"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "站点状态", prop: "status" } },
            [
              _c("el-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.ruleForm.status,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "status", $$v)
                  },
                  expression: "ruleForm.status"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "站点场景", prop: "stationTag" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择站点场景" },
                  model: {
                    value: _vm.ruleForm.stationTag,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "stationTag", $$v)
                    },
                    expression: "ruleForm.stationTag"
                  }
                },
                _vm._l(_vm.stationTagList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.tag, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "站点指引", prop: "introduce" } },
            [
              _c("span", { staticStyle: { color: "#67c23a" } }, [
                _vm._v(_vm._s(_vm.ruleForm.introduce ? "已设置" : ""))
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "text", icon: "el-icon-edit" },
                  on: { click: _vm.handleUpdate }
                },
                [_vm._v("编辑")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "站点地图" } }, [
            _vm.ruleForm.stationType
              ? _c("span", [
                  _vm._v(
                    "提示：可点击地图空白处新增" +
                      _vm._s(_vm.ruleForm.stationType == 1 ? "站点" : "围栏")
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticStyle: { position: "relative" } }, [
              _c("div", { attrs: { id: "new_map" } }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "map-search" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "在这里输入地址搜索", type: "text" },
                    on: { input: _vm.searchFn },
                    model: {
                      value: _vm.inputSearchVal,
                      callback: function($$v) {
                        _vm.inputSearchVal = $$v
                      },
                      expression: "inputSearchVal"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    ref: "panel",
                    staticStyle: { "z-index": "99999" },
                    attrs: { id: _vm.panelId }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.backPage } }, [_vm._v("取消")])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: "站点实景指引编辑",
            visible: _vm.showEditor
          },
          on: {
            "update:visible": function($event) {
              _vm.showEditor = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { height: "240px" } },
            [
              _vm.showEditor
                ? _c("editor", {
                    ref: "editor",
                    model: {
                      value: _vm.ruleForm.introduce,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "introduce", $$v)
                      },
                      expression: "ruleForm.introduce"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleUpdate } }, [
                _vm._v("取 消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleUpdate } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }