var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "details_content" }, [
    _c("p", [_vm._v("订单信息")]),
    _vm._v(" "),
    _c("div", { staticClass: "div_wrapper" }, [
      _c("div", [
        _vm._v("\n      订单号码："),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.orderDetails.orderNo || "--"))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      下单时间： "),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.orderDetails.orderTime || "--"))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      验票时间：\n      "),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.orderDetails.checkTicketTime || "--"))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      公司名称：\n      "),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.orderDetails.companyName || "--"))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      订单状态：\n      "),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.orderDetails.orderStatusDesc || "--"))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      下单手机：\n      "),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.orderDetails.passengerPhone || "--"))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      业务类型：\n      "),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.orderDetails.businessType || "--"))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      支付时间：\n      "),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.orderDetails.paySuccessTime || "--"))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      支付方式： "),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.orderDetails.payType || "--"))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      订单来源：\n      "),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.orderDetails.orderChannelDesc || "--"))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      乘车人数：\n      "),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.orderDetails.passengerNum || "--"))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      验票状态：\n      "),
        _c("span", [
          _vm._v(
            _vm._s(_vm.orderDetail.orderDetails.checkTicketStatusDesc || "--")
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("p", [_vm._v("班次信息")]),
    _vm._v(" "),
    _c("div", { staticClass: "div_wrapper" }, [
      _c("div", [
        _vm._v("\n      线路名称：\n      "),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.scheduleDetails.lineName || "--"))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      班次时间：\n      "),
        _c("span", [
          _vm._v(
            _vm._s(_vm.orderDetail.scheduleDetails.scheduleDate + " " || "--") +
              "\n        " +
              _vm._s(_vm.orderDetail.scheduleDetails.firstTime) +
              "\n        " +
              _vm._s(
                _vm.orderDetail.scheduleDetails.lastTime
                  ? "-" + _vm.orderDetail.scheduleDetails.lastTime
                  : ""
              )
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      司机手机：\n      "),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.scheduleDetails.driverPhone || "--"))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      班次状态：\n      "),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.scheduleDetails.statusDesc || "--"))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      起点：\n      "),
        _c("span", [
          _vm._v(
            _vm._s(_vm.orderDetail.scheduleDetails.startStationName || "--")
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      终点：\n      "),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.scheduleDetails.endStationName || "--"))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      车牌号码： "),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.scheduleDetails.carNo || "--"))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      司机姓名：\n      "),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.scheduleDetails.driverName || "--"))
        ])
      ])
    ]),
    _vm._v(" "),
    _c("p", [_vm._v("金额明细")]),
    _vm._v(" "),
    _c("div", { staticClass: "div_wrapper" }, [
      _c("div", [
        _vm._v("\n      订单金额：\n      "),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.airportOrderAmount.orderMoney || "--"))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      优惠金额：\n      "),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.airportOrderAmount.couponMoney || "--"))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      订单实付：\n      "),
        _c("span", [
          _vm._v(
            _vm._s(_vm.orderDetail.airportOrderAmount.actuallyPayMoney || "--")
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      平台承担优惠：\n      "),
        _c("span", [
          _vm._v(
            _vm._s(
              _vm.orderDetail.airportOrderAmount.platorderDetailMoney || "--"
            )
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      加盟商承担优惠：\n      "),
        _c("span", [
          _vm._v(
            _vm._s(_vm.orderDetail.airportOrderAmount.franchiseeMoney || "--")
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      退款金额：\n      "),
        _c("span", [
          _vm._v(_vm._s(_vm.orderDetail.airportOrderAmount.refundMoney || "--"))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n      退款手续费：\n      "),
        _c("span", [
          _vm._v(
            _vm._s(
              _vm.orderDetail.airportOrderAmount.refundServiceCharge || "--"
            )
          )
        ])
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { width: "fit-content" } },
        _vm._l(_vm.orderDetail.priceDetails, function(item) {
          return _c("span", [
            _vm._v(
              "\n        " +
                _vm._s(item.priceType + "票") +
                "\n        " +
                _vm._s(item.num + "人") +
                "\n        " +
                _vm._s("(" + item.price + "元)") +
                "\n      "
            )
          ])
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c("p", [_vm._v("乘客信息")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "div_list_wrapper" },
      [
        _vm._l(_vm.orderDetail.passengerInfos, function(item) {
          return !_vm.orderDetail.scheduleDetails.realNameRideSwitch
            ? _c("div", [
                _c("span", [
                  _vm._v(_vm._s(item.friendType) + _vm._s("(" + item.num + ")"))
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(item.priceType))])
              ])
            : _vm._e()
        }),
        _vm._v(" "),
        _vm._l(_vm.orderDetail.passengerInfos, function(item) {
          return _vm.orderDetail.scheduleDetails.realNameRideSwitch
            ? _c("div", [
                _c("span", [
                  _vm._v(_vm._s(item.friendName) + _vm._s("(" + item.num + ")"))
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(item.friendType + "票"))]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(item.friendIdcard))]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(item.priceType))])
              ])
            : _vm._e()
        })
      ],
      2
    ),
    _vm._v(" "),
    _c("p", [_vm._v("订单日志")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "div_list_wrapper" },
      _vm._l(_vm.orderDetail.statusRecords, function(item) {
        return _c("div", [
          _c("span", [_vm._v(_vm._s(item.orderStatus))]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(item.created))]),
          _vm._v(" "),
          item.refundRecord
            ? _c("span", [_vm._v(_vm._s(item.refundRecord.refundMoney) + "元")])
            : _vm._e()
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("p", [_vm._v("发票日志")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "div_list_wrapper" },
      _vm._l(_vm.invoiceRecord, function(item) {
        return _c("div", [
          _c("span", [_vm._v(_vm._s(item.invoiceStatusDes))]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(item.created))]),
          _vm._v(" "),
          item.invoiceNum
            ? _c("span", [_vm._v("发票码" + _vm._s(item.invoiceNum))])
            : _vm._e()
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }