var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 6, xs: 24 } },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("司机详细信息")])]
                ),
                _vm._v(" "),
                _c("div", [
                  _c("div", { staticClass: "text-center" }, [
                    _c("img", {
                      staticClass: "avatar",
                      attrs: { src: _vm.user.baseInfo.avatar }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "user_name" }, [
                      _c("span", [_vm._v(_vm._s(_vm.user.baseInfo.driverName))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "list-group list-group-striped" }, [
                    _c("div", { staticClass: "title" }, [
                      _c("span", [_vm._v("司机账户详情")])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _c("div", { staticClass: "list-group-item-item" }, [
                        _c("span", { staticClass: "item-title" }, [
                          _vm._v("佣金设置:")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          _vm._l(_vm.user.accountInfo.commissionRatio, function(
                            commissioRatio,
                            index
                          ) {
                            return _c("div", { key: index }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(commissioRatio.serviceType) +
                                  ":\n                        平台:" +
                                  _vm._s(commissioRatio.platformRatio) +
                                  "%，\n                        司机:" +
                                  _vm._s(commissioRatio.driverRatio) +
                                  "%，\n                        加盟商:" +
                                  _vm._s(commissioRatio.companyRatio) +
                                  "%，\n                        其他固定抽佣:" +
                                  _vm._s(commissioRatio.fixedIncomeAmount) +
                                  "元\n                      "
                              )
                            ])
                          }),
                          0
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v(
                        "\n                  总完单量\n                  "
                      ),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.user.accountInfo.orderTotalNum))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v(
                        "\n                  资金账户状态\n                  "
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["system:driver:fund"],
                              expression: "['system:driver:fund']"
                            }
                          ],
                          staticClass: "pull-right",
                          staticStyle: { "margin-left": "10px", color: "blue" },
                          on: { click: _vm.disableAccount }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.user.accountInfo.accountFreeze == 0
                                ? "解冻资金账户"
                                : "冻结资金账户"
                            )
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(
                          _vm._s(
                            _vm.user.accountInfo.accountFreeze == 0
                              ? "冻结"
                              : "正常"
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "title" }, [
                      _c("span", [_vm._v("基础信息")])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v(
                        "\n                  运力公司\n                  "
                      ),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.user.baseInfo.companyName || "--"))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v(
                        "\n                  司机类型\n                  "
                      ),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("driverTypeFilter")(
                              _vm.user.baseInfo.driverType
                            )
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v("\n                  手机号\n                  "),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.user.baseInfo.phone || "--"))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v("\n                  身份证\n                  "),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.user.baseInfo.idCard || "--"))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v(
                        "\n                  驾驶证初次领取\n                  "
                      ),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.user.baseInfo.licenseDate || "--"))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v(
                        "\n                  驾驶证有效期起\n                  "
                      ),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.user.baseInfo.licenseStart || "--"))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v(
                        "\n                  驾驶证有效期止\n                  "
                      ),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.user.baseInfo.licenseEnd || "--"))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "title" }, [
                      _c("span", [_vm._v("登陆信息")])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v(
                        "\n                  最近登录时间\n                  "
                      ),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.user.loginInfo.lastLoginTime || "--"))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v(
                        "\n                  注册时间\n                  "
                      ),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(
                          _vm._s(_vm.user.loginInfo.registrationTime || "--")
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v(
                        "\n                  手机型号\n                  "
                      ),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.user.loginInfo.phoneModel || "--"))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v("\n                  APP版本\n                  "),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.user.loginInfo.appVersion || "--"))
                      ])
                    ])
                  ])
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 18, xs: 24 } },
            [
              _c(
                "el-card",
                [
                  _c(
                    "el-tabs",
                    {
                      model: {
                        value: _vm.activeTab,
                        callback: function($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab"
                      }
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "订单记录", name: "order" } },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { data: _vm.tableData, border: "" }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "orderNo",
                                  label: "订单号",
                                  width: "180",
                                  fixed: "left"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "tradeNo",
                                  label: "商户单号",
                                  width: "180"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "companyName",
                                  label: "公司名称"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "lineName",
                                  label: "线路名称",
                                  width: "160"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "upAddress",
                                  label: "上车点",
                                  width: "220"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "downAddress",
                                  label: "下车点",
                                  width: "220"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "订单状态" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.filterStatus(
                                                scope.row.orderStatus
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "支付状态" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.filterPayStatus(
                                                scope.row.payStatus
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "退款状态" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.filterRefundStatus(
                                                scope.row.refundStatus
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "channel",
                                  label: "订单来源",
                                  width: "100"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.filterChannel(
                                                scope.row.channel
                                              )
                                            ) +
                                            "\n                  "
                                        )
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { prop: "note", label: "订单备注" }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "司机信息", width: "200" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(scope.row.driverName) +
                                            " " +
                                            _vm._s(scope.row.driverPhone) +
                                            "\n                  "
                                        )
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "messagePhone",
                                  label: "乘客手机号",
                                  width: "200"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "passengerPhone",
                                  label: "下单手机号",
                                  width: "200"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "orderTime",
                                  label: "下单时间",
                                  width: "160"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "scheduleDate",
                                  label: "班次时间",
                                  width: "160"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "orderMoney",
                                  label: "订单总金额"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "orderActuallyPayMoney",
                                  label: "订单实付金额"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "couponMoney",
                                  label: "订单优惠金额"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "couponPlatformMoney",
                                  label: "平台承担优惠"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "couponCompanyMoney",
                                  label: "加盟商承担优惠"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "refundMoney",
                                  label: "退款金额"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "refundServiceCharge",
                                  label: "退款手续费"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("pagination", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.total > 0,
                                expression: "total>0"
                              }
                            ],
                            attrs: {
                              total: _vm.total,
                              page: _vm.queryParams.pageNum,
                              limit: _vm.queryParams.pageSize
                            },
                            on: {
                              "update:page": function($event) {
                                return _vm.$set(
                                  _vm.queryParams,
                                  "pageNum",
                                  $event
                                )
                              },
                              "update:limit": function($event) {
                                return _vm.$set(
                                  _vm.queryParams,
                                  "pageSize",
                                  $event
                                )
                              },
                              pagination: _vm.geOrdertList
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      false
                        ? _c(
                            "el-tab-pane",
                            { attrs: { label: "账单记录", name: "bill" } },
                            [
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { data: _vm.tableData2, border: "" }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "id",
                                      label: "序号",
                                      width: "160"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "billType",
                                      label: "账单类型",
                                      width: "160"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row.billType
                                                      ? "订单收入"
                                                      : "提现"
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3128310991
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "createTime",
                                      label: "账单时间",
                                      width: "160"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "money",
                                      label: "账单收入",
                                      width: "160"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "orderMoney",
                                      label: "订单总金额",
                                      width: "160"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "orderRefundMoney",
                                      label: "订单退款金额",
                                      width: "160"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "orderRefundFee",
                                      label: "退款手续费",
                                      width: "160"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "platformMoney",
                                      label: "平台抽佣",
                                      width: "160"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "companyMoney",
                                      label: "加盟商抽佣",
                                      width: "160"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "orderDeparture",
                                      label: "订单上车点",
                                      width: "160"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "orderDestination",
                                      label: "订单下车点",
                                      width: "160"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("pagination", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.billTotal > 0,
                                    expression: "billTotal>0"
                                  }
                                ],
                                attrs: {
                                  total: _vm.billTotal,
                                  page: _vm.queryBillParams.pageNum,
                                  limit: _vm.queryBillParams.pageSize
                                },
                                on: {
                                  "update:page": function($event) {
                                    return _vm.$set(
                                      _vm.queryBillParams,
                                      "pageNum",
                                      $event
                                    )
                                  },
                                  "update:limit": function($event) {
                                    return _vm.$set(
                                      _vm.queryBillParams,
                                      "pageSize",
                                      $event
                                    )
                                  },
                                  pagination: _vm.getBillList
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }