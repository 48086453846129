"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAuditing = getAuditing;
exports.auditing = auditing;
exports.getMyAuditing = getMyAuditing;
exports.auditingExport = auditingExport;
exports.getNoticeNumber = getNoticeNumber;

var _request = _interopRequireDefault(require("@/utils/request"));

// 平台获取审批列表(平台去审批)
function getAuditing(query) {
  return (0, _request.default)({
    url: '/system/auditing/getAuditing',
    method: 'get',
    params: query
  });
} // 平台审核加盟商提交的审批的接口 审核操作接口


function auditing(query) {
  return (0, _request.default)({
    url: '/system/auditing/auditing',
    method: 'get',
    params: query
  });
} // 获取我提交的审批(加盟商查看自己的审批记录)


function getMyAuditing(query) {
  return (0, _request.default)({
    url: '/system/auditing/getMyAuditing',
    method: 'get',
    params: query
  });
} // 导出已审批内容


function auditingExport(query) {
  return (0, _request.default)({
    url: '/system/auditing/export',
    method: 'get',
    params: query
  });
} // 获取 发票申请、司机提现、待处理审批、我的审批、订单投诉 待办事项数量


function getNoticeNumber(query) {
  return (0, _request.default)({
    url: '/system/notice/getNoticeNumber',
    method: 'get',
    params: query
  });
}