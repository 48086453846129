var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "150px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "发放人群", prop: "couponSendType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.couponSendType,
                    callback: function($$v) {
                      _vm.couponSendType = $$v
                    },
                    expression: "couponSendType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("指定用户")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("表格导入")])
                ],
                1
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm.couponSendType == 1
                ? _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      placeholder: "请输入指定用户手机号",
                      type: "number"
                    },
                    model: {
                      value: _vm.form.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone"
                    }
                  })
                : _c(
                    "el-upload",
                    {
                      ref: "upload",
                      staticClass: "upload-demo",
                      attrs: {
                        action: "https://jsonplaceholder.typicode.com/posts/",
                        "on-success": _vm.handleChange,
                        "file-list": _vm.fileList,
                        limit: 1,
                        accept: ".xls,.xlsx"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip"
                        },
                        [
                          _vm._v(
                            "\n          只能上传.xls、.xlsx文件，且不超过500行\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "发放场景", prop: "grantType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.grantType,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "grantType", $$v)
                    },
                    expression: "form.grantType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("客服补偿")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("线下活动")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "发放内容", prop: "rewardType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.rewardType,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "rewardType", $$v)
                    },
                    expression: "form.rewardType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("优惠券")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("优惠券包")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.form.rewardType == 1
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-plus",
                    size: "mini"
                  },
                  on: { click: _vm.addCoupon }
                },
                [_vm._v("添加优惠券")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.form.rewardType == 1
            ? _c(
                "div",
                { staticStyle: { margin: "20px" } },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.addCouponList, border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "选择优惠券",
                          width: "160"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "选择优惠券",
                                        size: "small",
                                        "value-key": "id"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.selectCouponChange(
                                            scope.row
                                          )
                                        }
                                      },
                                      model: {
                                        value: scope.row.coupon,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "coupon", $$v)
                                        },
                                        expression: "scope.row.coupon"
                                      }
                                    },
                                    _vm._l(_vm.listAllCoupon, function(item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.couponName,
                                          value: item
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3450533943
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "选择加盟商",
                          width: "200"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        "collapse-tags": "",
                                        multiple: "",
                                        clearable: "",
                                        placeholder: "选择加盟商"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.selectCompanyChange(
                                            scope.row
                                          )
                                        }
                                      },
                                      model: {
                                        value: scope.row.couponCompanyList,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "couponCompanyList",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "scope.row.couponCompanyList"
                                      }
                                    },
                                    _vm._l(_vm.company, function(item) {
                                      return _c("el-option", {
                                        key: item.deptId,
                                        attrs: {
                                          label: item.deptName,
                                          value: item.deptId
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3936384018
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "选择线路",
                          width: "200"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        "collapse-tags": "",
                                        multiple: "",
                                        clearable: "",
                                        placeholder: "选择线路",
                                        size: "small",
                                        "value-key": "unique"
                                      },
                                      model: {
                                        value: scope.row.lines,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "lines", $$v)
                                        },
                                        expression: "scope.row.lines"
                                      }
                                    },
                                    _vm._l(scope.row.allLineList, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: item.unique,
                                        attrs: {
                                          label:
                                            "(" +
                                            item.businessDes +
                                            ")" +
                                            item.lineName,
                                          value: item
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4134337300
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "数量", width: "100" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-input", {
                                    staticStyle: { width: "70px" },
                                    attrs: { size: "small" },
                                    model: {
                                      value: scope.row.num,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "num", $$v)
                                      },
                                      expression: "scope.row.num"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1442841955
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "有效期" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { margin: "0 15px" },
                                          attrs: {
                                            placeholder: "选择有效期类型",
                                            size: "small"
                                          },
                                          model: {
                                            value: scope.row.couponExpireType,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "couponExpireType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.couponExpireType"
                                          }
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "指定时间",
                                              value: 1
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "限定时长",
                                              value: 2
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      scope.row.couponExpireType == 1
                                        ? _c(
                                            "div",
                                            [
                                              _c("el-date-picker", {
                                                staticStyle: { width: "240px" },
                                                attrs: {
                                                  size: "small",
                                                  format: "yyyy-MM-dd",
                                                  "value-format": "yyyy-MM-dd",
                                                  type: "daterange",
                                                  "range-separator": "至",
                                                  "start-placeholder":
                                                    "有效期起",
                                                  "end-placeholder": "有效期止"
                                                },
                                                model: {
                                                  value: scope.row.couponDate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "couponDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.couponDate"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.couponExpireType == 2
                                        ? _c(
                                            "div",
                                            [
                                              _vm._v(
                                                "\n                获取后\n                "
                                              ),
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                attrs: { size: "small" },
                                                model: {
                                                  value:
                                                    scope.row.couponExpireDay,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "couponExpireDay",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.couponExpireDay"
                                                }
                                              }),
                                              _vm._v(
                                                "\n                天内到期\n              "
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          344557582
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "成本承担",
                          width: "300"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v("\n            平台：\n            "),
                                  _c("el-input", {
                                    staticStyle: { width: "70px" },
                                    attrs: { size: "small" },
                                    model: {
                                      value: scope.row.platform,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "platform", $$v)
                                      },
                                      expression: "scope.row.platform"
                                    }
                                  }),
                                  _vm._v(
                                    "%\n            加盟商：\n            "
                                  ),
                                  _c("el-input", {
                                    staticStyle: { width: "70px" },
                                    attrs: { size: "small" },
                                    model: {
                                      value: scope.row.franchisee,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "franchisee", $$v)
                                      },
                                      expression: "scope.row.franchisee"
                                    }
                                  }),
                                  _vm._v("%\n          ")
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4111512941
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "100", align: "center", label: "操作" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.delCoupon(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3153147616
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.form.rewardType == 2
            ? _c(
                "div",
                { staticStyle: { margin: "20px" } },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.addGiftList, border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "选择优惠券包",
                          width: "160"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "选择优惠券包",
                                        size: "small"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.selectGiftChange(scope.row)
                                        }
                                      },
                                      model: {
                                        value: scope.row.giftId,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "giftId", $$v)
                                        },
                                        expression: "scope.row.giftId"
                                      }
                                    },
                                    _vm._l(_vm.listAllCouponGift, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1791749474
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "选择加盟商",
                          width: "200"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        "collapse-tags": "",
                                        multiple: "",
                                        clearable: "",
                                        placeholder: "选择加盟商"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.selectCompanyChange(
                                            scope.row
                                          )
                                        }
                                      },
                                      model: {
                                        value: scope.row.couponCompanyList,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "couponCompanyList",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "scope.row.couponCompanyList"
                                      }
                                    },
                                    _vm._l(_vm.company, function(item) {
                                      return _c("el-option", {
                                        key: item.deptId,
                                        attrs: {
                                          label: item.deptName,
                                          value: item.deptId
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3936384018
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "选择线路",
                          width: "200"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        "collapse-tags": "",
                                        multiple: "",
                                        clearable: "",
                                        placeholder: "选择线路",
                                        size: "small",
                                        "value-key": "unique"
                                      },
                                      model: {
                                        value: scope.row.lines,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "lines", $$v)
                                        },
                                        expression: "scope.row.lines"
                                      }
                                    },
                                    _vm._l(scope.row.allLineList, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: item.unique,
                                        attrs: {
                                          label:
                                            "(" +
                                            item.businessDes +
                                            ")" +
                                            item.lineName,
                                          value: item
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4134337300
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "数量", width: "100" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-input", {
                                    staticStyle: { width: "70px" },
                                    attrs: { size: "small" },
                                    model: {
                                      value: scope.row.num,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "num", $$v)
                                      },
                                      expression: "scope.row.num"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1442841955
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "有效期" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { margin: "0 15px" },
                                          attrs: {
                                            placeholder: "选择有效期类型",
                                            size: "small"
                                          },
                                          model: {
                                            value: scope.row.couponExpireType,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "couponExpireType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.couponExpireType"
                                          }
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "指定时间",
                                              value: 1
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "限定时长",
                                              value: 2
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      scope.row.couponExpireType == 1
                                        ? _c(
                                            "div",
                                            [
                                              _c("el-date-picker", {
                                                staticStyle: { width: "240px" },
                                                attrs: {
                                                  size: "small",
                                                  format: "yyyy-MM-dd",
                                                  "value-format": "yyyy-MM-dd",
                                                  type: "daterange",
                                                  "range-separator": "至",
                                                  "start-placeholder":
                                                    "有效期起",
                                                  "end-placeholder": "有效期止"
                                                },
                                                model: {
                                                  value: scope.row.couponDate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "couponDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.couponDate"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.couponExpireType == 2
                                        ? _c(
                                            "div",
                                            [
                                              _vm._v(
                                                "\n                获取后\n                "
                                              ),
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                attrs: { size: "small" },
                                                model: {
                                                  value:
                                                    scope.row.couponExpireDay,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "couponExpireDay",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.couponExpireDay"
                                                }
                                              }),
                                              _vm._v(
                                                "\n                天内到期\n              "
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          344557582
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "成本承担",
                          width: "300"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v("\n            平台：\n            "),
                                  _c("el-input", {
                                    staticStyle: { width: "70px" },
                                    attrs: { size: "small" },
                                    model: {
                                      value: scope.row.platform,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "platform", $$v)
                                      },
                                      expression: "scope.row.platform"
                                    }
                                  }),
                                  _vm._v(
                                    "%\n            加盟商：\n            "
                                  ),
                                  _c("el-input", {
                                    staticStyle: { width: "70px" },
                                    attrs: { size: "small" },
                                    model: {
                                      value: scope.row.franchisee,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "franchisee", $$v)
                                      },
                                      expression: "scope.row.franchisee"
                                    }
                                  }),
                                  _vm._v("%\n          ")
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4111512941
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "发送推送", prop: "sms" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.sms,
                    callback: function($$v) {
                      _vm.sms = $$v
                    },
                    expression: "sms"
                  }
                },
                [_c("el-radio", { attrs: { label: 1 } }, [_vm._v("短信")])],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "sms-style" }, [
                _vm._v(
                  "\n        恭喜您获得" +
                    _vm._s(_vm.form.service) +
                    "乘车优惠券，快打开新干线哈喽优行公众号查看吧\n      "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "请输入备注" },
                model: {
                  value: _vm.form.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.submitLoading,
                  expression: "submitLoading"
                }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.submitForm }
            },
            [_vm._v("发 送")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }