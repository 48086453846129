var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c(
        "div",
        { staticClass: "data-analysis-wrapper" },
        [
          _c("i", { staticClass: "el-icon-view" }),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [_vm._v("数据概览")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.gotoData } },
            [_vm._v("进入班车数据作战中心")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.gotoCarpoolData } },
            [_vm._v("进入拼车数据作战中心")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 30 } },
        [
          _c("el-col", { staticClass: "card-panel-col", attrs: { span: 8 } }, [
            _c("div", { staticClass: "card-panel" }, [
              _c(
                "div",
                { staticClass: "card-panel-description" },
                [
                  _c(
                    "div",
                    { staticClass: "card-panel-text" },
                    [
                      _vm._v("\n            总收益\n            "),
                      _c("svg-icon", {
                        attrs: {
                          "icon-class": _vm.showTotalMoney
                            ? "eye_close"
                            : "eye_open",
                          "class-name": "card-panel-icon"
                        },
                        on: { click: _vm.getTotalMoney }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showTotalMoney
                    ? _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.totalMoney,
                          duration: 1000
                        }
                      })
                    : _c("span", [_vm._v("******")])
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("el-col", { staticClass: "card-panel-col", attrs: { span: 8 } }, [
            _c("div", { staticClass: "card-panel" }, [
              _c(
                "div",
                { staticClass: "card-panel-description" },
                [
                  _c(
                    "div",
                    { staticClass: "card-panel-text" },
                    [
                      _vm._v("\n            总客量（票）\n            "),
                      _c("svg-icon", {
                        attrs: {
                          "icon-class": _vm.showTotalPerson
                            ? "eye_close"
                            : "eye_open",
                          "class-name": "card-panel-icon"
                        },
                        on: { click: _vm.getTotalPerson }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showTotalPerson
                    ? _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.totalPerson,
                          duration: 1000
                        }
                      })
                    : _c("span", [_vm._v("******")])
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "notice-wrapper" }, [
        _vm.noticeNumberDetail.invoiceAble
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermi",
                    rawName: "v-hasPermi",
                    value: ["system:finance:list"],
                    expression: "['system:finance:list']"
                  }
                ],
                staticClass: "item",
                on: {
                  click: function($event) {
                    return _vm.gotoList(1)
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: require("../assets/image/index_invoice@2x.png")
                  }
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "发票申请(" +
                      _vm._s(_vm.noticeNumberDetail.invoiceNumber) +
                      ")"
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.noticeNumberDetail.driverWithDrawalAble
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermi",
                    rawName: "v-hasPermi",
                    value: ["driver:apply:list"],
                    expression: "['driver:apply:list']"
                  }
                ],
                staticClass: "item",
                on: {
                  click: function($event) {
                    return _vm.gotoList(2)
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("../assets/image/index_driver@2x.png") }
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "司机提现(" +
                      _vm._s(_vm.noticeNumberDetail.driverWithDrawalNumber) +
                      ")"
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.noticeNumberDetail.userWithDrawalAble
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermi",
                    rawName: "v-hasPermi",
                    value: ["system:apply:list"],
                    expression: "['system:apply:list']"
                  }
                ],
                staticClass: "item",
                on: {
                  click: function($event) {
                    return _vm.gotoList(3)
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("../assets/image/index_user@2x.png") }
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "用户提现(" +
                      _vm._s(_vm.noticeNumberDetail.userWithDrawalNumber) +
                      ")"
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.noticeNumberDetail.approveAble
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermi",
                    rawName: "v-hasPermi",
                    value: ["system:auditing:list"],
                    expression: "['system:auditing:list']"
                  }
                ],
                staticClass: "item",
                on: {
                  click: function($event) {
                    return _vm.gotoList(4)
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: require("../assets/image/index_approval@2x.png")
                  }
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "待我审批(" +
                      _vm._s(_vm.noticeNumberDetail.approveNumber) +
                      ")"
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.noticeNumberDetail.myApproveAble
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermi",
                    rawName: "v-hasPermi",
                    value: ["system:auditing:mylist"],
                    expression: "['system:auditing:mylist']"
                  }
                ],
                staticClass: "item",
                on: {
                  click: function($event) {
                    return _vm.gotoList(5)
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: require("../assets/image/index_my_approval@2x.png")
                  }
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "我的审批申请(" +
                      _vm._s(_vm.noticeNumberDetail.approvingNumber) +
                      ")"
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.noticeNumberDetail.complaintAble
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermi",
                    rawName: "v-hasPermi",
                    value: ["system:complaint:list"],
                    expression: "['system:complaint:list']"
                  }
                ],
                staticClass: "item",
                on: {
                  click: function($event) {
                    return _vm.gotoList(6)
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: require("../assets/image/index_complaint@2x.png")
                  }
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "订单投诉(" +
                      _vm._s(_vm.noticeNumberDetail.orderUnHandleNumber) +
                      ")"
                  )
                ])
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "count", attrs: { gutter: 30 } },
        [
          _c("el-col", { staticClass: "count-item", attrs: { span: 4 } }, [
            _c("div", { staticClass: "_div _item" }, [
              _c(
                "div",
                { staticClass: "title" },
                [
                  _c("h5", [_vm._v("今日客量")]),
                  _vm._v(" "),
                  _c("svg-icon", {
                    attrs: {
                      "icon-class": _vm.showKeLiang ? "eye_close" : "eye_open",
                      "class-name": "card-panel-icon"
                    },
                    on: { click: _vm.getKeLiang }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("扫码购票")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.showKeLiang ? _vm.jinRiKeLiang.扫码购票 : "***")
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("APP购票")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.showKeLiang ? _vm.jinRiKeLiang.APP购票 : "***")
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("公众号购票")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.showKeLiang ? _vm.jinRiKeLiang.公众号购票 : "***"
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("小件快递")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.showKeLiang ? _vm.jinRiKeLiang.小件快递 : "***")
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("总计")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.showKeLiang ? _vm.jinRiKeLiang.总计 : "***")
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            {
              staticStyle: { position: "relative", "box-sizing": "border-box" },
              attrs: { span: 20 }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "select-filter",
                  staticStyle: { width: "100px" },
                  attrs: { size: "mini", placeholder: "请选择" },
                  on: { change: _vm.handleSetLineChartData1 },
                  model: {
                    value: _vm.value1,
                    callback: function($$v) {
                      _vm.value1 = $$v
                    },
                    expression: "value1"
                  }
                },
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("line-chart", { attrs: { "chart-data": _vm.chartData } }),
              _vm._v(" "),
              !_vm.showChartData1
                ? _c(
                    "div",
                    { staticClass: "chart-hint-wrapper" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleSetLineChartData1 }
                        },
                        [_vm._v("点击获取客量趋势")]
                      ),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-top": "10px" } }, [
                        _vm._v("默认获取本周数据，可切换获取本月度、季度数据")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "count", attrs: { gutter: 30 } },
        [
          _c("el-col", { staticClass: "count-item", attrs: { span: 4 } }, [
            _c("div", { staticClass: "_div _item" }, [
              _c(
                "div",
                { staticClass: "title" },
                [
                  _c("h5", [_vm._v("今日收益")]),
                  _vm._v(" "),
                  _c("svg-icon", {
                    attrs: {
                      "icon-class": _vm.showShowYi ? "eye_close" : "eye_open",
                      "class-name": "card-panel-icon"
                    },
                    on: { click: _vm.getShouYi }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("扫码购票")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "¥" +
                      _vm._s(_vm.showShowYi ? _vm.jinRiKeShouYi.扫码购票 : "**")
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("APP购票")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "¥" +
                      _vm._s(_vm.showShowYi ? _vm.jinRiKeShouYi.APP购票 : "**")
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("公众号购票")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "¥" +
                      _vm._s(
                        _vm.showShowYi ? _vm.jinRiKeShouYi.公众号购票 : "**"
                      )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("小件快递")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "¥" +
                      _vm._s(_vm.showShowYi ? _vm.jinRiKeShouYi.小件快递 : "**")
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("总计")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "¥" + _vm._s(_vm.showShowYi ? _vm.jinRiKeShouYi.总计 : "**")
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            {
              staticStyle: { position: "relative", "box-sizing": "border-box" },
              attrs: { span: 20 }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "select-filter",
                  staticStyle: { width: "100px" },
                  attrs: { size: "mini", placeholder: "请选择" },
                  on: { change: _vm.handleSetLineChartData2 },
                  model: {
                    value: _vm.value2,
                    callback: function($$v) {
                      _vm.value2 = $$v
                    },
                    expression: "value2"
                  }
                },
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("line-chart", { attrs: { "chart-data": _vm.chartData2 } }),
              _vm._v(" "),
              !_vm.showChartData2
                ? _c(
                    "div",
                    { staticClass: "chart-hint-wrapper" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleSetLineChartData2 }
                        },
                        [_vm._v("点击获取收益趋势")]
                      ),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-top": "10px" } }, [
                        _vm._v("默认获取本周数据，可切换获取本月度、季度数据")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }