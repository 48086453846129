"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.find");

var _typeof2 = _interopRequireDefault(require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime-corejs2/helpers/typeof"));

var _carpoolNew = require("@/api/business/carpoolNew");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    openServiceDialog: {
      type: Boolean,
      default: false
    },
    lineData: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      lineForm: {
        serviceType: []
      },
      rules: {
        serviceType: [{
          required: true,
          message: "请选择线路服务类型",
          trigger: "blur"
        }]
      },
      serviceTypeList: [],
      // 服务配置列表
      modelList: [] // 车型列表

    };
  },
  created: function created() {
    // this.getConfig();
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      // 获取公司服务配置
      (0, _carpoolNew.transportationCompanyManageServiceList)({
        companyId: this.lineData.companyId
      }).then(function (res) {
        if (res.code == 200) {
          _this.serviceTypeList = res.data;
        }
      }); // 获取拼车车型列表

      (0, _carpoolNew.vehicleModelList)().then(function (res) {
        if (res.code == 200) {
          _this.modelList = res.data;
        }
      }); // 获取线路配置服务信息

      (0, _carpoolNew.lineVehicleModelConfigInfo)({
        lineId: this.lineData.id
      }).then(function (res) {
        if (res.code == 200) {
          // 如果有拼车选择
          if (res.data.carpool) {
            _this.lineForm.serviceType.push('carpool');

            _this.$set(_this.lineForm, 'carpool', res.data.carpool[0]); // this.lineForm.carpool = res.data.carpool

          } // 如果有包车选择


          if (res.data.charter) {
            _this.lineForm.serviceType.push('charter');

            _this.$set(_this.lineForm, 'charter', res.data.charter); // this.lineForm.charter = res.data.charter

          }
        }
      });
    },
    handleDialogClose: function handleDialogClose() {
      this.$emit("closepop"); //通知父组件改变。
    },
    onSubmit: function onSubmit() {
      var _this2 = this;

      this.$refs["lineForm"].validate(function (valid) {
        if (valid) {
          var _ret = function () {
            var params = []; // 循环选择的业务类型

            var _loop = function _loop(i) {
              var item = _this2.lineForm.serviceType[i]; // 拼车改为单选

              if (item == 'carpool' && _this2.lineForm['carpool']) {
                var modal = _this2.modelList.find(function (m) {
                  return m.id == _this2.lineForm['carpool'];
                });

                params.push({
                  lineId: _this2.lineData.id,
                  serviceType: 'carpool',
                  carModelId: _this2.lineForm['carpool'],
                  carModelName: modal.modelName
                });
                console.log(params);
              } else {
                // 没有选择车型
                if (!_this2.lineForm[item].length) {
                  _this2.msgError('至少选择一个车型');

                  return {
                    v: {
                      v: void 0
                    }
                  };
                } // 循环选择的车型


                _this2.lineForm[item].forEach(function (car) {
                  var modal = _this2.modelList.find(function (m) {
                    return m.id == car;
                  });

                  params.push({
                    lineId: _this2.lineData.id,
                    serviceType: item,
                    carModelId: car,
                    carModelName: modal.modelName
                  });
                });
              }
            };

            for (var i = 0; i < _this2.lineForm.serviceType.length; i++) {
              var _ret2 = _loop(i);

              if ((0, _typeof2.default)(_ret2) === "object") return _ret2.v;
            }

            (0, _carpoolNew.lineVehicleModelConfigSave)(params).then(function (response) {
              if (response.code === 200) {
                _this2.msgSuccess("操作成功");
              } else {
                _this2.msgError(response.msg);
              }

              _this2.handleDialogClose();
            });
          }();

          if ((0, _typeof2.default)(_ret) === "object") return _ret.v;
        }
      });
    }
  }
};
exports.default = _default;