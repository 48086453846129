"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.summaryList = summaryList;
exports.orderImplVo = orderImplVo;
exports.orderDetail = orderDetail;
exports.orderSchedule = orderSchedule;
exports.orderMoney = orderMoney;
exports.getOrderNote = getOrderNote;
exports.selectBusTicketAndRefund = selectBusTicketAndRefund;
exports.summaryCount = summaryCount;
exports.listNoPageDetails = listNoPageDetails;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询订单列表
function summaryList(params) {
  return (0, _request.default)({
    url: '/order/order/summaryList',
    method: 'get',
    params: params
  });
} // 描述：订单详情


function orderImplVo(params) {
  return (0, _request.default)({
    url: '/order/order/orderImplVo',
    method: 'get',
    params: params
  });
} // 班车订单详情


function orderDetail(params) {
  return (0, _request.default)({
    url: '/order/order/orderDetail',
    method: 'get',
    params: params
  });
} // 订单详情-班次信息


function orderSchedule(params) {
  return (0, _request.default)({
    url: '/order/order/orderSchedule',
    method: 'get',
    params: params
  });
} // 订单详情-金额信息


function orderMoney(params) {
  return (0, _request.default)({
    url: '/order/order/orderMoney',
    method: 'get',
    params: params
  });
} // 订单详情-备注信息


function getOrderNote(params) {
  return (0, _request.default)({
    url: '/order/order/getOrderNote',
    method: 'get',
    params: params
  });
} // 描述：订单详情table


function selectBusTicketAndRefund(params) {
  return (0, _request.default)({
    url: '/order/ticket/selectBusTicketAndRefund',
    method: 'get',
    params: params
  });
} // 查询订单汇总详细


function summaryCount(params) {
  return (0, _request.default)({
    url: '/order/order/summaryCount',
    method: 'get',
    params: params
  });
} // 查询订单汇总详细


function listNoPageDetails(params) {
  return (0, _request.default)({
    url: '/order/record/listNoPage',
    method: 'get',
    params: params
  });
}