"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _dialogServiceConfig = _interopRequireDefault(require("./dialogServiceConfig.vue"));

var _dialogOperationScope = _interopRequireDefault(require("./dialogOperationScope.vue"));

var _dialogLinePrice = _interopRequireDefault(require("./dialogLinePrice.vue"));

var _dialogScheduleModuleDialog = _interopRequireDefault(require("./dialogScheduleModuleDialog.vue"));

var _dialogCancelConfig = _interopRequireDefault(require("./dialogCancelConfig.vue"));

var _dialogGeneralConfig = _interopRequireDefault(require("./dialogGeneralConfig.vue"));

var _carpoolNew = require("@/api/business/carpoolNew");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    dialogServiceConfig: _dialogServiceConfig.default,
    dialogOperationScope: _dialogOperationScope.default,
    dialogLinePrice: _dialogLinePrice.default,
    dialogScheduleModuleDialog: _dialogScheduleModuleDialog.default,
    dialogCancelConfig: _dialogCancelConfig.default,
    dialogGeneralConfig: _dialogGeneralConfig.default
  },
  data: function data() {
    return {
      openOperationScopeDialog: false,
      openScheduleModuleDialog: false,
      openScheduleOrderDialog: false,
      openLinePriceDialog: false,
      openCarConfigDialog: false,
      openServiceDialog: false,
      openGeneralConfigDialog: false,
      openDialogCancelConfig: false,
      timeRefresh: "",
      lnglatData: [],
      lineData: {},
      // 线路详情
      lists: [],
      lineId: '' // 线路id

    };
  },
  mounted: function mounted() {
    console.log(this.$route, "this.$route");
    this.lineId = this.$route.query.id;
    this.getConfigList();
    this.initLineData();
  },
  methods: {
    /** 获取线路配置信息 */
    getConfigList: function getConfigList() {
      var _this = this;

      (0, _carpoolNew.lineConfigStatusList)({
        lineId: this.lineId
      }).then(function (res) {
        if (res.code == 200) {
          _this.lists = res.data;
        }
      });
    },
    // 打开配置弹框
    opneDialog: function opneDialog(type) {
      if (type == '服务配置') {
        this.openServiceDialog = true;
      } else if (type == '运营范围配置') {
        this.openOperationScopeDialog = true;
      } else if (type == '价格配置') {
        this.openLinePriceDialog = true;
      } else if (type == '班次模板配置') {
        this.openScheduleModuleDialog = true;
      } else if (type == '取消规则配置') {
        this.openDialogCancelConfig = true;
      } else if (type == '通用配置') {
        this.openGeneralConfigDialog = true;
      }
    },

    /** 获取详情 */
    initLineData: function initLineData() {
      var _this2 = this;

      (0, _carpoolNew.lineDetails)({
        id: this.lineId
      }).then(function (response) {
        _this2.lineData = response.data;
      });
    },

    /** 修改线路营运状态 */
    updateLineStatus: function updateLineStatus() {
      var _this3 = this;

      var str;

      if (this.lineData.status) {
        str = "是否开启线路营运状态？";
      } else {
        str = "是否关闭线路营运状态？";
      }

      this.$confirm(str, "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return (0, _carpoolNew.lineAddOrUpdate)({
          id: _this3.lineId,
          status: _this3.lineData.status
        });
      }).catch(function () {
        _this3.lineData.status = !_this3.lineData.status;
      });
    },
    reLoad: function reLoad() {
      this.timeRefresh = new Date().getTime();
    },
    closeOperationScopeDialog: function closeOperationScopeDialog() {
      this.openOperationScopeDialog = false;
      this.getConfigList();
    },
    closeScheduleModuleDialog: function closeScheduleModuleDialog() {
      this.openScheduleModuleDialog = false;
      this.getConfigList();
    },
    closeLinePriceDialog: function closeLinePriceDialog() {
      this.openLinePriceDialog = false;
      this.getConfigList();
    },
    closeServiceDialog: function closeServiceDialog() {
      this.openServiceDialog = false;
      this.getConfigList();
    },
    closeGeneralConfigDialog: function closeGeneralConfigDialog() {
      this.openGeneralConfigDialog = false;
      this.getConfigList();
    },
    closeDialogCancelConfig: function closeDialogCancelConfig() {
      this.openDialogCancelConfig = false;
      this.getConfigList();
    },
    closeDialogCarConfig: function closeDialogCarConfig() {
      this.openCarConfigDialog = false;
      this.getConfigList();
    },
    submitOperationScopeDialog: function submitOperationScopeDialog(row) {
      console.log(row);
    },
    submitLinePriceDialog: function submitLinePriceDialog(row) {
      console.log(row);
    },
    submitGeneralConfigDialog: function submitGeneralConfigDialog(row) {
      console.log(row);
    },
    submitServiceDialog: function submitServiceDialog(row) {// row.serviceType = row.serviceType.join(",");
      // addLineConfig({ ...row }).then(res => {
      //   console.log(res);
      //   this.closeServiceDialog();
      //   if (res.code === 200) {
      //     this.$message({ showClose: true, message: res.msg, type: "success" });
      //   } else {
      //     this.$message({ showClose: true, message: res.msg, type: "error" });
      //   }
      // });
    }
  }
};
exports.default = _default;