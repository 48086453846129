"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _carpoolNew = require("@/api/business/carpoolNew");

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    openLinePriceDialog: {
      type: Boolean,
      default: false
    },
    lineData: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      showTimePriceConfig: false,
      timeConfigLists: [],
      loading: false,
      activeName: "",
      formPrice: {},
      // 价格表单
      serviceType: [],
      // 服务配置
      configStatusList: [],
      // 配置状态列表
      currentCarModelId: 0,
      // 当前选中车型 
      modelName: "" // 车型选中项

    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      // 获取线路配置的服务类型
      (0, _carpoolNew.lineVehicleModelConfigService)({
        lineId: this.lineData.id
      }).then(function (res) {
        if (res.code == 200) {
          _this.serviceType = res.data;

          if (_this.serviceType.length) {
            _this.activeName = _this.serviceType[0];

            _this.getLinePriceConfigStatusList(_this.serviceType[0]);
          }
        }
      });
    },

    /** 获取价格配置状态 */
    getLinePriceConfigStatusList: function getLinePriceConfigStatusList(serviceType) {
      var _this2 = this;

      (0, _carpoolNew.linePriceConfigStatusList)({
        lineId: this.lineData.id,
        serviceType: serviceType
      }).then(function (res) {
        if (res.code == 200) {
          _this2.configStatusList = res.data;
          _this2.modelName = _this2.configStatusList[0].carModelId + '';

          _this2.getlinePriceConfigDetails(serviceType, _this2.configStatusList[0].carModelId);
        }
      });
    },

    /**
     * 获取价格配置详情
     * serviceType 服务类型
     * carModelId 车型ID
     */
    getlinePriceConfigDetails: function getlinePriceConfigDetails(serviceType, carModelId) {
      var _this3 = this;

      this.currentCarModelId = carModelId;
      var params = {
        lineId: this.lineData.id,
        serviceType: serviceType,
        carModelId: carModelId
      };
      (0, _carpoolNew.linePriceConfigDetails)(params).then(function (res) {
        if (res.code == 200) {
          _this3.formPrice = res.data;

          if (!_this3.formPrice.id) {
            _this3.formPrice.specialAreaMarkupRule = '0';
          }

          if (!_this3.formPrice.cancelFeeRuleConfig.length) {
            _this3.addRefundItem();
          }
        }
      });
    },
    // 服务TAB点击
    handleTabClick: function handleTabClick(tab, event) {
      this.getLinePriceConfigStatusList(this.activeName);
    },
    // 车型TAB点击
    handlePaneClick: function handlePaneClick(tab, event) {
      console.log(tab);
      this.getlinePriceConfigDetails(this.activeName, this.configStatusList[tab.index].carModelId);
    },
    // 添加取消规则
    addRefundItem: function addRefundItem() {
      this.formPrice.cancelFeeRuleConfig.push({
        lineId: this.lineData.id,
        serviceType: this.activeName,
        carModelId: this.currentCarModelId,
        maxMin: 0,
        minMin: 0,
        ratio: 0
      });
    },
    // 删除取消规则
    delRefundItem: function delRefundItem(idx) {
      this.formPrice.cancelFeeRuleConfig.splice(idx, 1);
    },
    handleDialogClose: function handleDialogClose() {
      this.$emit("closepop"); //通知父组件改变。
    },
    // 打开加载层
    openLoading: function openLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
    },
    onSubmit: function onSubmit() {
      var _this4 = this;

      // this.loading = true;
      var copyFormDialog = (0, _utils.deepClone)(this.formPrice);
      copyFormDialog.lineId = this.lineData.id;
      copyFormDialog.serviceType = this.activeName;
      copyFormDialog.carModelId = this.currentCarModelId;
      console.log(copyFormDialog, "this.formPrice");
      (0, _carpoolNew.linePriceConfigSave)(copyFormDialog).then(function (res) {
        if (res.code === 200) {
          _this4.$message.success(res.msg);

          _this4.handleDialogClose();
        } else {
          _this4.$message.error(res.msg);
        }

        _this4.loading = false;
      });
    }
  }
};
exports.default = _default;